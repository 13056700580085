import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProgressPieComponent} from './progress-pie.component';


@NgModule({
  declarations: [
    ProgressPieComponent
  ],
  exports: [
    ProgressPieComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ProgressPieModule { }
