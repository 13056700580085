import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  toggleBodyScroll(state: boolean): void {
    const body = document.querySelector('body');
    if (body) {
      console.log('toggling body scroll ' + state);
      (state) ? body.classList.remove('overflow-hidden') : body.classList.add('overflow-hidden');
      // body.style.overflow = (state) ? '' : 'hidden';
      // body.style.height = (state) ? '' : '100vh';
      // body.style.maxHeight = (state) ? '' : window.innerHeight + 'px';
    }
  }
}
