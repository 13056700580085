import {gql} from 'apollo-angular';
import {ImageFragment} from './ImageFragment';

export const ResourceFragment = gql`
  fragment resourceFields on Resource {
    databaseId
    title
    acfResourceFields {
      type
      titleEn
      story {
        theme
        cards {
          content {
            ... on Resource_Acfresourcefields_Story_cards_Content_Text {
              textDe
              textEn
            }
            ... on Resource_Acfresourcefields_Story_cards_Content_Image {
              image {
                ...imageFields
              }
            }
          }
        }
      }
      audio {
        audioFileDe {
          mediaItemUrl
        }
        audioFileEn {
          mediaItemUrl
        }
        transcriptDe
        transcriptEn
      }
      audioDurationDe
      audioDurationEn
      linkDe {
        title
        url
      }
      linkEn {
        title
        url
      }
    }
  }
  ${ImageFragment}
`;
