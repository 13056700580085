import {Injectable} from '@angular/core';
import {ContentService} from './content.service';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import {ContentBase} from '../interfaces/content-base';
import {Tour} from '../interfaces/tour';

@Injectable({
  providedIn: 'root'
})
export class TourResolverService {
  constructor(
    private contentService: ContentService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{tour: Tour}> | Promise<{tour: Tour}> | ContentBase {

    let id = 0;
    const paramId = route.paramMap.get('id');

    if (paramId) {
      id = parseInt(paramId, 10);
    }

    return forkJoin({
      tour: this.contentService.getTourById(id)
    });
  }
}
