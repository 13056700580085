import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Notification} from '../../interfaces/notification';
import {NotificationsService} from '../../services/notifications.service';
import {LanguageService} from '../../services/language.service';

@Component({
  selector: 'mak-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  @ViewChild('notificationContainer') pullupWrapper: ElementRef | undefined;

  @Output('onNotificationExpired') onNotificationExpired = new EventEmitter<number>();

  @Input('data') set setNotification(value: Notification | null) {
    if (value) {
      this.notification = value;
      if (this.notification.image && this.notification.image.length) {
        // Here we gives TIMEOUT_IMG_LOADING_DURATION for image loading, if image loaded early it removes this timeout and show image
        // otherwise in 1 second the notification will be shown in anyway
        this.timeoutImgLoading = window.setTimeout(() => {
          this.setNotificationVisible();
        }, this.TIMEOUT_IMG_LOADING_DURATION);
      } else { // otherwise show immediately
        this.setNotificationVisible();
      }

      this.expiresTimeout = window.setTimeout(() => {
        this.closeNotification();
      }, this.EXPIRE_DURATION);

    } else {
      this.closeNotification();
    }
  }

  $lang = this.language.getLanguage();
  isVisible = false;
  notification!: Notification;
  isDragging = false;
  lastPosY = 0;
  deltaY = 0;
  timeoutImgLoading = -1;
  private readonly TIMEOUT_IMG_LOADING_DURATION = 1000; // ms
  private readonly THRESHOLD = 30;
  expiresTimeout = -1;
  private readonly EXPIRE_DURATION = 5000; // ms

  constructor(private notifications: NotificationsService, private language: LanguageService) { }

  ngOnInit(): void {
  }

  setNotificationVisible(): void {
    setTimeout(() => { // show with transition
      this.isVisible = true;
    }, 100);
  }

  async closeNotification(): Promise<void> {
    clearTimeout(this.expiresTimeout); // clear all possible timeouts
    clearTimeout(this.timeoutImgLoading);
    await this.hideNotification();
    this.removeSelf();
  }

  private hideNotification(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.isVisible = false;
      setTimeout(() => {
        resolve();
      }, this.notifications.animationDuration);
    });
  }

  private removeSelf(): void {
    this.onNotificationExpired.emit();
  }

  handleDrag(ev: any): void {

    if (this.pullupWrapper) {
      if (ev.deltaY <= 0) {
        if (!this.isDragging) {
          this.isDragging = true;
          this.lastPosY = this.pullupWrapper.nativeElement.offsetTop;
        }

        const posY = ev.deltaY + this.lastPosY;

        this.pullupWrapper.nativeElement.style.top = posY + 'px';

        // Drag ends
        if (ev.isFinal) {
          this.isDragging = false;

          // Threshold for pullup position
          if (-this.THRESHOLD < ev.deltaY && ev.deltaY < this.THRESHOLD) {
            this.pullupWrapper.nativeElement.style.top = this.lastPosY + 'px';
            return;
          }

          this.deltaY = ev.deltaY;
          this.pullupWrapper.nativeElement.style.top = '1rem';
          this.closeNotification();

        }
      } else {
        this.pullupWrapper.nativeElement.style.top = '1rem';
      }

    }
  }

  onInternalLinkClicked(): void { // TODO: may be we have to call some methods from tab-navigation here instead of [routerLink] for internal links?
    if (this.notification.onInternalLinkClicked) {
      console.log('internalLinkClicked');
      this.notification.onInternalLinkClicked();
    }
    this.closeNotification();
  }

  onExternalLinkClicked(): void {
    this.closeNotification();
  }

  onImageLoaded(): void {
    console.log('Image Loaded');
    clearTimeout(this.timeoutImgLoading);
    this.timeoutImgLoading = -1;
    if (!this.isVisible) {
      this.setNotificationVisible();
    }
  }
}
