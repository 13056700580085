import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateKeyPipe} from './translate-key.pipe';
import {TimePreviewPipe} from './time-preview.pipe';


@NgModule({
  declarations: [
    TranslateKeyPipe,
    TimePreviewPipe
  ],
  exports: [
    TranslateKeyPipe,
    TimePreviewPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
