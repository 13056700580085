import {createAction, props} from '@ngrx/store';
import {ObjectDetails} from '../../interfaces/object-details';
import {TourObjectLocation} from '../../interfaces/tour-object-location';
import {Poi} from '../../interfaces/poi';

export const setObjectInterest = createAction(
  '[Map] Set Object Of Interest',
  props<{objectDetails: ObjectDetails | undefined}>()
);

export const setTourObjectsOfInterest = createAction(
  '[Map] Show Tour Objects of Interest on Map',
  props<{objects: TourObjectLocation[]}>()
);

export const setTourMarkerOfInterest = createAction(
  '[Map] Show Tour Marker of Interest',
  props<{object: TourObjectLocation | undefined}>()
);

export const removeTourMarkerOfInterest = createAction(
  '[Map] Remove Tour Marker of Interest',
  props<{timestamp: string}>()
);

export const removeObjectInterest = createAction(
  '[Map] Remove Show on Map',
  props<{timestamp: string}>()
);

export const setPoiOfInterest = createAction(
  '[Map] Show Poi on Map',
  props<{poi: Poi | undefined}>()
);

export const removePoiOfInterest = createAction(
  '[Map] Remove Poi from Map',
  props<{timestamp: string}>()
);

