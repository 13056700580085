import {createAction, props} from '@ngrx/store';
import {ObjectDetails} from '../../interfaces/object-details';
import {Story} from '../../interfaces/story';

export const setObjectForPullUp = createAction(
  '[Object] Set Object for Pull Up',
  props<{object: ObjectDetails}>()
);

export const toggleTourSwiper = createAction(
  '[Object] Toggle swiping in tour component',
  props<{state: boolean}>()
);

export const setTourPointer = createAction(
  '[Object] Set Tour Pointer',
  props<{index: number, timestamp: string}>()
);

export const setStoryObject = createAction(
  '[Object] Set Story Object',
  props<{story: Story | null}>()
);

