import {Injectable, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationsComponent} from './notifications.component';
import {NotificationComponent} from './notification/notification.component';
import {AcfImageModule} from '../atoms/acf-image/acf-image.module';
import {PipesModule} from '../pipes/pipes.module';
import * as Hammer from 'hammerjs';
import {HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';


@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    pan: { direction: Hammer.DIRECTION_ALL }
  } as any;
}

@NgModule({
  declarations: [
    NotificationsComponent,
    NotificationComponent
  ],
  exports: [
    NotificationsComponent
  ],
  imports: [
    CommonModule,
    AcfImageModule,
    PipesModule,
    HammerModule,
    RouterModule
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ]
})
export class NotificationsModule { }
