import {Component, OnInit} from '@angular/core';
import {OpeningHoursService} from '../../services/opening-hours.service';
import {GlobalOptionsService} from '../../services/global-options.service';

@Component({
  selector: 'mak-opening-hours',
  templateUrl: './opening-hours.component.html',
  styleUrls: ['./opening-hours.component.scss']
})
export class OpeningHoursComponent implements OnInit {

  todayCloseTime = this.openingHoursService.getEmptyTime();
  state = false;

  constructor(private openingHoursService: OpeningHoursService,
              private globalOptionsService: GlobalOptionsService) {}

  ngOnInit(): void {
    this.globalOptionsService.getGlobalOptions().then((globalOptions) => {
      const openingHours = this.openingHoursService.getState(globalOptions.openingHours);
      this.todayCloseTime = openingHours.to;
      this.state = openingHours.isOpened;
    });
  }
}
