import {gql} from 'apollo-angular';
import {ObjectFragment} from './framents/ObjectFragment';

export const GET_CONTENT_BY_PATTERN = gql`
  query GetContentByPattern($pattern: String!) {
    contentNodes(where: {pattern: $pattern}) {
      edges {
        node {
          ... on Object {
            ...objectFields
          }
          ... on Tour {
            title
            databaseId
          }
          ... on Resource {
            title
            databaseId
            acfResourceFields {
              type
            }
          }
        }
      }
    }
  }
  ${ObjectFragment}
`;
