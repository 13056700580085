import {Component, Input, OnInit} from '@angular/core';
import {ObjectDetails} from '../../interfaces/object-details';
import {TabNavigationService} from '../../services/tab-navigation.service';
import {AppState} from '../../store';
import {Store} from '@ngrx/store';
import {getFavoriteObjects} from '../../store/selectors/user.selectors';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FavoritesService} from '../../services/favorites.service';
import {take} from 'rxjs/operators';
import {ScrollService} from '../../services/scroll.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mak-object-details-dashboard',
  templateUrl: './object-details-dashboard.component.html',
  styleUrls: ['./object-details-dashboard.component.scss']
})
export class ObjectDetailsDashboardComponent implements OnInit {

  @Input('objectDetails') set setObjectDetails(value: ObjectDetails | null) {
    if (value !== null) {
      this.objectDetails = value;
      this.store.select(getFavoriteObjects).pipe(take(1)).subscribe((response) => {
        if (this.objectDetails) {
          this.isInFavorites = this.favoritesService.isInFavoritesList(this.objectDetails, response);
        }
      });
    } else {
      this.objectDetails = null;
    }
  }
  objectDetails!: ObjectDetails | null;

  isInFavorites = false;

  isOnScrollTop = true;

  constructor(
    private tabNavigation: TabNavigationService,
    private store: Store<AppState>,
    private favoritesService: FavoritesService,
    private scrollService: ScrollService
  ) { }

  ngOnInit(): void {
    this.store.select(getFavoriteObjects).pipe(untilDestroyed(this)).subscribe((response) => {
      if (this.objectDetails) {
        this.isInFavorites = this.favoritesService.isInFavoritesList(this.objectDetails, response);
      }
    });

    this.scrollService.getScrollUpdate().pipe(untilDestroyed(this)).subscribe((response) => {
      this.isOnScrollTop = response <= this.scrollService.SCROLL_TOP_OFFSET;
    });
  }

  goBack(): void {
    this.tabNavigation.goBack();
  }

  toggleFavorites(): void {
    if (this.objectDetails) {
      this.favoritesService.toggleFavoriteObject(this.objectDetails);
    }
  }
}
