import {Injectable, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PullUpContainerComponent} from './pull-up-container.component';
import * as Hammer from 'hammerjs';
import {HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from '@angular/platform-browser';


@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    pan: { direction: Hammer.DIRECTION_VERTICAL }
  } as any;
}

@NgModule({
  declarations: [
    PullUpContainerComponent
  ],
  exports: [
    PullUpContainerComponent
  ],
  imports: [
    CommonModule,
    HammerModule
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ]
})
export class PullUpContainerModule { }
