import {Injectable} from '@angular/core';
import {Hint} from '../interfaces/hint';
import {Subject} from 'rxjs';
import {ImageService} from './image.service';
import {SharedService} from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class HintsService {

  private hintsSubject = new Subject<Hint[] | null>();

  constructor(private imageService: ImageService, private sharedService: SharedService) { }

  get hints(): Subject<Hint[] | null> {
    return this.hintsSubject;
  }

  startHintsTour(hints: Hint[]): void {
    this.hintsSubject.next(hints);
    this.sharedService.toggleBodyScroll(false);
  }

  closeHints(): void {
    this.hintsSubject.next(null);
    this.sharedService.toggleBodyScroll(true);
  }

  getEmptyHint(): Hint {
    return {
      de: {text: '', title: ''},
      en: {text: '', title: ''},
      media: {
        isImage: true,
        image: this.imageService.getEmptyImage(),
        lottieAnimationData: ''
      }
    };
  }
}
