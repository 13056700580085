import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainMenuComponent} from './main-menu.component';
import {OpeningHoursComponent} from './opening-hours/opening-hours.component';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    MainMenuComponent,
    OpeningHoursComponent
  ],
  exports: [
    MainMenuComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule
  ]
})
export class MainMenuModule { }
