import {gql} from 'apollo-angular';

export const ImageFragment = gql`
  fragment imageFields on MediaItem {
    title
    altText
    caption
    description
    mediaItemUrl
    srcSet
    sizes
    mediaDetails {
      height
      width
    }
  }
`;
