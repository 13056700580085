import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {MakRoutes} from './shared/MakRoutes';

const baseRoutes: Routes = [

];

const routes: Routes = [...baseRoutes, ...MakRoutes.ROUTES_DE];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
