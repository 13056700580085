import {gql} from 'apollo-angular';
import {ObjectFragment} from './framents/ObjectFragment';
import {ImageFragment} from './framents/ImageFragment';
import {TourFragment} from './framents/TourFragment';

export const GET_TOURS = gql`
  query GetTours {
    tours(first: 1000) {
      nodes {
        ... on Tour {
          ...tourFields
        }
      }
    }
  }
  ${ObjectFragment}
  ${ImageFragment},
  ${TourFragment}
  `;

