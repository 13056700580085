import {Injectable} from '@angular/core';
import {TourCard} from '../interfaces/tour-card';
import {ImageService} from './image.service';
import {ObjectDetailsService} from './object-details.service';

@Injectable({
  providedIn: 'root'
})
export class CardMapperService {

  constructor(
    private image: ImageService,
    private objectDetailsService: ObjectDetailsService
  ) { }

  mapCardData(rawContent: any, index = 0, tourId: number = -1): TourCard {
    return {
      id: rawContent.object.databaseId,
      object: this.objectDetailsService.mapObjectDetails(rawContent.object, tourId, index, rawContent.overrideTitleDe, rawContent.overrideTitleEn),
      de: {
        timestamp: rawContent.timestampDe ?? 0,
        duration: rawContent.durationDe ?? 0,
      },
      en: {
        timestamp: rawContent.timestampEn ?? 0,
        duration: rawContent.durationEn ?? 0,

      },
      image: this.image.parseAcfImage(rawContent.object?.acfObjectFields?.previewImage)
    };
  }
}
