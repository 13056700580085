import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ObjectGalleryComponent} from './object-gallery.component';
import {AcfImageModule} from '../atoms/acf-image/acf-image.module';
import {SwiperModule} from 'swiper/angular';


@NgModule({
    declarations: [
        ObjectGalleryComponent
    ],
    exports: [
        ObjectGalleryComponent
    ],
  imports: [
    CommonModule,
    AcfImageModule,
    SwiperModule
  ]
})
export class ObjectGalleryModule { }
