import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SocialActionsComponent} from './social-actions.component';
import {RouterModule} from '@angular/router';


@NgModule({
    declarations: [
        SocialActionsComponent
    ],
    exports: [
        SocialActionsComponent
    ],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class SocialActionsModule { }
