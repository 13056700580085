import {createReducer, on} from '@ngrx/store';
import {uniq, xorBy} from 'lodash';
import {
  addClosedAPINotificationIDs,
  addObjectToFavorites,
  addPlayedAudioId,
  addVisitedUrl,
  clearVisitedUrls,
  increaseCodeCounter,
  popVisitedUrl,
  setActiveTab,
  setFirstTourHintWatched,
  setIntroWatched,
  setLanguage,
  setMenuVisibility,
  setPreviousUrl,
  setRouterAnimationDirection,
  toggleFavoriteObject
} from '../actions/user.actions';
import {ObjectDetails} from '../../interfaces/object-details';
import {Languages} from '../../enums/languages';
import {NavigationTabs} from '../../enums/navigation-tabs';
import {RouterAnimationDirections} from '../../enums/router-animation-directions';

export const userFeatureKey = 'user';

export interface State {
  introWatched: boolean;
  firstTourHintWatched: boolean;
  playedAudioIds: string[];
  favoriteObjects: ObjectDetails[];
  language: Languages;
  activeTab: NavigationTabs;
  previousUrl: string;
  visitedPagesCodeTab: string[];
  visitedPagesHomeTab: string[];
  visitedPagesAudioTab: string[];
  visitedPagesLocationTab: string[];
  visitedPagesFavoritesTab: string[];
  routerAnimationDirection: RouterAnimationDirections;
  menuVisible: boolean;
  codeCounter: number;
  closedAPINotificationIDs: number[];
}

export const initialState: State = {
  introWatched: false,
  firstTourHintWatched: false,
  playedAudioIds: new Array(0),
  favoriteObjects: new Array(0),
  language: Languages.GERMAN,
  activeTab: NavigationTabs.HOME,
  previousUrl: '',
  visitedPagesCodeTab: new Array(0),
  visitedPagesHomeTab: new Array(0),
  visitedPagesAudioTab: new Array(0),
  visitedPagesLocationTab: new Array(0),
  visitedPagesFavoritesTab: new Array(0),
  routerAnimationDirection: RouterAnimationDirections.NONE,
  menuVisible: true,
  codeCounter: 0,
  closedAPINotificationIDs: []
};

export const reducer = createReducer(
  initialState,
  on(setIntroWatched, (state, action) => {
    return {
      ...state,
      introWatched: action.watched
    };
  }),
  on(setFirstTourHintWatched, (state, action) => {
    return {
      ...state,
      firstTourHintWatched: action.state
    };
  }),
  on(addPlayedAudioId, (state, action) => {
    let existingPlayedObjectIds = [...state.playedAudioIds];
    existingPlayedObjectIds.push(action.audioId);
    existingPlayedObjectIds = uniq(existingPlayedObjectIds);
    return {
      ...state,
      playedAudioIds: existingPlayedObjectIds
    };
  }),
  on(addObjectToFavorites, (state, action) => {
    let existingFavoriteObjects = [...state.favoriteObjects];
    existingFavoriteObjects.push(action.object);
    existingFavoriteObjects = uniq(existingFavoriteObjects);
    return {
      ...state,
      favoriteObjects: existingFavoriteObjects
    };
  }),
  on(toggleFavoriteObject, (state, action) => {
    return {
      ...state,
      favoriteObjects: xorBy([...state.favoriteObjects], [Object.assign({}, action.object)], 'id')
    };
  }),
  on(setLanguage, (state, action) => {
    return {
      ...state,
      language: action.language
    };
  }),
  on(setActiveTab, (state, action) => {
    return {
      ...state,
      activeTab: action.tab
    };
  }),
  on(addVisitedUrl, (state, action) => {

    switch (action.tab) {
      case NavigationTabs.HOME:

        const visitedUrlsHomeTab = [...state.visitedPagesHomeTab];
        if (visitedUrlsHomeTab[visitedUrlsHomeTab.length - 1] !== action.url) {
          visitedUrlsHomeTab.push(action.url);
        }

        return {
          ...state,
          visitedPagesHomeTab: visitedUrlsHomeTab
        };
      case NavigationTabs.CODE:

        const visitedUrlsCodeTab = [...state.visitedPagesCodeTab];
        if (visitedUrlsCodeTab[visitedUrlsCodeTab.length - 1] !== action.url) {
          visitedUrlsCodeTab.push(action.url);
        }

        return {
          ...state,
          visitedPagesCodeTab: visitedUrlsCodeTab
        };
      case NavigationTabs.AUDIO:

        const visitedUrlsAudioTab = [...state.visitedPagesAudioTab];
        if (visitedUrlsAudioTab[visitedUrlsAudioTab.length - 1] !== action.url) {
          visitedUrlsAudioTab.push(action.url);
        }

        return {
          ...state,
          visitedPagesAudioTab: visitedUrlsAudioTab
        };
      case NavigationTabs.LOCATION:

        const visitedUrlsLocationTab = [...state.visitedPagesLocationTab];
        if (visitedUrlsLocationTab[visitedUrlsLocationTab.length - 1] !== action.url) {
          visitedUrlsLocationTab.push(action.url);
        }

        return {
          ...state,
          visitedPagesLocationTab: visitedUrlsLocationTab
        };
      case NavigationTabs.FAVORITES:

        const visitedUrlsFavoritesTab = [...state.visitedPagesFavoritesTab];
        if (visitedUrlsFavoritesTab[visitedUrlsFavoritesTab.length - 1] !== action.url) {
          visitedUrlsFavoritesTab.push(action.url);
        }

        return {
          ...state,
          visitedPagesFavoritesTab: visitedUrlsFavoritesTab
        };

      default:
        console.log('Could not map visited URL to Tab: ' + action.tab);
    }

    return {
      ...state
    };

  }),
  on(popVisitedUrl, (state, action) => {

    switch (action.tab) {
      case NavigationTabs.HOME:

        const visitedUrlsHomeTab = [...state.visitedPagesHomeTab];
        visitedUrlsHomeTab.pop();

        return {
          ...state,
          visitedPagesHomeTab: visitedUrlsHomeTab
        };
      case NavigationTabs.CODE:

        const visitedUrlsCodeTab = [...state.visitedPagesCodeTab];
        visitedUrlsCodeTab.pop();

        return {
          ...state,
          visitedPagesCodeTab: visitedUrlsCodeTab
        };
      case NavigationTabs.AUDIO:

        const visitedUrlsAudioTab = [...state.visitedPagesAudioTab];
        visitedUrlsAudioTab.pop();

        return {
          ...state,
          visitedPagesAudioTab: visitedUrlsAudioTab
        };

      case NavigationTabs.LOCATION:

        const visitedUrlsLocationTab = [...state.visitedPagesLocationTab];
        visitedUrlsLocationTab.pop();

        return {
          ...state,
          visitedPagesLocationTab: visitedUrlsLocationTab
        };

      case NavigationTabs.FAVORITES:

        const visitedUrlsFavoritesTab = [...state.visitedPagesFavoritesTab];
        visitedUrlsFavoritesTab.pop();

        return {
          ...state,
          visitedPagesFavoritesTab: visitedUrlsFavoritesTab
        };

      default:
        console.log('Could not pop visited URL in Tab: ' + action.tab);
    }

    return {
      ...state
    };

  }),
  on(clearVisitedUrls, (state, action) => {

    switch (action.tab) {
      case NavigationTabs.HOME:
        return {
          ...state,
          visitedPagesHomeTab: new Array(0)
        };
      case NavigationTabs.CODE:
        return {
          ...state,
          visitedPagesCodeTab: new Array(0)
        };
      case NavigationTabs.AUDIO:
        return {
          ...state,
          visitedPagesAudioTab: new Array(0)
        };
      case NavigationTabs.LOCATION:
        return {
          ...state,
          visitedPagesLocationTab: new Array(0)
        };
      case NavigationTabs.FAVORITES:
        return {
          ...state,
          visitedPagesFavoritesTab: new Array(0)
        };
      default:
        console.log('Could not reset visited URL in Tab: ' + action.tab);
    }

    return {
      ...state
    };
  }),
  on(setRouterAnimationDirection, (state, action) => {
    return {
      ...state,
      routerAnimationDirection: action.direction
    };
  }),
  on(setMenuVisibility, (state, action) => {
    return {
      ...state,
      menuVisible: action.visible
    };
  }),
  on(increaseCodeCounter, (state, action) => {
    return {
      ...state,
      codeCounter: state.codeCounter + 1
    };
  }),
  on(setPreviousUrl, (state, action) => {
    return {
      ...state,
      previousUrl: action.url
    };
  }),
  on(addClosedAPINotificationIDs, (state, action) => {
    return {
      ...state,
      closedAPINotificationIDs: [... new Set([...state.closedAPINotificationIDs, ...action.ids])]
    };
  })
);
