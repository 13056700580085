<div class="object-details-related-tours" *ngIf="relatedTours.length > 0 && !isLoading;">

  <div class="object-details-related-tours__title" *ngIf="relatedTours.length === 1; else multipleToursFound">
    {{ "RELATED_TOURS.SINGLE_RELATED_TOURS" | translate }}
  </div>
  <ng-template #multipleToursFound>
    <div class="object-details-related-tours__title">
      {{ "RELATED_TOURS.MULTIPLE_RELATED_TOURS" | translate:{number: relatedTours.length} }}
    </div>
  </ng-template>
  <div class="object-details-related-tours__items">
    <div class="object-details-related-tours__item" *ngFor="let tour of relatedTours" (click)="openMenuForItem(tour)">
      <mak-content-preview [data]="tour.tour"></mak-content-preview>
    </div>
  </div>
</div>

<div class="object-details-related-tours__loader" *ngIf="isLoading"></div>

<mak-pull-up-container [state]="menuState" (onStateChanged)="toggleMenuState($event)" [additionalPaddingTop]="true">
  <div class="object-details-related-tours__menu-items" *ngIf="activeTour !== null">
    <div (click)="tabNavigation.gotDeeperInTab('/tour/' + activeTour.tour.id)" class="object-details-related-tours__menu-item">
      {{ "RELATED_TOURS.MENU_TOUR_OVERVIEW" | translate }} <span class="icon icon-chevron-right"></span>
    </div>
    <div (click)="tabNavigation.gotDeeperInTab('/tour-object/' + activeTour.tour.id + '/' + activeTour.cardIdx)" class="object-details-related-tours__menu-item">
      {{ "RELATED_TOURS.MENU_TOUR_OBJECT" | translate }} <span class="icon icon-chevron-right"></span>
    </div>
  </div>
</mak-pull-up-container>
