import {Injectable} from '@angular/core';
import {ObjectDetails} from '../interfaces/object-details';
import {ImageService} from './image.service';
import {StoryService} from './story.service';
import {DirectionMapperService} from './direction-mapper.service';
import {Playlist} from '../interfaces/playlist';

@Injectable({
  providedIn: 'root'
})
export class ObjectDetailsService {

  constructor(
    private image: ImageService,
    private storyService: StoryService,
    private directionMapper: DirectionMapperService
  ) { }

  getEmptyObjectDetails(): ObjectDetails {
    return {
      id: 0,
      audioId: '',
      locationData: false,
      indexInTour: null,
      pattern: '',
      de: {
        title: '',
        textContent: []
      },
      en: {
        title: '',
        textContent: []
      },
      image: {
        title: '',
        alt: '',
        caption: '',
        src: '',
        srcSet: '',
        width: 0,
        height: 0
      },
      resources: {
        stories: [],
        audios: [],
        links: []
      },
      gallery: [],
      playlist: undefined,
      direction: undefined
    };
  }

  mapObjectDetails(
    contentNode: any, parentTourId: number = -1,
    indexInTour: number = -1,
    overrideTitleDe = '',
    overrideTitleEn = ''
  ): ObjectDetails {

    const objectDetails = this.getEmptyObjectDetails();
    objectDetails.id = contentNode.databaseId;
    objectDetails.audioId = contentNode.databaseId.toString();


    let {acfObjectFields} = contentNode;
    if (typeof acfObjectFields !== 'object') { acfObjectFields = {}; }

    objectDetails.indexInTour = indexInTour;

    objectDetails.de.title = (overrideTitleDe && overrideTitleDe.length > 0) ?
      overrideTitleDe : contentNode.title ?? '';
    objectDetails.en.title =  (overrideTitleEn && overrideTitleEn.length > 0) ?
      overrideTitleEn : contentNode.acfObjectFields.titleEn ? contentNode.acfObjectFields.titleEn : objectDetails.de.title;

    if (contentNode?.entityCode?.codeAssignment?.length && contentNode.entityCode.codeAssignment[0].title) {
      objectDetails.pattern = contentNode.entityCode.codeAssignment[0].title;
    }

    if ( contentNode?.acfObjectFields?.objectsResources) {
      contentNode?.acfObjectFields?.objectsResources.forEach((el: any, index: number) => {
        if (el.acfResourceFields?.type === 'story') {
          objectDetails.resources.stories.push(this.storyService.parseStoryResource(el));
        }

        if (el.acfResourceFields?.type === 'audio') {

          const audioResource: Playlist = {
            sprite: {
              de: el.acfResourceFields?.audio?.audioFileDe?.mediaItemUrl ?? undefined,
              en: el.acfResourceFields?.audio?.audioFileEn?.mediaItemUrl ?? undefined,
            },
            items: [
              {
                objectDetailsId: contentNode.databaseId,
                parentTourId,
                audioId: el.databaseId.toString(),
                thumbnail: this.image.parseAcfImage(acfObjectFields.previewImage),
                de: {
                  title: el.title ?? '',
                  timestamp: 0,
                  duration: el.acfResourceFields?.audioDurationDe ?? 0,
                  audioTranscript: el.acfResourceFields?.audio?.transcriptDe ?? ''
                },
                en: {
                  title: el.acfResourceFields?.titleEn ?? '',
                  timestamp: 0,
                  duration: el.acfResourceFields?.audioDurationEn ?? 0,
                  audioTranscript: el.acfResourceFields?.audio?.transcriptEn ?? ''
                }
              }
            ]
          };

          objectDetails.resources.audios.push(audioResource);

        }

        if (el.acfResourceFields?.type === 'link') {

          objectDetails.resources.links.push({
            de: {
              title: el.acfResourceFields?.linkDe?.title ?? '',
              url: el.acfResourceFields?.linkDe?.url ?? ''
            },
            en: {
              title: el.acfResourceFields?.linkEn?.title ?
                el.acfResourceFields?.linkEn?.title ?? '' :
                el.acfResourceFields?.linkDe?.title ?? '',
              url: el.acfResourceFields?.linkEn?.url ?
                el.acfResourceFields?.linkEn?.url ?? '' :
                el.acfResourceFields?.linkDe?.url ?? ''
            }
          });

        }

      });
    }

    if (contentNode.acfObjectFields?.textContentDe) {
      contentNode.acfObjectFields?.textContentDe.forEach((el: {text: string | null}) => {
        if (el.text !== null) {
          objectDetails.de.textContent.push(el.text);
        }
      });
    }

    if (contentNode.acfObjectFields?.textContentEn) {
      contentNode.acfObjectFields?.textContentEn.forEach((el: {text: string | null}) => {
        if (el.text !== null) {
          objectDetails.en.textContent.push(el.text);
        }
      });
    }

    objectDetails.image = this.image.parseAcfImage(acfObjectFields.previewImage);
    objectDetails.playlist = {
      sprite: {
        de: contentNode.acfObjectFields.audioDescriptionDe?.mediaItemUrl ?? undefined,
        en: contentNode.acfObjectFields.audioDescriptionEn?.mediaItemUrl ?? undefined,
      },
      items: [
        {
          objectDetailsId: contentNode.databaseId,
          parentTourId,
          audioId: contentNode.databaseId.toString(),
          thumbnail: objectDetails.image,
          de: {
            title: objectDetails.de.title ?? '',
            timestamp: 0,
            duration: contentNode.acfObjectFields.audioDurationDe ?? 0,
            audioTranscript: contentNode.acfObjectFields.audioTranscriptDe ?? ''
          },
          en: {
            title: objectDetails.en.title ? objectDetails.en.title : objectDetails.de.title ?? '',
            timestamp: 0,
            duration: contentNode.acfObjectFields.audioDurationEn ?? 0,
            audioTranscript: contentNode.acfObjectFields.audioTranscriptEn ?? ''
          }
        }
      ]
    };

    try {
      if (contentNode.acfObjectFields?.locationData) {
        objectDetails.locationData = JSON.parse(contentNode.acfObjectFields.locationData);
      }

    } catch (e) {
      console.error('Could not parse Location JSON');
    }

    if (contentNode.acfObjectFields?.gallery?.length) {
      objectDetails.gallery = contentNode.acfObjectFields?.gallery?.map((el: any) => {
        return this.image.parseAcfImage(el);
      });
    } else {
      objectDetails.gallery.push(this.image.parseAcfImage(acfObjectFields.previewImage));
    }

    // Parse Direction
    if (contentNode.acfObjectFields?.directionsObjects?.length) {
      contentNode.acfObjectFields?.directionsObjects.forEach((rawDirection: any) => {
        objectDetails.direction = this.directionMapper.mapDirectionData(rawDirection);
      });
    }
    return objectDetails;
  }

}
