import {gql} from 'apollo-angular';
import {ImageFragment} from './framents/ImageFragment';
import {PoiFragment} from './framents/PoiFragment';

export const GET_POIS = gql`
  query GetPOIs {
    pois(first: 1000) {
      nodes {
        ...poiFields
      }
    }
  }
  ${ImageFragment}
  ${PoiFragment}
  `;
