import {gql} from 'apollo-angular';
import {ImageFragment} from './ImageFragment';

export const PoiFragment = gql`
  fragment poiFields on Poi {
    databaseId
    title
    acfPoi {
      titleEn
      image {
        ...imageFields
      }
      locationData
      type
      icon
      link {
        title
        url
      }
    }
  }
  ${ImageFragment}
`;
