<div class="story-card">
  <div class="story-card__content-block" *ngFor="let block of card.contentBlock">
    <div class="story-card__image-block" *ngIf="block.image else textBlock">
      <mak-acf-image [image-data]="block.image" [disable-lazy-loading]="true"></mak-acf-image>
    </div>
    <ng-template #textBlock>
      <div class="story-card__text-block" [innerHTML]="block | translateKey:'text':(lang$ | async)"></div>
    </ng-template>
  </div>
</div>
