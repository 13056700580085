import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../store';
import {Notification} from '../interfaces/notification';
import {NotificationsService} from '../services/notifications.service';

@Component({
  selector: 'mak-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  activeNotification: Notification | null = null;

  constructor(private store: Store<AppState>, private notifications: NotificationsService) {
  }

  ngOnInit(): void {
    this.notifications.notificationsUpdater.subscribe((value) => {
      this.pushNotification(value);
    });
  }

  pushNotification(notification: Notification): void {
    if (this.activeNotification !== null) {
      this.activeNotification = null;

      setTimeout(() => {
        this.activeNotification = notification;
      }, this.notifications.animationDuration + 50);
    } else {
      this.activeNotification = notification;
    }
  }

  hideNotification(): void {
    this.activeNotification = null;
  }

  dummyPush(): void {
    const random = Math.random();
    this.notifications.pushNotification({
      de: {text: 'Super text De Super text De Super text De ' + random, tag: 'here is tag', linkUrl: '#', linkTitle: 'Titel auf Deutsch', linkIsExtern: false},
      en: {text: 'Super Text En Super Text En Super Text En ' + random, tag: 'here is tag', linkUrl: '#', linkTitle: 'Title on English', linkIsExtern: false},
      image: 'https://www.dosplash.com/wp-content/uploads/2019/01/40139-dummy-src-instant-free-placeholder-images-for-jpg--dummysrc-og1.jpg'
    });
  }
}
