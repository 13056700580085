<ng-template #desktopPageContainer></ng-template>

<mak-social-actions></mak-social-actions>

<mak-audio-player></mak-audio-player>
<mak-notifications></mak-notifications>
<mak-object-gallery-enlarged></mak-object-gallery-enlarged>
<mak-hints></mak-hints>
<mak-story></mak-story>

<div class="app__router-wrapper"
     #routerWrapperNode [@routeAnimations]="animationState"
     [ngClass]="{'app__router-wrapper--ios' : iosVersion !== undefined && iosVersion < 15, 'app__router-wrapper--ios-new' : iosVersion !== undefined && iosVersion >= 15}">
  <router-outlet #outlet="outlet" (activate)="onActivate($event)"></router-outlet>
</div>

<mak-main-nav></mak-main-nav>

<mak-single-hint></mak-single-hint>

<mak-turn-device *ngIf="showTurnDeviceOverlay"></mak-turn-device>
