import {gql} from 'apollo-angular';
import {ImageFragment} from './ImageFragment';
import {YoastSeoFragment} from './YoastSeoFragment';

export const TourPreviewFragment = gql`
  fragment tourPreviewFields on Tour {
    databaseId
    entityCode {
      codeAssignment {
        ... on Code {
          title
        }
      }
    }
    title
    acf {
      descriptionDe
      descriptionEn
      titleEn
      hiddenInArchive
      cards {
        ... on Tour_Acf_Cards_Object {
          object {
            ... on Object {
              databaseId
            }
          }
          overrideAudioDe {
            mediaItemUrl
          }
          overrideAudioEn {
            mediaItemUrl
          }
          overrideWithResource
          resource {
            ... on Resource {
              databaseId
            }
          }
        }
      }
      previewImages {
        ...imageFields
      }
      audioSpriteFileDe
      audioSpriteFileEn
      audioDurationDe
      audioDurationEn
      isExternalTour
      externalTourLink {
        target
        title
        url
      }
    }
    seo {
      ...yoastSeoFields
    }
  }
  ${YoastSeoFragment}
  ${ImageFragment}
`;
