import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../store';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {getLanguage} from '../store/selectors/user.selectors';
import {Languages} from '../enums/languages';
import {NavigationTabs} from '../enums/navigation-tabs';
import {TabNavigationService} from '../services/tab-navigation.service';
import {LanguageService} from '../services/language.service';
import {HintsService} from '../services/hints.service';
import {GlobalOptionsService} from '../services/global-options.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mak-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  @Output('onMainMenuClosed') onMainMenuClosed = new EventEmitter();

  currentLanguage = Languages.GERMAN;

  languages = Languages;

  constructor(
    private store: Store<AppState>,
    private tabNavigation: TabNavigationService,
    private languageService: LanguageService,
    private hintsService: HintsService,
    private globalOptionsService: GlobalOptionsService
  ) { }

  ngOnInit(): void {
    this.store.select(getLanguage)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.currentLanguage = response;
      });
  }

  closeMenu(): void {
    this.onMainMenuClosed.emit();
  }

  setLanguage(language: Languages): void {
    this.onMainMenuClosed.emit();
    this.languageService.setLanguage(language);
  }

  gotToTab(audioArchive: string): void {
    this.onMainMenuClosed.emit();
    switch (audioArchive) {
      case 'favoriten':
        this.tabNavigation.goToTab(NavigationTabs.FAVORITES);
        break;
      default:
        console.warn('MainMenuComponent/gotToTab: ' + audioArchive + ' not mapped');
    }
  }

  openTutorial(): void {
    this.globalOptionsService.getGlobalOptions().then((response) => {
      this.onMainMenuClosed.emit();
      this.hintsService.startHintsTour(response.introTourCards);
    });
  }

  toSinglePage(url: string): void {
    this.onMainMenuClosed.emit();
    this.tabNavigation.goToSinglePage(url);
  }
}
