<div class="equaliser" [ngClass]="{'equaliser--play' : state}">
  <ol class="equaliser__column">
    <li class="equaliser__bar"></li>
  </ol>
  <ol class="equaliser__column">
    <li class="equaliser__bar"></li>
  </ol>
  <ol class="equaliser__column">
    <li class="equaliser__bar"></li>
  </ol>
  <ol class="equaliser__column">
    <li class="equaliser__bar"></li>
  </ol>
  <ol class="equaliser__column">
    <li class="equaliser__bar"></li>
  </ol>
</div>
