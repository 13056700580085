<div class="audio-player audio-player__mini" [ngClass]="{'audio-player__mini--visible' : mode === makConstants.playerModes.mini && playlistItems.length > 0}">
  <mak-mini-player [current-item]="playlistItems[localPlayerState.index]" [progress]="progress" (title-clicked)="maximizePlayer()" (onMiniPlayerClosed)="closePlayer()"></mak-mini-player>
</div>

<div class="audio-player audio-player__full" [ngClass]="{'audio-player__full--visible' : mode === makConstants.playerModes.full && playlistItems.length > 0}">

  <div class="audio-player__dashboard">
    <div class="audio-player__dashboard-left">
      <div class="audio-player__minimize" (click)="minimizePlayer()"><span class="icon icon-chevron-down"></span></div>
    </div>

    <div class="audio-player__dashboard-right" *ngIf="showObjectActions">
      <div class="audio-player__favorites" *ngIf="playlistItems.length === 1" (click)="toggleFavorites()">
        <span class="icon icon-heart-outline" [ngClass]="{'icon-heart-full' : isSingleObjectInFavorites, 'icon-heart-outline': !isSingleObjectInFavorites}"></span>
      </div>
      <div class="audio-player__more" *ngIf="playlistItems.length === 1" (click)="toggleObjectMenu(0)"><span class="icon icon-more"></span></div>
    </div>

  </div>


  <div class="audio-player__playlist-wrapper" #scrollablePlaylist>
    <mak-playlist-viewer (itemMenuOpened)="toggleObjectMenu($event)" [currentIndex]="indexInState" #makPlaylistViewer></mak-playlist-viewer>
  </div>
  <div class="audio-player__controls-wrapper">
    <mak-player-controls [progress]="progress" (seek-position)="setSeekPosition($event)" (skip)="skip($event)" [seek]="seek" [duration]="spriteDuration" [timestamp]="spriteTimestamp" (seek-position-progress)="setSeekPositionByProgress($event)" (seek-jump)="seekJumpDirection($event)"></mak-player-controls>
  </div>
</div>

<mak-pull-up-container (onStateChanged)="closeTranscriptionPanel()" [state]="transcriptPanelState" [isScrollable]="true" [additionalPaddingTop]="true">
  <mak-transcript-panel [transcript]="audioTranscript" (onTranscriptPanelClosed)="closeTranscriptionPanel()"></mak-transcript-panel>
</mak-pull-up-container>

<mak-pull-up-container (onStateChanged)="closePlayerSpeed()" [state]="playerSpeedState">
  <mak-player-speed (onPlayerSpeedClosed)="closePlayerSpeed()"></mak-player-speed>
</mak-pull-up-container>

<mak-pull-up-container [state]="isObjectMenuOpened" (onStateChanged)="toggleObjectMenuState($event)">
  <mak-object-preview-menu [tourId]="relatedTourId" (onMenuClosed)="toggleObjectMenuState(false)" (onLinkToObjectClicked)="minimizePlayer()"></mak-object-preview-menu>
</mak-pull-up-container>




