<div class="single-hint" *ngIf="hintVisible">
  <div class="single-hint__content">
    <div class="single-hint__close" (click)="closeHint()"><span class="icon-close"></span></div>
    <div class="single-hint__items">
      <div class="single-hint__item">
        <mak-hints-item [hint]="hint"></mak-hints-item>
      </div>
    </div>
    <div class="single-hint__footer">
      <button class="single-hint__button" (click)="closeHint()">
        {{ 'GENERAL.OK' | translate }}
      </button>
    </div>
  </div>
</div>
