import {gql} from 'apollo-angular';
import {ImageFragment} from './ImageFragment';
import {DirectionFragment} from './DirectionFragment';

export const ObjectPreviewFragment = gql`
  fragment objectPreviewFields on Object {
    databaseId
    title
    acfObjectFields {
      titleEn
      previewImage {
        ...imageFields
      }
      locationData
      audioDurationDe
      audioTranscriptDe
      audioTranscriptEn
      audioDescriptionDe {
        mediaItemUrl
      }
      audioDurationEn
      audioDescriptionEn {
        mediaItemUrl
      }
      directionsObjects {
        ... on Direction {
          ...directionFields
        }
      }
    }
    entityCode {
      codeAssignment {
        ... on Code {
          title
        }
      }
    }
  }
  ${ImageFragment}
  ${DirectionFragment}
`;
