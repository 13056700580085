import {Injectable, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AudioPlayerComponent} from './audio-player.component';
import {MiniPlayerComponent} from './mini-player/mini-player.component';
import {AcfImageModule} from '../atoms/acf-image/acf-image.module';
import {PipesModule} from '../pipes/pipes.module';
import {PlayerControlsComponent} from './player-controls/player-controls.component';
import {PlaylistViewerComponent} from './playlist-viewer/playlist-viewer.component';
import {PlaylistLineItemComponent} from './playlist-line-item/playlist-line-item.component';
import {TranscriptPanelComponent} from './transcript-panel/transcript-panel.component';
import {PlayerSpeedComponent} from './player-speed/player-speed.component';
import {PullUpContainerModule} from '../pull-up-container/pull-up-container.module';
import * as Hammer from 'hammerjs';
import {HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from '@angular/platform-browser';
import {ObjectPreviewMenuModule} from '../object-preview-menu/object-preview-menu.module';
import {EqualizerComponent} from './equalizer/equalizer.component';


@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    pan: { direction: Hammer.DIRECTION_VERTICAL }
  } as any;
}
@NgModule({
  declarations: [
    AudioPlayerComponent,
    MiniPlayerComponent,
    PlayerControlsComponent,
    PlaylistViewerComponent,
    PlaylistLineItemComponent,
    TranscriptPanelComponent,
    PlayerSpeedComponent,
    EqualizerComponent
  ],
  imports: [
    CommonModule,
    AcfImageModule,
    PipesModule,
    PullUpContainerModule,
    HammerModule,
    ObjectPreviewMenuModule
  ],
  exports: [
    AudioPlayerComponent
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ]
})
export class AudioPlayerModule { }
