import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../store';
import {Router} from '@angular/router';
import {NavigationTabs} from '../enums/navigation-tabs';
import {addVisitedUrl, clearVisitedUrls, popVisitedUrl, setActiveTab, setRouterAnimationDirection} from '../store/actions/user.actions';
import {
  getActiveTab,
  getVisitedPagesAudioTab,
  getVisitedPagesCodeTab,
  getVisitedPagesFavoritesTab,
  getVisitedPagesHomeTab,
  getVisitedPagesLocationTab
} from '../store/selectors/user.selectors';
import {take} from 'rxjs/operators';
import {RouterAnimationDirections} from '../enums/router-animation-directions';
import {ScrollService} from './scroll.service';

@Injectable({
  providedIn: 'root'
})
export class TabNavigationService {

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private scrollService: ScrollService
  ) {}

  gotDeeperInTab(url: string): void {
    this.store.dispatch(setRouterAnimationDirection({direction: RouterAnimationDirections.RIGHT}));
    this.router.navigateByUrl(url);
  }

  addRouteToState(): void {
    const url = this.router.url;
    this.store.select(getActiveTab)
      .pipe(take(1))
      .subscribe(response => {
      this.store.dispatch(addVisitedUrl({url, tab: response}));
    });
  }

  goToSinglePage(url: string): void {
    this.store.dispatch(setActiveTab({tab: NavigationTabs.NONE}));
    this.gotDeeperInTab(url);
    this.scrollService.setScrollTop();
  }

  scrollTop(): void {
  }

  goBack(): void {
    this.store.dispatch(setRouterAnimationDirection({direction: RouterAnimationDirections.LEFT}));
    this.store.select(getActiveTab)
      .pipe(take(1))
      .subscribe(activeTab => {
        this.store.dispatch(popVisitedUrl({tab: activeTab}));
        switch (activeTab) {
          case NavigationTabs.CODE:
            this.loadCodeTab();
            break;
          case NavigationTabs.AUDIO:
            this.loadAudioTab();
            break;
          case NavigationTabs.LOCATION:
            this.loadLocationTab();
            break;
          case NavigationTabs.FAVORITES:
            this.loadFavoritesTab();
            break;
          case NavigationTabs.HOME:
            this.loadHomeTab();
            break;
          default:
            console.log('TabNavigationService/goBack tab not mapped:' + activeTab);
        }
      });
  }

  goToTab(activeTab: NavigationTabs): void {
    this.store.dispatch(setRouterAnimationDirection({direction: RouterAnimationDirections.NONE}));
    this.store.select(getActiveTab).pipe(take(1)).subscribe(response => {
      this.scrollService.setScrollTop();
      if (response === activeTab) {
        // Same Tab --> reset Tab and go to Start of Tab
        switch (activeTab) {
          case NavigationTabs.HOME:
            this.store.dispatch(setActiveTab({tab: NavigationTabs.HOME}));
            this.store.dispatch(clearVisitedUrls({tab: NavigationTabs.HOME}));
            this.router.navigateByUrl('/');
            break;
          case NavigationTabs.CODE:
            this.store.dispatch(setActiveTab({tab: NavigationTabs.CODE}));
            this.store.dispatch(clearVisitedUrls({tab: NavigationTabs.CODE}));
            this.router.navigateByUrl('/code');
            break;
          case NavigationTabs.AUDIO:
            this.store.dispatch(setActiveTab({tab: NavigationTabs.AUDIO}));
            this.store.dispatch(clearVisitedUrls({tab: NavigationTabs.AUDIO}));
            this.router.navigateByUrl('/audio');
            break;
          case NavigationTabs.LOCATION:
            this.store.dispatch(setActiveTab({tab: NavigationTabs.LOCATION}));
            this.store.dispatch(clearVisitedUrls({tab: NavigationTabs.LOCATION}));
            this.router.navigateByUrl('/locations');
            break;
          case NavigationTabs.FAVORITES:
            this.store.dispatch(setActiveTab({tab: NavigationTabs.FAVORITES}));
            this.store.dispatch(clearVisitedUrls({tab: NavigationTabs.FAVORITES}));
            this.router.navigateByUrl('/favoriten');
            break;
          default:
            this.store.dispatch(setActiveTab({tab: NavigationTabs.NONE}));
            console.log('TabNavigationService/goToTab (if) tab not mapped:' + activeTab);
        }
      } else {
        // Tab Changed
        switch (activeTab) {
          case NavigationTabs.HOME:
            this.store.dispatch(setActiveTab({tab: NavigationTabs.HOME}));
            this.loadHomeTab();
            break;
          case NavigationTabs.CODE:
            this.store.dispatch(setActiveTab({tab: NavigationTabs.CODE}));
            this.loadCodeTab();
            break;
          case NavigationTabs.AUDIO:
            this.store.dispatch(setActiveTab({tab: NavigationTabs.AUDIO}));
            this.loadAudioTab();
            break;
          case NavigationTabs.LOCATION:
            this.store.dispatch(setActiveTab({tab: NavigationTabs.LOCATION}));
            this.loadLocationTab();
            break;
          case NavigationTabs.FAVORITES:
            this.store.dispatch(setActiveTab({tab: NavigationTabs.FAVORITES}));
            this.loadFavoritesTab();
            break;
          default:
            this.store.dispatch(setActiveTab({tab: NavigationTabs.NONE}));
            console.log('TabNavigationService/goToTab (else) tab not mapped:' + activeTab);
        }
      }
    });
  }

  loadHomeTab(): void {
    this.store.select(getVisitedPagesHomeTab)
      .pipe(take(1))
      .subscribe(response => {
        if (response.length > 0) {
          this.router.navigateByUrl(response[response.length - 1]);
        } else {
          this.router.navigateByUrl('/');
        }
      });
  }

  loadCodeTab(): void {
    this.store.select(getVisitedPagesCodeTab)
      .pipe(take(1))
      .subscribe(response => {
        if (response.length > 0) {
          this.router.navigateByUrl(response[response.length - 1]);
        } else {
          this.router.navigateByUrl('/code');
        }
      });
  }

  loadAudioTab(): void {
    this.store.select(getVisitedPagesAudioTab)
      .pipe(take(1))
      .subscribe(response => {
        if (response.length > 0) {
          this.router.navigateByUrl(response[response.length - 1]);
        } else {
          this.router.navigateByUrl('/audio');
        }
      });
  }

  loadLocationTab(): void {
    this.store.select(getVisitedPagesLocationTab)
      .pipe(take(1))
      .subscribe(response => {
        if (response.length > 0) {
          this.router.navigateByUrl(response[response.length - 1]);
        } else {
          this.router.navigateByUrl('/locations');
        }
      });
  }

  loadFavoritesTab(): void {
    this.store.select(getVisitedPagesFavoritesTab)
      .pipe(take(1))
      .subscribe(response => {
        if (response.length > 0) {
          this.router.navigateByUrl(response[response.length - 1]);
        } else {
          this.router.navigateByUrl('/favoriten');
        }
      });
  }

}
