import {gql} from 'apollo-angular';
import {ImageFragment} from './ImageFragment';

export const DirectionFragment = gql`
  fragment directionFields on Direction {
    ...directionAcfFields
    acfDirection {
      base {
        ...directionAcfFields
        ... on Direction {
          acfDirection {
            base {
              ...directionAcfFields
              ... on Direction {
                acfDirection {
                  base {
                    ...directionAcfFields
                    ... on Direction {
                      acfDirection {
                        base {
                          ...directionAcfFields
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  }


  fragment directionAcfFields on Direction {
    databaseId
    title
    acfDirection {
      cards {
        content {
          ... on Direction_Acfdirection_cards_Content_Text {
            fieldGroupName
            textDe
            textEn
          }
          ... on Direction_Acfdirection_cards_Content_Image {
            fieldGroupName
            image {
              ...imageFields
            }
          }
        }
      }
    }
  }
  ${ImageFragment}
`;
