<div class="object-preview-menu">
  <ul class="object-preview-menu__items">
    <li (click)="linkToObjectClicked()" class="object-preview-menu__item">
      <span class="icon icon-arrow-extern"></span>{{ "OBJECT_PREVIEW_MENU.TO_OBJECT" | translate }}
    </li>
    <li class="object-preview-menu__item" (click)="onFavoritesToggleClicked()">
      <ng-container *ngIf="isInFavorites; else objectNotInFavorites">
        <span class="icon icon-heart-full"></span>{{ "OBJECT_PREVIEW_MENU.ALREADY_LIKED" | translate }}
      </ng-container>
      <ng-template #objectNotInFavorites>
        <span class="icon icon-heart-outline"></span>{{ "OBJECT_PREVIEW_MENU.LIKE" | translate }}
      </ng-template>
    </li>
    <li class="object-preview-menu__item" *ngIf="objectDetails?.direction !== undefined" (click)="openObjectLocationStory()"><span class="icon icon-direction"></span>{{ "OBJECT_PREVIEW_MENU.WHERE_IS_OBJECT" | translate }}</li>
    <li *ngIf="objectDetails?.locationData" class="object-preview-menu__item" (click)="showObjectOnMap()"><span class="icon icon-pin"></span>{{ "OBJECT_PREVIEW_MENU.SHOW_ON_PLAN" | translate }}</li>
    <li class="object-preview-menu__item" (click)="openShareMenu()"><span class="icon icon-share-arrow2"></span>{{ "OBJECT_PREVIEW_MENU.SHARE" | translate }}</li>
  </ul>
</div>

