<div class="hints"
     *ngIf="hints.length > 0">

  <swiper (swiper)="onSwiper($event)" (slideChange)="setCurrentIndex($event)">
    <ng-template swiperSlide *ngFor="let hint of hints; index as idx">

      <div class="hints__content-wrapper">
        <div class="hints__content" [ngClass]="{'hints__content--first' : idx === 0, 'hints__content--last' : idx === hints.length - 1}">
          <div class="hints__close" (click)="closeHints()"><span class="icon-close"></span></div>
          <div class="hints__items">
            <div class="hints__item">
              <mak-hints-item [hint]="hint"></mak-hints-item>
            </div>
          </div>

          <div class="hints__footer">
            <div class="hints__navigation">
              <div class="hints__button hints__button--inverted hints__skip" (click)="closeHints()"><span class="label">{{"HINTS.SKIP" | translate}}</span></div>
              <div class="hints__button hints__back" (click)="prevHint()"><span class="icon icon-chevron-left"></span><span class="label">{{"HINTS.BACK" | translate}}</span></div>
              <div class="hints__button hints__next" (click)="nextHint()"><span class="label">{{"HINTS.NEXT" | translate}}</span><span class="icon icon-chevron-right"></span></div>
              <div class="hints__button hints__finish" (click)="closeHints()">{{"HINTS.FINISH" | translate}}</div>
            </div>

            <div class="hints__pagination">
              <div class="hints__pagination-item"
                   *ngFor="let item of hints; index as localIndex"
                   [ngClass]="{'hints__pagination-item--active' : idx === localIndex}"
                   (click)="goToSlide(localIndex)"></div>
            </div>
          </div>
        </div>
      </div>

    </ng-template>
  </swiper>


</div>
