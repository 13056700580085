<div class="playlist-viewer" *ngIf="playlist">

  <div class="playlist-viewer__playlist-wrapper" *ngIf="playlist.items.length > 1">
    <div class="playlist-viewer__item-highlight" #highlightNode></div>
    <div class="playlist-viewer__items-wrapper" [ngClass]="{'playlist-viewer__items-wrapper--no-transition': isNoTransitionEffects}" (pan)="onListScroll($event)" #listNode>
      <div class="playlist-viewer__items-item" *ngFor="let playlistItem of playlist.items; let i = index">
        <mak-playlist-line-item  [playlist]="playlist" [index]="i" (itemClicked)="scrollToItem($event)" (itemMenuOpened)="itemMenuOpened.emit($event)"></mak-playlist-line-item>
      </div>
    </div>
  </div>


  <div class="playlist-viewer__single-item" *ngIf="playlist.items.length === 1">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-8">
          <div class="playlist-viewer__single-item-image-wrapper">
              <mak-acf-image [image-data]="playlist.items[0].thumbnail" [force-ratio]="'force-square'"></mak-acf-image>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="playlist-viewer__single-item-title">
            {{ playlist.items[0] | translateKey:'title':(lang$ | async) }}
          </div>
        </div>
      </div>
    </div>
  </div>


</div>
