import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../store';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {getFavoriteObjects} from '../store/selectors/user.selectors';
import {TabNavigationService} from '../services/tab-navigation.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mak-social-actions',
  templateUrl: './social-actions.component.html',
  styleUrls: ['./social-actions.component.scss']
})
export class SocialActionsComponent implements OnInit {

  hasFavorites = false;

  constructor(
    private store: Store<AppState>,
    public tabNavigation: TabNavigationService
  ) { }

  ngOnInit(): void {
    this.store.select(getFavoriteObjects)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.hasFavorites = response.length > 0;
      });
  }

}
