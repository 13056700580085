<div class="notification"
     *ngIf="notification"
     [ngClass]="{'notification--visible' : isVisible, 'notification--dragging' : isDragging}" (pan)="handleDrag($event)" #notificationContainer>
  <div class="notification__close" (click)="closeNotification()"><span class="icon-close"></span></div>
  <div class="notification__image" *ngIf="notification.image">
    <img [src]="notification.image" [srcset]="notification.image"  [alt]="notification.de.linkTitle" (load)="onImageLoaded()"/>
  </div>

  <div class="notification__content">
    <div class="notification__tag" *ngIf="(notification | translateKey:'tag':($lang | async)).length > 0">
      {{ notification | translateKey:'tag':($lang | async) }}
    </div>

    <div class="notification__text">{{ notification | translateKey:'text':($lang | async) }}</div>
    <div class="notification__link" *ngIf="(notification | translateKey:'linkUrl':($lang | async)).length > 0">

      <a [href]="notification | translateKey:'linkUrl':($lang | async)" target="_blank"
         *ngIf="(notification | translateKey:'linkIsExtern':($lang | async)) === true; else internalLink;"
         (click)="onExternalLinkClicked()">
        {{ notification | translateKey:'linkTitle':($lang | async) }}
      </a>
      <ng-template #internalLink>
        <a [routerLink]="notification | translateKey:'linkUrl':($lang | async)" (click)="onInternalLinkClicked()">{{ notification | translateKey:'linkTitle':($lang | async) }}</a>
      </ng-template>
    </div>
  </div>
</div>
