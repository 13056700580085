import {createFeatureSelector, createSelector} from '@ngrx/store';
import {audioFeatureKey, State} from '../reducers/audio.reducer';

export const selectAudioFeature = createFeatureSelector<State>(
  audioFeatureKey
);

export const getActiveAudioFile = createSelector(
  selectAudioFeature,
  (state: State) => state.activeAudioFile
);

export const getPlaylist = createSelector(
  selectAudioFeature,
  (state: State) => state.playlist
);

export const getSprite = createSelector(
  selectAudioFeature,
  (state: State) => state.sprite
);

export const getActivePlaylistItem = createSelector(
  selectAudioFeature,
  (state: State) => state.activePlaylistItem
);

export const getActiveAudioId = createSelector(
  selectAudioFeature,
  (state: State) => state.activeAudioId
);

export const getPlayerState = createSelector(
  selectAudioFeature,
  (state: State) => state.playerState
);

export const getPlayerAction = createSelector(
  selectAudioFeature,
  (state: State) => state.playerAction
);

export const getAudioTranscriptVisibility = createSelector(
  selectAudioFeature,
  (state: State) => state.audioTranscriptVisible
);

export const getPlayerSpeed = createSelector(
  selectAudioFeature,
  (state: State) => state.playerSpeed
);

export const getPlayerSpeedVisibility = createSelector(
  selectAudioFeature,
  (state: State) => state.playerSpeedVisible
);

export const getPlayerMode = createSelector(
  selectAudioFeature,
  (state: State) => state.playerMode
);
