import {createAction, props} from '@ngrx/store';
import {Playlist} from '../../interfaces/playlist';

export const setActiveAudioFile = createAction(
  '[Audio] Set Active Audio File',
  props<{fileUrl: string}>()
);

export const setPlaylist = createAction(
  '[Audio] Set Playlist',
  props<{playlist: Playlist | undefined}>()
);

export const setActivePlaylistItem = createAction(
  '[Audio] Set Active Playlist Item',
  props<{index: number, timestamp: string}>()
);

export const setActiveAudioId = createAction(
  '[Audio] Set Active Audio Id',
  props<{audioId: string}>()
);

export const setPlayerState = createAction(
  '[Audio] Set Player State',
  props<{state: string}>()
);

export const setPlayerAction = createAction(
  '[Audio] Set Player Control',
  props<{action: string, timestamp: string}>()
);

export const setAudioTranscriptVisibility = createAction(
  '[Audio] Set Audio Transcript Visibility',
  props<{visible: boolean}>()
);

export const setPlayerSpeed = createAction(
  '[Audio] Set Player Speed',
  props<{speed: number}>()
);

export const setPlayerSpeedVisibility = createAction(
  '[Audio] Set Player Speed Visibility',
  props<{visible: boolean}>()
);

export const setPlayerMode = createAction(
  '[Audio] Set Player Mode',
  props<{mode: string}>()
);

