import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ShareButton} from '../../interfaces/share-button';
import {ShareService} from '../../services/share.service';

@Component({
  selector: 'mak-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss']
})
export class ShareButtonComponent implements OnInit {

  @Input('addBackground') addBackground = false;

  @Input('data') data: ShareButton = {
    text: '',
    title: ''
  };


  constructor(
    private route: Router,
    private share: ShareService
  ) { }

  ngOnInit(): void {
  }

  openShareMenu(): void {
    this.share.openShareMenu('MAK hörend erleben', this.route.url, 'Erkunden Sie das MAK mit dem neuen digitalen MAK Guide. Viele Audios, Hörstücke und hochauflösendes Bildmaterial lassen Sie faszinierende MAK-Objekte und ihre Geschichten auf eine ganz neue Art erleben.');
  }
}
