import {gql} from 'apollo-angular';
import {ObjectFragment} from './framents/ObjectFragment';
import {YoastSeoFragment} from './framents/YoastSeoFragment';
import {TourFragment} from './framents/TourFragment';

export const GET_TOUR_BY_ID = gql`
  query GetTourById($tourId: Int!) {
    contentNodes(where: {id: $tourId}) {
      nodes {
        ... on Tour {
          ...tourFields
        }
      }
    }
  }
  ${ObjectFragment},
  ${YoastSeoFragment},
  ${TourFragment}
`;
