import {Component, Input, OnInit} from '@angular/core';
import {StoryCard} from '../interfaces/story-card';
import {LanguageService} from '../services/language.service';

@Component({
  selector: 'mak-story-card',
  templateUrl: './story-card.component.html',
  styleUrls: ['./story-card.component.scss']
})
export class StoryCardComponent implements OnInit {

  lang$ = this.language.getLanguage();

  @Input('card') card!: StoryCard;

  constructor(
    private language: LanguageService
  ) { }

  ngOnInit(): void {
  }
}
