import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Howl} from 'howler';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {AppState} from '../store';
import {
  getActivePlaylistItem,
  getAudioTranscriptVisibility,
  getPlayerAction,
  getPlayerMode,
  getPlayerSpeed,
  getPlayerSpeedVisibility,
  getPlaylist
} from '../store/selectors/audio.selectors';
import {PlaylistItem} from '../interfaces/playlist-item';
import {
  setActiveAudioId,
  setActivePlaylistItem,
  setAudioTranscriptVisibility,
  setPlayerSpeedVisibility,
  setPlayerState,
  setPlaylist
} from '../store/actions/audio.actions';
import {forkJoin, Observable} from 'rxjs';
import {MakConstants} from '../shared/MakConstants';
import {addPlayedAudioId} from '../store/actions/user.actions';
import {LanguageService} from '../services/language.service';
import {Playlist} from '../interfaces/playlist';
import {Languages} from '../enums/languages';
import {take} from 'rxjs/operators';
import {SharedService} from '../services/shared.service';
import {setObjectForPullUp} from '../store/actions/object.actions';
import {ContentService} from '../services/content.service';
import {PlaylistViewerComponent} from './playlist-viewer/playlist-viewer.component';
import {PlayerActionsService} from '../services/player-actions.service';
import {getFavoriteObjects} from '../store/selectors/user.selectors';
import {FavoritesService} from '../services/favorites.service';
import {ObjectDetails} from '../interfaces/object-details';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mak-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit {

  @ViewChild('scrollablePlaylist') scrollablePlaylist!: ElementRef;
  @ViewChild('makPlaylistViewer') makPlaylistViewer!: PlaylistViewerComponent;

  localPlayerState = {
    language: '',
    playlist: '',
    index: -1,
    playerState: MakConstants.playerStates.empty
  };

  lang$ = this.language.getLanguage();

  makConstants = MakConstants;
  mode = MakConstants.playerModes.mini;

  isPlaying = false;
  progress = 0;
  seek = 0;
  spriteTimestamp = 0;
  spriteDuration = 0;
  indexInState = 0;
  playlistItems: PlaylistItem[] = [];
  playerSpeed = 1;
  playerSpeedState = false;
  showObjectActions = false;

  sprites: {audioId: string, markers: number[]}[] = new Array(0);
  src: any;
  sound: any;
  audioTranscript = '';
  transcriptPanelState = false;

  // Favorites System
  singleObjectDetails: ObjectDetails | null = null;
  isSingleObjectInFavorites = false;


  isObjectMenuOpened = false;
  relatedTourId: number | null = null;

  activeItemMarkedAsPlayed = false;

  constructor(
    private store: Store<AppState>,
    private language: LanguageService,
    private sharedService: SharedService,
    private contentService: ContentService,
    private playerActionsService: PlayerActionsService,
    private favoritesService: FavoritesService
  ) { }

  ngOnInit(): void {

    this.store.select(getPlayerMode)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.mode = response;
        this.setPlayerBodyClasses();
      });

    this.store.select(getAudioTranscriptVisibility)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.transcriptPanelState = response;
      });

    this.store.select(getPlayerSpeedVisibility)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.playerSpeedState = response;
      });

    this.store.select(getPlayerSpeed)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.playerSpeed = response;
        this.setPlayerSpeed();
      });

    /**
     * Listen to Player Actions and check if content changed
     * If content changed play it
     * if Not Changed react according to action
     */
    this.store.select(getPlayerAction)
      .pipe(untilDestroyed(this))
      .subscribe(playerAction => {
      this.getPlayerContent().pipe(take(1)).subscribe(response => {
        if (response) {
          // If Content Changed it will be played automatically. No further actions to be done.

          // set dashboard favorites state
          if (this.playlistItems.length === 1) {
            this.store.select(getFavoriteObjects).pipe(take(1)).subscribe((favoriteObjects) => {
              this.contentService.getObjectDetailsByID(this.playlistItems[0].objectDetailsId, -1).then((objectDetails) => {
                if (objectDetails !== null) {
                  this.singleObjectDetails = objectDetails;
                  this.isSingleObjectInFavorites = this.favoritesService.isInFavoritesList(objectDetails, favoriteObjects);
                }
              });
            });
          }
        } else {
          // Content did not change
          switch (playerAction.action) {
            case MakConstants.playerActions.play:
              this.resume();
              break;
            case MakConstants.playerActions.pause:
              this.pause();
              break;
            default:
              console.log('AudioPlayerComponent: Unmapped external player action: ' + playerAction.action);
          }
        }
      });
    });
  }

  /**
   * Get Player Content as an observable
   * Return true if changed; false if same content in store as currently in player
   */
  getPlayerContent(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      forkJoin([
        this.store.select(getPlaylist).pipe(take(1)),
        this.lang$.pipe(take(1)),
        this.store.select(getActivePlaylistItem).pipe(take(1))
      ])
        .pipe(untilDestroyed(this))
        .subscribe(([playlist, lang, activePlaylistItem]) => {
          if (playlist && lang && activePlaylistItem) {

            const playlistChanged = this.localPlayerState.playlist !== JSON.stringify(playlist);
            const languageChanged = this.localPlayerState.language !== JSON.stringify(lang);
            const indexChanged = this.localPlayerState.index !== activePlaylistItem.index;

            if (playlistChanged || languageChanged || indexChanged) {

              // If playlist or language changed we need to create a new sound and jump to activePlaylistItem
              if (playlistChanged || languageChanged) {
                this.localPlayerState.playlist = JSON.stringify(playlist);
                this.localPlayerState.language = JSON.stringify(lang);
                this.createSound(playlist, lang);
              }

              // If activePlaylistItem item changed we need to jump to this position
              if (indexChanged) {
                if (typeof activePlaylistItem.index !== 'undefined') {
                  this.localPlayerState.index = activePlaylistItem.index;
                  this.skipTo(activePlaylistItem.index);
                }
              }

              observer.next(true);
              observer.complete();

            } else {
              observer.next(false);
              observer.complete();
            }

          } else {
            observer.next(false);
            observer.complete();
          }

        });
    });
  }

  /**
   * Load Playlist and Create New Sound
   */
  createSound(playlist: Playlist, lang: Languages): void {

    this.src = (playlist.sprite)[lang];
    this.sprites = new Array(0);

    playlist.items.forEach((playlistItem: PlaylistItem) => {
      const start = Math.ceil(playlistItem[lang].timestamp) * 1000; // timestamp
      const end = Math.floor(playlistItem[lang].timestamp + playlistItem[lang].duration) * 1000; // timestamp + duration

      this.sprites.push({
        audioId: playlistItem.audioId,
        markers: [start, end]
      });

    });

    this.localPlayerState.index = this.indexInState = 0;

    this.playlistItems = playlist.items.map((item) => {
      console.log(item);
      return {...item};
    });

    // Destroy if sound is active
    if (this.sound) {
      this.sound.stop();
      this.sound.unload();
    }

    this.sound = new Howl({
      src: [this.src],
      html5: true,
      rate: this.playerSpeed,
      onplay: () => {
        requestAnimationFrame(this.step.bind(this));
      },
      onseek: () => {
        requestAnimationFrame(this.step.bind(this));
      },
      onend: () => {
        this.ended();
      }
    });

    this.activeItemMarkedAsPlayed = false;

    this.play();
    this.setPlayerBodyClasses();

  }


  /**
   * Play a song in the playlist.
   * index Index of the song in the playlist (leave empty to play the first or current).
   */
  play(index?: number): void {

    if (this.playlistItems.length === 0) {
      console.warn('Play: No Tracks in Playlist');
      return;
    }

    index = typeof index === 'number' ? index : this.localPlayerState.index;

    this.sound.pause();
    this.sound.seek(this.sprites[index].markers[0] / 1000);
    this.sound.play();

    this.localPlayerState.index = index;
    this.isPlaying = true;
    this.setActiveAudioInState();

    this.store.dispatch(setPlayerState({state: MakConstants.playerStates.playing}));

  }

  /**
   * Pause the currently playing track.
   */
  pause(): void {

    if (this.playlistItems.length === 0) {
      console.warn('No Tracks in Playlist');
      return;
    }

    this.sound.pause();
    this.isPlaying = false;
    this.store.dispatch(setPlayerState({state: MakConstants.playerStates.paused}));
  }

  /**
   * Resume the currently playing track.
   */
  resume(): void {

    if (this.sound) {
      this.isPlaying = true;
      this.sound.seek(this.seek);
      this.sound.play();
      this.store.dispatch(setPlayerState({state: MakConstants.playerStates.playing}));
    } else {
      this.play();
    }
  }

  /**
   * Skip to the next or previous track.
   * direction 'next' or 'prev'.
   */
  skip(direction: string): void {

    let index = 0;
    if (direction === 'prev') {
      index = this.localPlayerState.index - 1;
      if (index < 0) {
        index = this.playlistItems.length - 1;
      }
    } else {
      index = this.localPlayerState.index + 1;
      if (index >= this.playlistItems.length) {
        index = 0;
      }
    }

    this.skipTo(index);

  }

  /**
   * Skip to a specific track based on its playlist index.
   * Index in the playlist.
   */
  skipTo(index: number): void {


    // Stop the current track.
    if (this.sound) {
      this.sound.stop();
    }

    // Play the new track.
    this.play(index);

  }

  /**
   * This function run on each tick of a player
   */
  step(): void {

    this.seek = this.sound.seek();

    // Find Active Index (seek position in between markers)
    if (this.sprites.length > 1) {
      const sprites = this.sprites.filter(sprite => {
        return sprite.markers[0] < (this.seek * 1000) && (this.seek * 1000) < sprite.markers[1];
      });
      if (sprites.length) {
        this.localPlayerState.index = this.sprites.map(e => e.audioId).indexOf(sprites[0].audioId);
      }

      if (this.progress > 90 && this.progress <= 100.0 && !this.activeItemMarkedAsPlayed) {
        this.addPlayedAudioToState();
      }

      if (this.indexInState !== this.localPlayerState.index) {
        this.indexInState = this.localPlayerState.index;
        this.activeItemMarkedAsPlayed = false;
        this.setActiveAudioInState();
      }


    } else {
      if (this.progress > 90 && this.progress <= 100.0 && !this.activeItemMarkedAsPlayed) {
        this.addPlayedAudioToState();
      }
    }

    this.updateProgressBar();

    if (this.sound.playing()) {
      requestAnimationFrame(this.step.bind(this));
    }
  }

  /**
   * Draws a progress bar indicating the current play time of the sprite
   */
  updateProgressBar(): void {
    if (this.sound) {
      const sprite = this.sprites[this.localPlayerState.index];
      if (sprite && sprite.markers[1] > 0) {
        this.progress = (((this.seek * 1000 - sprite.markers[0]) / (sprite.markers[1] - sprite.markers[0]) * 100) || 0);
      } else {
        this.progress = (((this.seek / this.sound.duration()) * 100) || 0);
      }
    }
  }

  /**
   * Save current audio id in state to show pause/play else where
   */
  setActiveAudioInState(): void {
    // Get Active Card ID

    if (typeof this.playlistItems[this.localPlayerState.index] !== 'undefined') {
      this.toggleObjectActions();
      const activeAudioId = this.playlistItems[this.localPlayerState.index].audioId;
      this.audioTranscript = this.getAudioTranscript();
      const sprite = this.sprites[this.localPlayerState.index];
      this.spriteTimestamp = sprite.markers[0];
      this.spriteDuration = sprite.markers[1];
      console.log('activeAudioId', activeAudioId);
      this.store.dispatch(setActiveAudioId({audioId: activeAudioId}));
      // this.store.dispatch(addPlayedAudioId({audioId: activeAudioId}));
      this.store.dispatch(setActivePlaylistItem({index: this.localPlayerState.index, timestamp: new Date().toString()}));
    }

  }

  /**
   * Get Transcript of current item and language
   */
  getAudioTranscript(): string {

    if (typeof this.playlistItems[this.localPlayerState.index] !== 'undefined') {
      if (this.localPlayerState.language === JSON.stringify(Languages.GERMAN)) {
        // Current Audio Transcript
        return this.playlistItems[this.localPlayerState.index].de.audioTranscript ?? '';
      } else {
        return this.playlistItems[this.localPlayerState.index].en.audioTranscript ?? '';
      }
    }

    return '';

  }

  /**
   * Clean up player on end
   */
  ended(): void { // ALSO USED IN CLOSE PLAYER! (this.closePlayer())

    this.isPlaying = false;
    this.store.dispatch(setPlayerState({state: MakConstants.playerStates.empty}));

    this.playlistItems = new Array(0);
    this.localPlayerState = {
      language: '',
      playlist: '',
      index: -1,
      playerState: MakConstants.playerStates.empty
    };

    this.store.dispatch(setPlaylist({
      playlist: {
        items: new Array(0),
        sprite: {
          de: '',
          en: ''
        }
      }
    }));

    this.store.dispatch(setActiveAudioId({audioId: ''}));

    if (this.sound) {
      this.sound.stop();
      this.sound.unload();
    }

    this.setPlayerBodyClasses();

  }

  /**
   * This function sets the seek position of a track relative to the current sprite and the clicked position on the progress bar.
   */
  setSeekPosition(payload: any): void {
    const rect = payload.event.target.getBoundingClientRect();
    const x = payload.event.clientX - rect.left; // x position within the element.
    const width = payload.element?.nativeElement.getBoundingClientRect().width;
    const progress = x / width;
    this.setSeekPositionByProgress(progress);
  }

  setSeekPositionByProgress(progress: number): void {
    console.log(progress);
    if (this.sprites.length > 1) {
      const sprite = this.sprites[this.localPlayerState.index];
      const seekPosition = sprite.markers[0] + (sprite.markers[1] - sprite.markers[0]) * progress;
      this.sound.seek(seekPosition / 1000);
    } else {
      const seekPosition = this.sound.duration() * progress;
      this.sound.seek(seekPosition);
    }
  }

  minimizePlayer(): void {
    this.playerActionsService.setPlayerMode(MakConstants.playerModes.mini);
    this.sharedService.toggleBodyScroll(true);
  }

  maximizePlayer(): void {
    this.sharedService.toggleBodyScroll(false);
    this.playerActionsService.setPlayerMode(MakConstants.playerModes.full);
  }

  seekJumpDirection(direction: string): void {

    let seekPosition = this.seek * 1000;
    if (direction === 'next') {
      const sprite = this.sprites[this.localPlayerState.index];
      seekPosition = Math.min(seekPosition + 15000, sprite.markers[1]);
    } else if (direction === 'prev') {
      seekPosition = Math.max(0, seekPosition - 15000);
    }
    // this.seek = seekPosition / 1000;
    this.sound.seek(seekPosition / 1000);
  }

  /**
   * Set the playback speed according to select fields
   */
  setPlayerSpeed(): void {
    if (this.sound) {
      this.sound.rate(this.playerSpeed);
    }
  }

  closePlayerSpeed(): void {
    this.store.dispatch(setPlayerSpeedVisibility({visible: false}));
  }

  closeTranscriptionPanel(): void {
    this.store.dispatch(setAudioTranscriptVisibility({visible: false}));
  }

  toggleFavorites(): void {
    if (this.playlistItems.length === 1) { // if length !== 1 it makes no sense
      if (!this.singleObjectDetails) { // if singleObjectDetails is null, then we must load it, then toggle
        this.contentService.getObjectDetailsByID(this.playlistItems[0].objectDetailsId).then((response) => {
          if (response !== null) {
            this.singleObjectDetails = response;
            this.favoritesService.toggleFavoriteObject(this.singleObjectDetails).then(newState => {
              this.isSingleObjectInFavorites = newState;
            });

          }
        });
      } else { // else toggleImmediately
        this.favoritesService.toggleFavoriteObject(this.singleObjectDetails).then(newState => {
          this.isSingleObjectInFavorites = newState;
          console.log(this.isSingleObjectInFavorites);
        });
      }
    }

  }

  toggleObjectMenu(idx: number): void {
    console.log('toggle idx is ' + idx);
    console.log(this.playlistItems);
    this.contentService
      .getObjectDetailsByID(this.playlistItems[idx].objectDetailsId, -1)
      .then((response) => {
      if (response) {
        console.log(response);
        this.relatedTourId = (this.playlistItems[idx].parentTourId !== -1) ? this.playlistItems[idx].parentTourId : null;
        response.indexInTour = idx;
        this.store.dispatch(setObjectForPullUp({object: response}));
        this.toggleObjectMenuState(true);
      } else {
        console.log('not found: ');
        console.log(response);
      }
    });
  }

  toggleObjectMenuState($event: boolean): void {
    this.isObjectMenuOpened = $event;
  }

  closePlayer(): void {
    this.ended();
  }

  setPlayerBodyClasses(): void {
    if (this.mode === MakConstants.playerModes.mini && this.playlistItems.length > 0) {
      document.body.classList.add('player-visible--mini');
    } else {
      document.body.classList.remove('player-visible--mini');
    }
  }

  toggleObjectActions(): void {
    if (this.playlistItems[this.localPlayerState.index].objectDetailsId > -1) {
      this.showObjectActions = true;
    } else {
      this.showObjectActions = false;
    }
  }

  addPlayedAudioToState(): void {
    this.store.select(getActivePlaylistItem)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.index !== undefined) {
          this.activeItemMarkedAsPlayed = true;
          console.log('added ID ' + this.playlistItems[response.index].audioId);
          this.store.dispatch(addPlayedAudioId({audioId: this.playlistItems[response.index].audioId}));
        }
      });
  }

}
