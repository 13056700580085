<div class="main-nav">
  <a (click)="gotToTab('home')" [ngClass]="{'main-nav__item--active' : (activeTab$ | async) === navigationTabsEnum.HOME}" class="main-nav__item">
    <span class="icon icon-greif"></span>
  </a>
  <a (click)="gotToTab('audio')" [ngClass]="{'main-nav__item--active' : (activeTab$ | async) === navigationTabsEnum.AUDIO}" class="main-nav__item">
    <span class="icon icon-audio"></span>
  </a>
  <a (click)="gotToTab('code')" [ngClass]="{'main-nav__item--active' : (activeTab$ | async) === navigationTabsEnum.CODE}" class="main-nav__item">
    <span class="icon icon-code"></span>
  </a>
  <a (click)="gotToTab('locations')" [ngClass]="{'main-nav__item--active' : (activeTab$ | async) === navigationTabsEnum.LOCATION}" class="main-nav__item">
    <span class="icon icon-search"></span>
  </a>
  <div class="main-nav__item" (click)="toggleMainMenu(true)">
    <span class="icon icon-list"></span>
  </div>
</div>
<mak-pull-up-container (onStateChanged)="toggleMainMenu($event)" [state]="mainMenuState">
  <mak-main-menu (onMainMenuClosed)="toggleMainMenu(false)"></mak-main-menu>
</mak-pull-up-container>
