import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AudioResourceComponent} from './audio-resource.component';


@NgModule({
    declarations: [
        AudioResourceComponent
    ],
    exports: [
        AudioResourceComponent
    ],
    imports: [
        CommonModule
    ]
})
export class AudioResourceModule { }
