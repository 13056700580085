import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContentPreviewComponent} from './content-preview.component';
import {ProgressPieModule} from '../atoms/progress-pie/progress-pie.module';
import {RouterModule} from '@angular/router';
import {AcfImageModule} from '../atoms/acf-image/acf-image.module';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from '../pipes/pipes.module';


@NgModule({
    declarations: [
        ContentPreviewComponent,
    ],
    exports: [
        ContentPreviewComponent
    ],
    imports: [
        CommonModule,
        ProgressPieModule,
        RouterModule,
        AcfImageModule,
        TranslateModule,
        PipesModule
    ]
})
export class ContentPreviewModule { }
