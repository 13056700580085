import {Injectable} from '@angular/core';
import {OpeningHours} from '../interfaces/opening-hours';
import {OpeningHoursDay} from '../interfaces/opening-hours-day';

@Injectable({
  providedIn: 'root'
})
export class OpeningHoursService {

  // TODO this module need improvements for case if opening hours longer then midnight

  // Change order of items if need sunday first for example
  private readonly WEEKDAYS = [ 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  constructor() { }

  getState(globalOpeningHours: OpeningHours): OpeningHoursDay {
    const currentTime = new Date();
    const currentDay = this.getCurrentDaySettings(globalOpeningHours);

    if (currentDay.isOpened) {
      if (currentTime.getHours() > currentDay.to.hour) {
        currentDay.isOpened = false;
      } else if (currentTime.getHours() === currentDay.to.hour) {
        if (currentTime.getMinutes() >= currentDay.to.minute) {
          currentDay.isOpened = false;
        }
      }
    }

    return currentDay;
  }

  getCurrentDaySettings(globalOpeningHours: OpeningHours): OpeningHoursDay {
    const currentTime = new Date();
    const todaysException = globalOpeningHours.exceptions.filter((el) => el.date.day === currentTime.getDate()
      && el.date.month === currentTime.getMonth() + 1
      && el.date.year === currentTime.getFullYear());

    if (todaysException.length) {
      return {...todaysException[0].day}; // because readonly store state
    } else {
      return {...globalOpeningHours.weekdays[currentTime.getDay()]}; // because readonly store state
    }
  }

  getEmptyOpeningHours(): OpeningHours {
    return {
      weekdays: [],
      exceptions: []
    };
  }

  parseOpeningHours(rawData: any): OpeningHours {
    console.log(rawData);
    const openingHours = this.getEmptyOpeningHours();
    if (rawData.dateException && rawData.dateException.length) {
      rawData.dateException.forEach((dateException: any) => {
        const date = this.parseDate(dateException.date);
        openingHours.exceptions.push({
          date,
          day: {
            from: this.parseTime(dateException.form),
            to: this.parseTime(dateException.to),
            isOpened: dateException.isOpened
          }
        });
      });
    }

    this.WEEKDAYS.forEach((weekday) => {
      if (rawData.hasOwnProperty(weekday)) {
        openingHours.weekdays.push({
          from: this.parseTime(rawData[weekday].from),
          to: this.parseTime(rawData[weekday].to),
          isOpened: !!rawData[weekday].isOpened
        });
      }
    });

    return openingHours;
  }

  getEmptyTime(): {hour: number, minute: number} {
    return {
      hour: 0,
      minute: 0
    };
  }

  isNMinutesBeforeClose(minutes: number, globalOpeningHours: OpeningHours): boolean {
    const currentDaySettings = this.getCurrentDaySettings(globalOpeningHours);
    if (!currentDaySettings.isOpened) {
      return false;
    }

    const currentTime = new Date();

    let newMinutes = currentTime.getMinutes() + minutes;
    let newHours = currentTime.getHours();
    if (newMinutes >= 60 && newMinutes < 120) { // no reason to check more than hour
      newMinutes = newMinutes - 60;
      newHours++;
    }

    return (newMinutes === currentDaySettings.to.minute && newHours === currentDaySettings.to.hour);
  }

  /**
   * Parses time string in format HH:MM:SS and returns object
   * @param time string
   */
  private parseTime(time: string | null): {hour: number, minute: number} {
    if (time) {
      const timeArray = time.split(':');
      let hour = 0;
      let minute = 0;
      timeArray.forEach((el, idx) => {
        switch (idx) {
          case 0:
            // parse hours
            hour = parseInt(el, undefined);
            break;
          case 1:
            // parse minutes
            minute = parseInt(el, undefined);
            break;
          // need seconds? add case 2 and seconds!
        }
      });
      return {
        hour,
        minute
      };
    }
    return this.getEmptyTime();
  }

  /**
   * Parses date string in format DD/MM/YYYY and returns object
   * @param date string
   */
  private parseDate(date: string): {day: number, month: number; year: number} {
    const dateArray = date.split('/');
    let day = 0;
    let month = 0;
    let year = 0;
    dateArray.forEach((el, idx) => {
      switch (idx) {
        case 0:
          // parse hours
          day = parseInt(el, undefined);
          break;
        case 1:
          // parse minutes
          month = parseInt(el, undefined);
          break;
        case 2:
          // parse year
          year = parseInt(el, undefined);
      }
    });
    return {
      day,
      month,
      year
    };
  }
}
