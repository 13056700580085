import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PlayerHistoryService} from '../services/player-history.service';
import {LanguageService} from '../services/language.service';
import {TabNavigationService} from '../services/tab-navigation.service';
import {TourPreview} from '../interfaces/tour-preview';


enum PROGRESS_TYPE {
  NOT_STARTED,
  IN_PROGRESS,
  FINISHED
}

@Component({
  selector: 'mak-content-preview',
  templateUrl: './content-preview.component.html',
  styleUrls: ['./content-preview.component.scss']
})
export class ContentPreviewComponent implements OnInit, OnDestroy {

  lang$ = this.language.getLanguage();

  @Input('data') data!: TourPreview;

  progressState = 1; // number of listened audios in tour (can be renamed)
  progressType: PROGRESS_TYPE = PROGRESS_TYPE.NOT_STARTED;
  currentPreviewImage = 0;
  previewImagesInterval: number | null = null;
  private readonly INTERVAL_DURATION = 4000; // ms

  constructor(
    private playerHistory: PlayerHistoryService,
    private language: LanguageService,
    public tabNavigation: TabNavigationService
  ) { }

  ngOnInit(): void {
    if (this.data) {

      const tourCardsIds = this.data.audioIds;
      this.progressState = this.playerHistory.getPlayedItemsCount(tourCardsIds);
      if (this.data.previewImages.length > 1) {
        const random = Math.random() * 2000; // prevent interval at the same time for all previews
        setTimeout(() => {
          this.previewImagesInterval = window.setInterval(() => {
            if (this.currentPreviewImage === (this.data.previewImages.length - 1)) {
              this.currentPreviewImage = 0;
            } else {
              this.currentPreviewImage++;
            }
          }, this.INTERVAL_DURATION);
        }, random);

      }

      if (this.progressState >= this.data.cardIds.length) {
        this.progressType = PROGRESS_TYPE.FINISHED;
      } else if (this.progressState > 0 && this.progressType < this.data.cardIds.length) {
        this.progressType = PROGRESS_TYPE.IN_PROGRESS;
      } else {
        this.progressType = PROGRESS_TYPE.NOT_STARTED;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.previewImagesInterval !== null) {
      clearInterval(this.previewImagesInterval);
    }
  }

}
