import {Component, Input, OnInit} from '@angular/core';
import {Hint} from '../../interfaces/hint';
import {LanguageService} from '../../services/language.service';
import {AnimationItem} from 'ngx-lottie/lib/symbols';

@Component({
  selector: 'mak-hints-item',
  templateUrl: './hints-item.component.html',
  styleUrls: ['./hints-item.component.scss']
})
export class HintsItemComponent implements OnInit {

  hint!: Hint;
  $lang = this.language.getLanguage();
  animationInstance!: any;
  animationLoaded = false;

  optionsHintAnimation: any = {
    animationData: '',
    loop: true,
    autoplay: true
  };

  @Input('hint') set setHint(value: Hint) {
    this.hint = value;
    if (!this.hint.media.isImage && this.hint.media.lottieAnimationData) {
      this.optionsHintAnimation.animationData = JSON.parse(this.hint.media.lottieAnimationData);
    }
  }

  constructor(private language: LanguageService) { }

  ngOnInit(): void {
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationInstance = animationItem;
  }

  animationDOMLoaded(): void {
    this.animationLoaded = true;
  }

}
