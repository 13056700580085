import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoryCardComponent} from './story-card.component';
import {AcfImageModule} from '../atoms/acf-image/acf-image.module';
import {PipesModule} from '../pipes/pipes.module';


@NgModule({
  declarations: [
    StoryCardComponent
  ],
    imports: [
        CommonModule,
        AcfImageModule,
        PipesModule
    ],
  exports: [
    StoryCardComponent
  ]
})
export class StoryCardModule { }
