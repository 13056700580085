<a [href]="data.description" target="_blank" class="object-details-main-menu-item object-details-main-menu-item--link"
     (click)="onItemClicked.emit([data.type, data.uniqueId])"
     *ngIf="data && data.type === menuTypes.LINK; else defaultView">
  <div class="object-details-main-menu-item__tag" *ngIf="data.tag && data.tag.length">
    <span class="icon icon-check"></span>
    {{data.tag}}
  </div>
  <div class="object-details-main-menu-item__title">{{data.title}}</div>
  <div class="object-details-main-menu-item__icon">
    <span class="icon"
          [ngClass]="{ 'icon-link' : data.type === menuTypes.LINK }"></span>
  </div>
  <div class="object-details-main-menu-item__description" #titleElement>
    <div class="object-details-main-menu-item__description-text" #titleTextElement>{{data.description}}</div>
  </div>
</a>

<ng-template #defaultView>
  <div class="object-details-main-menu-item"
       [ngClass]="{'object-details-main-menu-item--audio' : data.type === menuTypes.AUDIO_PLAYING || data.type === menuTypes.AUDIO}"
       (click)="onItemClicked.emit([data.type, data.uniqueId])">
    <div class="object-details-main-menu-item__tag" *ngIf="data.tag && data.tag.length">
      <span class="icon icon-check"></span>
      {{data.tag}}
    </div>
    <div class="object-details-main-menu-item__title">{{data.title}}</div>
    <div class="object-details-main-menu-item__icon">
    <span class="icon"
          [ngClass]="{ 'icon-play-circle' : data.type === menuTypes.AUDIO,
          'icon-pause-circle' : data.type === menuTypes.AUDIO_PLAYING,
          'icon-direction' : data.type === menuTypes.DIRECTION,
          'icon-pin' : data.type === menuTypes.LOCATION,
          'icon-video-player' : data.type === menuTypes.VIDEO,
          'icon-link' : data.type === menuTypes.LINK,
          'icon-cards' : data.type === menuTypes.STORY }"></span>
    </div>
    <div class="object-details-main-menu-item__description">{{data.description}}</div>
  </div>
</ng-template>
