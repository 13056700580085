<div class="object-gallery-enlarged" [ngClass]="{'object-gallery-enlarged--active' : state}" *ngIf="enlargedItem.item !== undefined">
    <div class="object-gallery-enlarged__close" (click)="closeEnlargedImage()"><span class="icon-close"></span></div>
    <div class="object-gallery-enlarged__image" [ngStyle]="{'top': enlargedItem.sourcePosition.top + 'px', 'left': enlargedItem.sourcePosition.left + 'px'}">
      <pinch-zoom [limit-zoom]="4" #myPinch [transition-duration]="300" [double-tap]="true" [double-tap-scale]="4">
        <img
             [width]="enlargedItem.item.width"
             [height]="enlargedItem.item.height"

             [sizes]="'100vw'"
             [srcset]="srcSet"

             [alt]="enlargedItem.item.alt"
             src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
        >
      </pinch-zoom>
    </div>
</div>
