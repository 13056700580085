import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LandingComponent} from './landing.component';
import {AcfImageModule} from '../atoms/acf-image/acf-image.module';
import {PipesModule} from '../pipes/pipes.module';


@NgModule({
  declarations: [
    LandingComponent
  ],
  exports: [
    LandingComponent
  ],
  imports: [
    CommonModule,
    AcfImageModule,
    PipesModule
  ]
})
export class LandingModule { }
