import {createReducer, on} from '@ngrx/store';
import {ObjectDetails} from '../../interfaces/object-details';
import {setObjectForPullUp, setStoryObject, setTourPointer, toggleTourSwiper} from '../actions/object.actions';
import {Story} from '../../interfaces/story';


export const objectFeatureKey = 'object';

export interface State {
  objectForPullUp: ObjectDetails | null;
  tourSwipingState: boolean;
  tourPointer: {
    index: number,
    timestamp: string
  } | null;
  story: Story | null
}

export const initialState: State = {
  objectForPullUp: null,
  tourSwipingState: true,
  tourPointer: null,
  story: null
};

export const reducer = createReducer(
  initialState,
  on(setObjectForPullUp, (state, action) => {
    return {
      ...state,
      objectForPullUp: action.object
    };
  }),
  on(toggleTourSwiper, (state, action) => {
    return {
      ...state,
      tourSwipingState: action.state
    };
  }),
  on(setTourPointer, (state, action) => {
    return {
      ...state,
      tourPointer:  {
        index: action.index,
        timestamp: action.timestamp
      }
    };
  }),
  on(setStoryObject, (state, action) => {
    return {
      ...state,
      story: action.story
    };
  })
);


