import {gql} from 'apollo-angular';
import {ObjectFragment} from './framents/ObjectFragment';

export const GET_CONTENT_BY_ID = gql`
  query GetContentById($databaseId: Int!) {
    contentNodes(where: {id: $databaseId}) {
      edges {
        node {
          ... on Object {
            ...objectFields
          }
          ... on Tour {
            title
          }
          ... on Resource {
            title
            id
            acfResourceFields {
              type
            }
          }
        }
      }
    }
  }
  ${ObjectFragment}
`;
