import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'mak-progress-pie',
  templateUrl: './progress-pie.component.html',
  styleUrls: ['./progress-pie.component.scss']
})
export class ProgressPieComponent implements OnInit {

  @Input('backgroundColor') backgroundColor!: string;
  @Input('progressColor') progressColor!: string;
  @Input('progress') set setProgress(value: number) {
    if (value > 1) {
      this.progress = 1;
    } else if (value < 0) {
      this.progress = 0;
    } else {
      this.progress = value;
    }
  }
  progress = 0;

  constructor() { }

  ngOnInit(): void {
  }

  getBackground(): string {
    if (this.progress < 0.5) {
      return this.backgroundColor;
    } else {
      return this.progressColor;
    }
  }

  getTransform(): string {
    let normalizer = 0;
    if (this.progress > 0.5) {
      normalizer = 0.5;
    }
    return 'rotate(' + (((this.progress - normalizer) / 0.5) * 180) + 'deg)';

  }
}
