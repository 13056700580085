import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainNavComponent} from './main-nav.component';
import {RouterModule} from '@angular/router';
import {MainMenuModule} from '../main-menu/main-menu.module';
import {PullUpContainerModule} from '../pull-up-container/pull-up-container.module';


@NgModule({
  declarations: [
    MainNavComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MainMenuModule,
    PullUpContainerModule
  ],
  exports: [
    MainNavComponent
  ]
})
export class MainNavModule { }
