import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import * as fromUser from './reducers/user.reducer';
import * as fromAudio from './reducers/audio.reducer';
import * as fromNotification from './reducers/notification.reducer';
import * as fromMap from './reducers/map.reducer';
import * as fromObject from './reducers/object.reducer';
import * as fromGlobalOptions from './reducers/global-options.reducer';
import {localStorageSync} from 'ngrx-store-localstorage';

export interface AppState {
  [fromUser.userFeatureKey]: fromUser.State;
  [fromAudio.audioFeatureKey]: fromAudio.State;
  [fromNotification.notificationFeatureKey]: fromNotification.State;
  [fromMap.mapFeatureKey]: fromMap.State;
  [fromObject.objectFeatureKey]: fromObject.State;
  [fromGlobalOptions.globalOptionsFeatureKey]: fromGlobalOptions.State;
}

export const reducers: ActionReducerMap<AppState> = {
  [fromUser.userFeatureKey]: fromUser.reducer,
  [fromAudio.audioFeatureKey]: fromAudio.reducer,
  [fromNotification.notificationFeatureKey]: fromNotification.reducer,
  [fromMap.mapFeatureKey]: fromMap.reducer,
  [fromObject.objectFeatureKey]: fromObject.reducer,
  [fromGlobalOptions.globalOptionsFeatureKey]: fromGlobalOptions.reducer
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [fromUser.userFeatureKey],
    // keys: [],
    rehydrate: true
  })(reducer);
}

// export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];
