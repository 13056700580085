<div class="turn-device">
  <div class="turn-device__lottie-container">
    <ng-lottie [options]="optionsHintAnimation" (animationCreated)="animationCreated($event)" (domLoaded)="animationDOMLoaded()"></ng-lottie>
  </div>


  <div class="turn-device__text">
    {{ "TURN_DEVICE" | translate }}
  </div>


</div>

