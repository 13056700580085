import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ObjectGalleryEnlargedItem} from '../interfaces/object-gallery-enlarged-item';

@Injectable({
  providedIn: 'root'
})
export class ObjectGalleryService {

  private enlargedItem = new Subject<ObjectGalleryEnlargedItem | null>();

  constructor() { }

  getEnlargedItemUpdates(): Observable<ObjectGalleryEnlargedItem | null> {
    return new Observable<ObjectGalleryEnlargedItem | null>((observer) => {
      this.enlargedItem.subscribe((response) => {
        observer.next(response);
      });
    });
  }

  openImage(image: ObjectGalleryEnlargedItem): void {
    this.enlargedItem.next(image);
  }

  closeImage(): void {
    this.enlargedItem.next(null);
  }
}
