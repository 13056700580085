<div class="pull-up-container"
     [ngClass]="{'pull-up-container--opened' : state, 'pull-up-container--scrollable' : isScrollable, 'pull-up-container--additional-padding-top' : additionalPaddingTop}">
  <div class="pull-up-container__close-background" (click)="toggleMenu(false)"></div>
  <div class="pull-up-container__content-wrapper" [ngClass]="{'pull-up-container__content-wrapper--dragging': isDragging}" (panstart)="handleDrag($event, true)" (pan)="handleDrag($event)" #pullupWrapper>
    <div class="pull-up-container__close" (click)="toggleMenu(false)" *ngIf="isScrollable"><span class="icon icon-close"></span></div>
    <div class="pull-up-container__top-line" *ngIf="!isScrollable"></div>

    <div class="pull-up-container__content">
      <ng-content></ng-content>
    </div>
  </div>
</div>

