import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  readonly SCROLL_TOP_OFFSET = 50; // px

  private scrollUpdate = new Subject<number>();
  private scrollPosition = new Subject<number>();

  constructor() { }

  // On

  getScrollUpdate(): Subject<number> {
    return this.scrollUpdate;
  }

  setScrollPosition(): Subject<number> {
    return this.scrollPosition;
  }

  updateScrollPosition(newValue: number): void {
    this.scrollUpdate.next(newValue);
  }

  setScrollTop(): void {
    console.log('scroll top');
    this.scrollPosition.next(0);
  }
}
