<div class="hints-item">
  <div class="hints-item__media">
    <div class="hints-item__media-image" *ngIf="hint.media.isImage;">
      <mak-acf-image [image-data]="hint.media.image" [disable-lazy-loading]="true"></mak-acf-image>
    </div>
    <div class="hints-item__lottie-wrapper" *ngIf="!hint.media.isImage;">
      <ng-lottie [options]="optionsHintAnimation" (animationCreated)="animationCreated($event)" (domLoaded)="animationDOMLoaded()"></ng-lottie>
    </div>
  </div>
  <div class="hints-item__title" [innerHTML]="hint | translateKey:'title':($lang | async)"></div>
  <div class="hints-item__text" [innerHTML]="hint | translateKey:'text':($lang | async)"></div>
</div>
