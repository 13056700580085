import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../store';
import {Observable} from 'rxjs';
import {getLanguage} from '../store/selectors/user.selectors';
import {Languages} from '../enums/languages';
import {TranslateService} from '@ngx-translate/core';
import {setLanguage} from '../store/actions/user.actions';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  currentLanguage: Languages = Languages.GERMAN;

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService
  ) {

    this.store.select(getLanguage).subscribe((response) => {
      this.currentLanguage = response;
      this.translate.use(response);
      this.setBodyClasses(response);
    });
  }

  getLanguage(): Observable<Languages> {
    return this.store.select(getLanguage);
  }

  getTranslatedKey(object: any, key: any, lang: any): string {
    if (typeof object[lang] !== 'undefined' && typeof object[lang][key] !== 'undefined') {
      return object[lang][key];
    } else {
      return 'Could not find ' + key + ' in object for lang ' + lang;
    }
  }

  setLanguage(language: Languages): void {
    this.store.dispatch(setLanguage({language}));
  }

  setBodyClasses(language: Languages): void {
    const htmlElement = document.getElementsByTagName('html')[0];
    if (language === Languages.ENGLISH) {
      htmlElement.setAttribute('lang', language);
      document.body.classList.add('current-lang-en');
      document.body.classList.remove('current-lang-de');
    } else {
      htmlElement.setAttribute('lang', language);
      document.body.classList.add('current-lang-de');
      document.body.classList.remove('current-lang-en');
    }
  }

}
