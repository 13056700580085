import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Notification} from '../interfaces/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  animationDuration = 300; // ms;
  private notificationsStream = new Subject<Notification>();
  constructor() {}

  get notificationsUpdater(): Subject<Notification> {
    return this.notificationsStream;
  }

  pushNotification(notification: Notification): void {
    this.notificationsUpdater.next(notification);
  }
}
