<div class="object-details-dashboard" [ngClass]="{'object-details-dashboard--not-top' : !isOnScrollTop}" *ngIf="true">
  <div class="object-details-dashboard__left">
    <div class="object-details-dashboard__back-button" (click)="goBack()"><span class="icon icon-chevron-left"></span></div>
  </div>

  <div class="object-details-dashboard__right" *ngIf="objectDetails !== null">
    <div class="object-details-dashboard__favorites" (click)="toggleFavorites()">
      <span class="icon" [ngClass]="isInFavorites ? 'icon-heart-full' : 'icon-heart-outline'"></span>
    </div>

    <div class="object-details-dashboard__share-button">
      <mak-share-button [data]="{title: 'asd', text: 'das'}" [addBackground]="!isOnScrollTop"></mak-share-button>
    </div>
  </div>
</div>
