import {createReducer, on} from '@ngrx/store';
import {addNotification, hideSingleHint, setSingleHintContent, showSingleHint,} from '../actions/notification.actions';
import {Notification} from '../../interfaces/notification';
import {Hint} from '../../interfaces/hint';


export const notificationFeatureKey = 'notification';

export interface State {
  notification: Notification | null;
  singleHintContent: Hint | undefined;
  singleHintIsVisible: boolean;
}

export const initialState: State = {
  notification: null,
  singleHintContent: undefined,
  singleHintIsVisible: false
};

export const reducer = createReducer(
  initialState,
  on(addNotification, (state, action) => {
    return {
      ...state,
      notification: action.notification
    };
  }),
  on(setSingleHintContent, (state, action) => {
    return {
      ...state,
      singleHintContent: action.hint
    };
  }),
  on(showSingleHint, (state, action) => {
    return {
      ...state,
      singleHintIsVisible: true
    };
  }),
  on(hideSingleHint, (state, action) => {
    return {
      ...state,
      singleHintIsVisible: false
    };
  }),
);


