import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../services/data.service';
import {ObjectDetails} from '../interfaces/object-details';
import {ContentService} from '../services/content.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {AppState} from '../store';
import {ContentBase} from '../interfaces/content-base';
import {Story} from '../interfaces/story';
import {SharedService} from '../services/shared.service';
import {PlayerHistoryService} from '../services/player-history.service';
import {LanguageService} from '../services/language.service';
import {PlayerActionsService} from '../services/player-actions.service';
import {ObjectDetailsMainMenuItemType} from '../enums/object-details-main-menu-item-type';
import {TabNavigationService} from '../services/tab-navigation.service';
import {TranslateService} from '@ngx-translate/core';
import {setObjectInterest, setPoiOfInterest} from '../store/actions/map.actions';
import {StoryService} from '../services/story.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mak-object-details',
  templateUrl: './object-details.component.html',
  styleUrls: ['./object-details.component.scss']
})
export class ObjectDetailsComponent implements OnInit, OnDestroy {

  lang$ = this.language.getLanguage();

  postData: any;
  content!: ObjectDetails;
  alreadyPlayed = false;

  constructor(
    private data: DataService,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private sharedService: SharedService,
    private playerHistory: PlayerHistoryService,
    private language: LanguageService,
    private playerActions: PlayerActionsService,
    private tabNavigation: TabNavigationService,
    private translateService: TranslateService,
    private router: Router,
    private storyService: StoryService
  ) {
  }

  ngOnInit(): void {

    this.tabNavigation.addRouteToState();

    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        const contentBase: ContentBase = response.apiData.contentBase;
        this.content = contentBase.fields;
      });

    this.alreadyPlayed = this.playerHistory.getItemPlayed(this.content.audioId);

  }

  ngOnDestroy(): void {
  }

  playAudio(idx: number, isResourceAudio: boolean): void {
    if (isResourceAudio) {
      if (this.content.resources.audios[idx]) {
        this.playerActions.toggleAudio(this.content.resources.audios[idx], idx);
      }
    } else {
      if (this.content.playlist) {
        this.playerActions.toggleAudio(this.content.playlist, idx);
      }
    }
  }

  openStory(story: Story): void {
    this.storyService.openStory(story);
  }

  /**
   * (!) HAS COPY IN: 'tour-object-details.component.ts'
   * Listener of child component (Menu)
   * @param $event [ObjectDetailsMainMenuItemType, number] clicked type and its index in content (assuming that data is grouped in array)
   */
  menuItemClicked($event: [ObjectDetailsMainMenuItemType, number]): void {
    const type = $event[0];
    const id = $event[1];
    if (type === ObjectDetailsMainMenuItemType.STORY) {
      this.openStory(this.content.resources.stories[id]);
    } else if (type === ObjectDetailsMainMenuItemType.AUDIO || type === ObjectDetailsMainMenuItemType.AUDIO_PLAYING) {
      // TODO reactivity to change input of menu
      if (id === 0) {
        if (this.content.playlist?.sprite.de) {
          this.playAudio(id, false);
        } else {
          this.playAudio(id, true);
        }
      } else {
        if (this.content.playlist?.sprite.de) {
          this.playAudio(id - 1, true);
        } else {
          this.playAudio(id, true);
        }
      }
    } else if (type === ObjectDetailsMainMenuItemType.DIRECTION) {
      this.openDirections(this.content.direction);
    } else if (type === ObjectDetailsMainMenuItemType.LOCATION) {
      this.store.dispatch(setObjectInterest({objectDetails: this.content}));
      this.store.dispatch(setPoiOfInterest({poi: undefined}));
      this.router.navigateByUrl('/locations');
    }
  }

  private openDirections(direction: Story | undefined): void {
    if (direction) {
      this.openStory(direction);
    }
  }
}
