import {gql} from 'apollo-angular';
import {ImageFragment} from './framents/ImageFragment';

export const GET_GLOBAL_OPTIONS = gql`
  query GetGlobalOptions {
    globalOptions {
      acfGlobalOptions {
        anniversaryPromotionActive
        introTourCards {
          animationData
          bodyTextDe
          bodyTextEn
          titleDe
          titleEn
          fieldGroupName
          isAnimation
          illustration {
            ... imageFields
          }
        }
        firstTourHint {
          firstTourHintIsAnimation
          firstTourHintAnimation
          firstTourHintIllustration {
            ... imageFields
          }
          firstTourHintTitleDe
          firstTourHintTextDe
          firstTourHintTitleEn
          firstTourHintTextEn
        }
        closeHint {
          closeHintIsAnimation
          closeHintAnimation
          closeHintIllustration {
            ... imageFields
          }
          closeHintTitleDe
          closeHintTextDe
          closeHintTitleEn
          closeHintTextEn
        }
        openingHours {
          monday {
            isOpened
            from
            to
          }
          tuesday {
            isOpened
            from
            to
          }
          wednesday {
            isOpened
            from
            to
          }
          thursday {
            isOpened
            from
            to
          }
          friday {
            isOpened
            from
            to
          }
          saturday {
            isOpened
            from
            to
          }
          sunday {
            isOpened
            from
            to
          }
          dateException {
            date
            isOpened
            from
            to
          }
        }
      }
    }
  }
  ${ImageFragment}`;
