import {Injectable} from '@angular/core';
import {Poi} from '../interfaces/poi';
import {MakConstants} from '../shared/MakConstants';
import {ImageService} from './image.service';

@Injectable({
  providedIn: 'root'
})
export class PoiMapperService {

  constructor(
    private image: ImageService
  ) { }

  mapPoiData(rawData: any): Poi {
    const mappedPoi: Poi = {
      id: rawData?.databaseId ?? 0,
      image: this.image.parseAcfImage(rawData?.acfPoi.image),
      de: {
        title: rawData?.title ?? '',
      },
      en: {
        title: rawData?.acfPoi?.titleEn ? rawData?.acfPoi?.titleEn : rawData?.title ?? '',
      },
      icon: rawData?.acfPoi?.icon ?? false,
      type: rawData?.acfPoi?.type ?? '',
      locationDataArea: false,
      locationDataPoint: false,
      link: false
    };

    if (rawData?.acfPoi?.link) {
      mappedPoi.link = {
        url: rawData?.acfPoi?.link.url ?? '',
        title: rawData?.acfPoi?.link.title ?? '',
      };
    }

    if (mappedPoi.type === MakConstants.poiTypes.area) {

      try {
        if (rawData?.acfPoi?.locationData) {
          const parsedLocationData = JSON.parse(rawData?.acfPoi?.locationData);

          mappedPoi.locationDataArea = {
            alt: parsedLocationData.alt,
            southWest: {
              lat: parsedLocationData._southWest.lat,
              lng: parsedLocationData._southWest.lng,
            },
            northEast: {
              lat: parsedLocationData._northEast.lat,
              lng: parsedLocationData._northEast.lng,
            }
          };

        }

      } catch (e) {
        console.error('Could not parse Location JSON for Area');
      }

    } else if (mappedPoi.type === MakConstants.poiTypes.point) {
      try {
        if (rawData?.acfPoi?.locationData) {
          mappedPoi.locationDataPoint = JSON.parse(rawData?.acfPoi?.locationData);
        }

      } catch (e) {
        console.error('Could not parse Location JSON');
      }
    }

    return mappedPoi;
  }

}
