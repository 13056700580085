import {createReducer, on} from '@ngrx/store';
import {GlobalOptions} from '../../interfaces/global-options';
import {setGlobalOptions} from '../actions/global-options.actions';


export const globalOptionsFeatureKey = 'globalOptions';

export interface State {
  globalOptions: GlobalOptions | null;
}

export const initialState: State = {
  globalOptions: null,
};

export const reducer = createReducer(
  initialState,
  on(setGlobalOptions, (state, action) => {
    return {
      ...state,
      globalOptions: action.options
    };
  })
);


