import {Injectable} from '@angular/core';
import {Story} from '../interfaces/story';
import {StoryCard} from '../interfaces/story-card';
import {ImageService} from './image.service';
import {Store} from '@ngrx/store';
import {AppState} from '../store';
import {setStoryObject} from '../store/actions/object.actions';
import {SharedService} from './shared.service';
import {Observable} from 'rxjs';
import {getStoryObject} from '../store/selectors/object.selectors';

@Injectable({
  providedIn: 'root'
})
export class StoryService {

  constructor(private imageService: ImageService,
              private store: Store<AppState>,
              private sharedService: SharedService) { }

  getUpdates(): Observable<Story | null> {
    return this.store.select(getStoryObject);
  }

  openStory(story: Story): void {
    this.sharedService.toggleBodyScroll(false);
    this.store.dispatch(setStoryObject({story}));
  }

  closeStory(): void {
    this.sharedService.toggleBodyScroll(false);
    this.store.dispatch(setStoryObject({story: null}));
  }

  parseStoryResource(storyResource: any): Story { // need todo?
    const newStory = this.parseStory(storyResource.acfResourceFields?.story); // exactly story fields
    newStory.de.title = storyResource.title ?? ''; // whole resource field
    newStory.en.title = storyResource.acfResourceFields.titleEn ?
      storyResource.acfResourceFields.titleEn :
      newStory.de.title; // whole resource field
    return newStory;
  }

  parseStory(story: any): Story {
    console.log(story);
    const newStory: Story = {
      de: {
        title: ''
      },
      en: {
        title: ''
      },
      cards: [],
      theme: story.theme
    };
    if (story.cards) {
      story.cards.forEach((el: any) => {
        newStory.cards.push(this.parseStoryCard(el));
      });
    }
    return newStory;
  }
  parseStoryCard(card: any): StoryCard {
    const newCard: StoryCard = {
      contentBlock: []
    };
    if (card.content) {
      card.content.forEach((el: any) => {
        if (el.hasOwnProperty('image')) {
          newCard.contentBlock.push({image: this.imageService.parseAcfImage(el.image)});
        } else if (el.hasOwnProperty('textDe')) {
          newCard.contentBlock.push({
            de: {
              text: el.textDe
            },
            en: {
              text: el.textEn
            }
          });
        }
      });
    }
    return newCard;
  }
}
