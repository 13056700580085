<div class="playlist-line-item" [ngClass]="{'playlist-line-item--highlighted' : isCurrentItem}" *ngIf="playlistItem && (playlistItem | translateKey:'duration':(lang$ | async)) > 0" (click)="setActiveItem()">

  <div class="playlist-line-item__number">
    {{ index + 1 }}
  </div>
  <div class="playlist-line-item__equalizer">
    <mak-equalizer [state]="isPlaying"></mak-equalizer>
  </div>
  <div class="playlist-line-item__thumbnail-wrapper">
    <div class="playlist-line-item__thumbnail">
      <mak-acf-image [image-data]="playlistItem.thumbnail"></mak-acf-image>
    </div>
  </div>
  <div class="playlist-line-item__title-wrapper">
    <div class="playlist-line-item__title">
      {{ playlistItem | translateKey:'title':(lang$ | async) }}
    </div>
    <div class="playlist-line-item__duration">
      {{ (playlistItem | translateKey:'duration':(lang$ | async)) * 1000 | timePreview }} Min.
    </div>
  </div>
  <div class="playlist-line-item__actions-trigger" (click)="openObjectMenu($event, index)">
    <span class="icon-more"></span>
  </div>

</div>
