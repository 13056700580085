<ng-container *ngIf="data.isExternalTour">
  <ng-container *ngIf="data !== undefined && data.externalTourLink.url.length > 0">
    <a [href]="data.externalTourLink.url" target="_blank" class="content-preview" *ngIf="data !== undefined">
      <ng-template #noValue>
        <div class="content-preview"></div>
      </ng-template>
      <div class="content-preview__images">
        <div class="content-preview__image" [ngClass]="{'content-preview__image--active': idx === currentPreviewImage}" *ngFor="let image of data.previewImages; index as idx">
          <mak-acf-image [image-data]="image" [force-ratio]="'fourBySeven'" [disable-lazy-loading]="true"></mak-acf-image>
        </div>
      </div>
      <div class="content-preview__title" [innerHTML]="data | translateKey:'title':(lang$ | async)"></div>
      <div class="content-preview__meta">
        <span class="content-preview__objects">{{ data.externalTourLink.title }}</span>
      </div>
    </a>
  </ng-container>
  <ng-container *ngIf="data !== undefined && data.externalTourLink.url.length === 0">
    <div class="content-preview" *ngIf="data !== undefined">
      <ng-template #noValue>
        <div class="content-preview"></div>
      </ng-template>
      <div class="content-preview__images">
        <div class="content-preview__image" [ngClass]="{'content-preview__image--active': idx === currentPreviewImage}" *ngFor="let image of data.previewImages; index as idx">
          <mak-acf-image [image-data]="image" [force-ratio]="'fourBySeven'" [disable-lazy-loading]="true"></mak-acf-image>
        </div>
      </div>
      <div class="content-preview__title" [innerHTML]="data | translateKey:'title':(lang$ | async)"></div>
      <div class="content-preview__meta">
        <span class="content-preview__objects">{{ data.externalTourLink.title }}</span>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="!data.isExternalTour">
  <a (click)="tabNavigation.gotDeeperInTab('/tour/' + data.id)" class="content-preview" *ngIf="data !== undefined">
    <div class="content-preview__images">
      <div class="content-preview__image" [ngClass]="{'content-preview__image--active': idx === currentPreviewImage}" *ngFor="let image of data.previewImages; index as idx">
          <mak-acf-image [image-data]="image" [force-ratio]="'fourBySeven'" [disable-lazy-loading]="true"></mak-acf-image>
      </div>
    </div>

    <div class="content-preview__title" [innerHTML]="data | translateKey:'title':(lang$ | async)"></div>
    <div class="content-preview__meta">
      <span class="content-preview__objects">{{data.cardIds.length}} {{ "TOUR_PREVIEW.OBJECTS" | translate }},</span>
      <span class="content-preview__duration"> {{(data | translateKey:'totalDuration':(lang$ | async)) * 1000 | timePreview }} {{ "GENERAL.MIN" | translate }}</span>
    </div>
    <div class="content-preview__tag" [ngClass]="{'content-preview__tag--in-progress': progressType === 1, 'content-preview__tag--finished': progressType === 2}" *ngIf="progressState > 0">
      <ng-container *ngIf="progressType === 1; else typeFinished">
        <div class="content-preview__tag-progress-bar">
          <mak-progress-pie [progress]="progressState / data.cardIds.length" [backgroundColor]="'#444444'" [progressColor]="'#ffffff'"></mak-progress-pie>
        </div>
        <span class="content-preview__tag-text" >{{ "TOUR_PREVIEW.PLAYED_OF" | translate: {playedNumber: progressState, totalNumber: data.cardIds.length} }}</span>
      </ng-container>

      <ng-template #typeFinished>
        <span class="icon icon-check"></span>
        <span class="content-preview__tag-text">{{ "TOUR_PREVIEW.ALL_PLAYED" | translate }}</span>
      </ng-template>

    </div>
  </a>
</ng-container>
