import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {StoreModule} from '@ngrx/store';
import {metaReducers, reducers} from './store';
import {buildSpecificModules} from './build-specifics';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpLoaderFactory} from './shared/shared.module';
import {ObjectDetailsModule} from './object-details/object-details.module';
import {AudioPlayerModule} from './audio-player/audio-player.module';
import {GraphQLModule} from './graphql.module';
import {MainNavModule} from './main-nav/main-nav.module';
import {NotificationsModule} from './notifications/notifications.module';
import {SocialActionsModule} from './social-actions/social-actions.module';
import {ObjectGalleryEnlargedModule} from './object-gallery-enlarged/object-gallery-enlarged.module';
import {HintsModule} from './hints/hints.module';
import {LottieCacheModule, LottieModule} from 'ngx-lottie';
import {StoryModule} from './story/story.module';
import {LandingModule} from './landing/landing.module';
import {TurnDeviceModule} from './turn-device/turn-device.module';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory(): any {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    ...buildSpecificModules,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ObjectDetailsModule,
    AudioPlayerModule,
    GraphQLModule,
    MainNavModule,
    NotificationsModule,
    SocialActionsModule,
    ObjectGalleryEnlargedModule,
    HintsModule,
    LottieModule.forRoot({
      player: playerFactory
    }),
    LottieCacheModule.forRoot(),
    StoryModule,
    LandingModule,
    TurnDeviceModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
