import {createAction, props} from '@ngrx/store';
import {ObjectDetails} from '../../interfaces/object-details';
import {Languages} from '../../enums/languages';
import {NavigationTabs} from '../../enums/navigation-tabs';
import {RouterAnimationDirections} from '../../enums/router-animation-directions';

export const setFirstTourHintWatched = createAction(
  '[User] Set First Tour Hint Watched',
  props<{state: boolean}>()
);

export const setIntroWatched = createAction(
  '[User] Intro Watched',
  props<{watched: boolean}>()
);

export const addPlayedAudioId = createAction(
  '[User] Add Played Audio ID',
  props<{audioId: string}>()
);

export const addObjectToFavorites = createAction(
  '[User] Add Object To Favorites',
  props<{object: ObjectDetails}>()
);

export const toggleFavoriteObject = createAction(
  '[User] Toggle Favorite Object',
  props<{object: ObjectDetails}>()
);

export const setLanguage = createAction(
  '[User] Set language',
  props<{language: Languages}>()
);

export const setActiveTab = createAction(
  '[User] Set active Tab',
  props<{tab: NavigationTabs}>()
);

export const addVisitedUrl = createAction(
  '[User] Add Visited URL',
  props<{url: string, tab: NavigationTabs}>()
);

export const popVisitedUrl = createAction(
  '[User] Pop Visited URL',
  props<{tab: NavigationTabs}>()
);

export const clearVisitedUrls = createAction(
  '[User] Clear Visited URLs',
  props<{tab: NavigationTabs}>()
);

export const setRouterAnimationDirection = createAction(
  '[User] Set Router Animation Direction',
  props<{direction: RouterAnimationDirections}>()
);

export const setMenuVisibility = createAction(
  '[User] Set Menu Visibility',
  props<{visible: boolean}>()
);

export const increaseCodeCounter = createAction(
  '[User] Increase Code Counter',
  props<{timestamp: string}>()
);

export const setPreviousUrl = createAction(
  '[User] Set previous Url',
  props<{url: string}>()
);

export const addClosedAPINotificationIDs = createAction(
  '[User] Add Closed API Notification ID',
  props<{ids: number[]}>()
);

