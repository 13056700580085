import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  getHeaders() {
    return {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    };
  }

  getPostById(postId: string) {
    return this.http.get(environment.apiBaseUrl + 'wp-json/wp/v2/posts/?id=' + postId);
  }

  getObjectById(postId: string) {
    return this.http.get(environment.apiBaseUrl + 'wp-json/wp/v2/mak_object/' + postId);
  }

  getPageById(postId: string) {
    return this.http.get(environment.apiBaseUrl + 'wp-json/wp/v2/pages/' + postId);
  }

  getPageBySlug(slug: string) {
    return this.http.get(environment.apiBaseUrl + 'wp-json/wp/v2/pages/?slug=' + slug);
  }

  getMenuByLocation(location: string) {
    return this.http.get(environment.apiBaseUrl + 'wp-json/menus/v1/locations/' + location);
  }

  getMenuBySlug(slug: string) {
    return this.http.get(environment.apiBaseUrl + 'wp-json/menus/v1/menus/' + slug);
  }

  getCptPosts(postName: string) {
    return this.http.get(environment.apiBaseUrl + 'wp-json/wp/v2/' + postName + '/?per_page=100');
  }

}
