import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AcfImage} from '../interfaces/acf-image';
import {ObjectGalleryService} from '../services/object-gallery.service';
import {ObjectGalleryEnlargedItem} from '../interfaces/object-gallery-enlarged-item';
import {UntilDestroy} from '@ngneat/until-destroy';
import Swiper, {SwiperOptions} from 'swiper';
import {AppState} from '../store';
import {Store} from '@ngrx/store';
import {toggleTourSwiper} from '../store/actions/object.actions';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mak-object-gallery',
  templateUrl: './object-gallery.component.html',
  styleUrls: ['./object-gallery.component.scss']
})
export class ObjectGalleryComponent implements OnInit {

  @Input('items') set setItems(value: AcfImage[] | undefined) {
    if (value) {
      this.items = value;
    }
  }

  slider!: Swiper;
  items: AcfImage[] = [];
  currentSlideNumber = 0;
  sliderOptions: SwiperOptions = {
    preventClicksPropagation: true
  };

  constructor(
    private objectGalleryService: ObjectGalleryService,
    private cdRef: ChangeDetectorRef,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
  }

  enlargeImage(event: MouseEvent, idx: number): void {
    const enlargedItem: ObjectGalleryEnlargedItem = {
      item: this.items[idx],
      sourcePosition: {top: event.clientY, left: event.clientX}
    };
    this.objectGalleryService.openImage(enlargedItem);

  }

  setSliderIndex(idx: number): void {
    if (this.slider) {
      console.log('setSliderIndex ' + idx);
      this.currentSlideNumber = idx;
    }
  }

  goToSlide(idx: number): void {
    if (this.slider) {
      this.slider.slideTo(idx);
    }
  }

  sliderCreated(slider: Swiper): void {
    this.slider = slider;
    this.slider.on('slideChange', ($event) => {
      this.setSliderIndex(slider.activeIndex);
      this.cdRef.detectChanges();
    });

    this.slider.on('touchStart', ($event: Swiper) => {
      this.store.dispatch(toggleTourSwiper({state: false}));
    });

    this.slider.on('touchEnd', ($event: Swiper) => {
      this.store.dispatch(toggleTourSwiper({state: true}));
    });
  }
}
