import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HintsComponent} from './hints.component';
import {HintsItemComponent} from './hints-item/hints-item.component';
import {AcfImageModule} from '../atoms/acf-image/acf-image.module';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from '../pipes/pipes.module';
import {SwiperModule} from 'swiper/angular';
import {SingleHintComponent} from './single-hint/single-hint.component';
import {LottieModule} from 'ngx-lottie';

@NgModule({
  declarations: [
    HintsComponent,
    HintsItemComponent,
    SingleHintComponent
  ],
    exports: [
        HintsComponent,
        SingleHintComponent
    ],
    imports: [
        CommonModule,
        AcfImageModule,
        TranslateModule,
        PipesModule,
        SwiperModule,
        LottieModule
    ]
})
export class HintsModule { }
