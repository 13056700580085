import {animate, group, query, style, transition, trigger} from '@angular/animations';

const easing = '250ms cubic-bezier(0.785, 0.135, 0.150, 0.860)';

export const routerAnimations =

  trigger('routeAnimations', [
    transition('* => RIGHT', [
      query(':enter, :leave', style({
        position: 'absolute',
        minHeight: '100%',
        width: '100%',
        zIndex: 5,
        backgroundColor: '#FFFFFF'
      }), {optional: true}),
      query(':enter', style({
        transform: 'translateX(100%)',
        position: 'fixed',
        zIndex: 10
      }), {optional: true}),
      group([
        query(':enter', animate(easing, style({
          transform: 'translateX(0)',
        })), {optional: true}),
        query(':leave', animate(easing, style({
          transform: 'translateX(-20%)',
        })), {optional: true}),
        query(':leave', animate(easing, style({
          filter: 'brightness(50%)'
        })), {optional: true}),
      ])
    ]),
    transition('* => LEFT', [
      query(':enter, :leave', style({
        position: 'absolute',
        minHeight: '100%',
        width: '100%',
        zIndex: 10,
        backgroundColor: '#FFFFFF'
      }), {optional: true}),
      query(':enter', style({
        transform: 'translateX(-20%)',
        filter: 'brightness(50%)',
        position: 'fixed',
        zIndex: 5
      }), {optional: true}),
      group([
        query(':enter', animate(easing, style({
          transform: 'translateX(0)',
        })), {optional: true}),
        query(':leave', animate(easing, style({
          transform: 'translateX(100%)',
        })), {optional: true}),
        query(':enter', animate(easing, style({
          filter: 'brightness(100%)'
        })), {optional: true}),
      ])
    ])
  ]);


// trigger('routeAnimations', [
//   transition('* <=> *', [
//     query(':enter, :leave', style({
//       transform: 'translateY(0)'
//     }), {optional: true}),
//     query(':enter', style({
//       transform: 'translateY(100%)'
//     }), {optional: true}),
//     group([
//       query(':enter', animate('1200ms ease-in-out', style({
//          transform: 'translateY(0)'
//       })), {optional: true}),
//       query(':leave', animate('1200ms ease-in-out', style({
//          transform: 'translateY(-100%)'
//       })), {optional: true}),
//     ])
//   ])
// ]);
