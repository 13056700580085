import {gql} from 'apollo-angular';
import {ObjectPreviewFragment} from './framents/ObjectPreviewFragment';

export const GET_CONTENT_WITH_LOCATION = gql`
  query GetContentWithLocation {
    contentNodes(where: {location: true}, first: 1000) {
      edges {
        node {
          ... on Object {
            ...objectPreviewFields
          }
        }
      }
    }
  }
  ${ObjectPreviewFragment}
`;
