import {ChangeDetectorRef, Injector, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {LanguageService} from '../services/language.service';
import {AsyncPipe} from '@angular/common';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Languages} from '../enums/languages';

@UntilDestroy({ checkProperties: true })
@Pipe({
  name: 'translateKey'
})
export class TranslateKeyPipe implements PipeTransform, OnDestroy {

  private asyncPipe!: AsyncPipe;

  constructor(
    private injector: Injector,
    private language: LanguageService
  ) {
    this.asyncPipe = new AsyncPipe(injector.get(ChangeDetectorRef));
  }

  ngOnDestroy(): void {
    this.asyncPipe.ngOnDestroy();
  }

  transform(value: any, key: string, lang: Languages | null = Languages.GERMAN): any {
    if (!value || !key || !lang) {
      return undefined;
    }
    return this.language.getTranslatedKey(value, key, lang);
  }

}
