import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoryComponent} from './story.component';
import {StoryCardModule} from '../story-card/story-card.module';
import {PipesModule} from '../pipes/pipes.module';


@NgModule({
    declarations: [
        StoryComponent
    ],
    exports: [
        StoryComponent
    ],
  imports: [
    CommonModule,
    StoryCardModule,
    PipesModule
  ]
})
export class StoryModule { }
