import { Component, OnInit } from '@angular/core';
import {AnimationItem} from 'ngx-lottie/lib/symbols';
const animationDataCollectionEmpty = require('./../../assets/lottie/collection/collectionEmptyAnim.js');

@Component({
  selector: 'mak-turn-device',
  templateUrl: './turn-device.component.html',
  styleUrls: ['./turn-device.component.scss']
})
export class TurnDeviceComponent implements OnInit {

  animationInstance!: any;
  animationLoaded = false;

  optionsHintAnimation: any = {
    animationData: animationDataCollectionEmpty,
    loop: true,
    autoplay: true
  };

  constructor() {

  }

  ngOnInit(): void {
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationInstance = animationItem;
  }

  animationDOMLoaded(): void {
    this.animationLoaded = true;
  }

}
