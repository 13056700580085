import {gql} from 'apollo-angular';

export const UPDATE_LIKE_COUNT = gql`
  mutation UpdateLikeCountMutation($increase: Boolean, $objectId: Int!, $timeStamp: String) {
    updateLikeCount(input: {increase: $increase, objectId: $objectId, timeStamp: $timeStamp}) {
      error
      likeCountChanged
    }
  }
`;
