import {Component, OnInit} from '@angular/core';
import {Hint} from '../../interfaces/hint';
import {Store} from '@ngrx/store';
import {AppState} from '../../store';
import {getSingleHintContent, getSingleHintVisibility} from '../../store/selectors/notification.selectors';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {hideSingleHint} from '../../store/actions/notification.actions';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mak-single-hint',
  templateUrl: './single-hint.component.html',
  styleUrls: ['./single-hint.component.scss']
})
export class SingleHintComponent implements OnInit {

  hintVisible = true;
  hint: Hint = {
    media: {
      isImage: true
    },
    de: {
      title: 'German Title',
      text: 'German Text'
    },
    en: {
      title: 'English Title',
      text: 'English Text'
    }
  };

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.store.select(getSingleHintContent)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        if (response) {
          this.hint = response;
        }
      });

    this.store.select(getSingleHintVisibility)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.hintVisible = response;
      });
  }

  closeHint(): void {
    this.store.dispatch(hideSingleHint({timestamp: new Date().toString()}));
  }

}
