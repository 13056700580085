<div class="object-details">
  <div class="object-details__dashboard">
    <mak-object-details-dashboard [objectDetails]="content"></mak-object-details-dashboard>
  </div>

  <div class="object-details__object-gallery">
    <mak-object-gallery [items]="content?.gallery"></mak-object-gallery>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="object-details__title" [innerHTML]="content | translateKey: 'title': (lang$ | async)"></h1>

        <div class="object-details__main-menu">
          <mak-object-details-main-menu [objectDetails]="content" (onItemClicked)="menuItemClicked($event)"></mak-object-details-main-menu>
        </div>

        <div class="object-details__text-content">
          <mak-object-details-text-content [data]="content"></mak-object-details-text-content>
        </div>

        <div class="object-details__related-tours">
          <mak-object-details-related-tours [objectId]="content.id"></mak-object-details-related-tours>
        </div>
      </div>
    </div>
  </div>

</div>


