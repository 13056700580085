import {gql} from 'apollo-angular';

export const GET_CODE_BY_PATTERN = gql`
  query GetCodeByPattern($pattern: String!) {
    contentNodes(where: {title: $pattern}) {
      edges {
        node {
          ... on Code {
            title
            databaseId
            acfCode {
              isLabAppCode
              labAppLink
            }
          }
        }
      }
    }
  }
`;
