export const MakConstants = {
  contentTypes: {
    object: 'OBJECT',
    tour: 'TOUR',
    resource: 'RESOURCE',
    code: 'CODE',
  },
  playerStates: {
    playing: 'PLAYING',
    paused: 'PAUSED',
    ended: 'ENDED',
    empty: 'EMPTY',
    ready: 'READY',
    resuming: 'RESUMING'
  },
  playerActions: {
    play: 'PLAY',
    pause: 'PAUSE'
  },
  poiTypes: {
    point: 'POINT',
    area: 'AREA'
  },
  playerModes: {
    mini: 'MINI',
    full: 'FULL'
  }
};
