import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SharedService} from '../services/shared.service';

@Component({
  selector: 'mak-pull-up-container',
  templateUrl: './pull-up-container.component.html',
  styleUrls: ['./pull-up-container.component.scss']
})
export class PullUpContainerComponent implements OnInit {

  private readonly ANIMATION_DURATION = 300; // ms
  private readonly DEFAULT_THRESHOLD = 200; // px
  threshold = this.DEFAULT_THRESHOLD;
  isDragging = false;
  lastPosY: any;
  @ViewChild('pullupWrapper') pullupWrapper: ElementRef | undefined;
  deltaY = 0;

  @Output('onStateChanged') onStateChanged = new EventEmitter<boolean>();

  @Input('state') set setState(value: boolean) {
    this.state = value;
    this.sharedService.toggleBodyScroll(!value);
  }
  @Input('isScrollable') isScrollable = false;
  @Input('additionalPaddingTop') additionalPaddingTop = false;

  state = false;

  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {
  }

  handleDrag(ev: any, isStart = false): void { // TODO change THRESHOLD depending on container height otherwise can be too much

    // isStart is workaround here, because (pan) event has event.isFirst always false
    if (!this.isScrollable) {
      if (isStart) {
        if (this.pullupWrapper) {
          this.threshold = this.pullupWrapper.nativeElement.clientHeight * 0.3;
          console.log('new threshold = ' + this.threshold);
        }
      }
      if (this.pullupWrapper) {
        if (!this.isDragging) {
          this.isDragging = true;
          this.lastPosY = this.pullupWrapper.nativeElement.offsetTop;
        }

        const posY = ev.deltaY + this.lastPosY;

        this.pullupWrapper.nativeElement.style.top = posY + 'px';

        // Drag ends
        if (ev.isFinal) {
          this.isDragging = false;

          // Threshold for pullup position
          if (-this.threshold < ev.deltaY && ev.deltaY < this.threshold) {
            this.pullupWrapper.nativeElement.style.top = this.lastPosY + 'px';
            return;
          }

          console.log(ev.deltaY);
          if (this.state && ev.deltaY < -this.threshold) {
            this.pullupWrapper.nativeElement.style.top = this.lastPosY + 'px';
            return;
          }

          this.deltaY = ev.deltaY;

          this.toggleMenu(this.deltaY < 0);
        }
      }
    }

  }

  toggleMenu(state: boolean): void {
    console.log('New menu state is ' + state);
    this.onStateChanged.emit(state);

    if (!state) {
      setTimeout(() => {
        if (this.pullupWrapper) {
          this.pullupWrapper.nativeElement.style.top = '';
        }
      }, this.ANIMATION_DURATION);
    }
  }
}
