<div [class]="'story story--' + story.theme" *ngIf="story && state">
  <div class="story__content-wrapper">
    <div class="story__header">
      <div class="story__pagination">
        <div class="story__pagination-item"
             *ngFor="let card of story.cards; index as idx"
             [ngClass]="{'story__pagination-item--done' : idx <= currentCardNumber}"></div>
      </div>
      <div class="story__title">{{story | translateKey:'title':(lang$ | async)}}</div>
      <div class="story__dashboard">
        <div class="story__add-to-favorites"></div>
        <div class="story__close" (click)="closeStory()"><span class="icon icon-close"></span></div>
      </div>
    </div>
    <div class="story__cards" (click)="onCardClicked($event)">
      <div class="story__card" *ngFor="let card of story.cards; index as idx">
        <mak-story-card [card]="card" *ngIf="currentCardNumber === idx"></mak-story-card>
      </div>
    </div>
  </div>
</div>
