import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ObjectDetailsMainMenuItem} from '../../interfaces/object-details-main-menu-item';
import {Story} from '../../interfaces/story';
import {Languages} from '../../enums/languages';
import {ObjectDetailsMainMenuItemType} from '../../enums/object-details-main-menu-item-type';
import {ObjectDetails} from '../../interfaces/object-details';
import {LanguageService} from '../../services/language.service';
import {TranslateService} from '@ngx-translate/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PlayerHistoryService} from '../../services/player-history.service';
import {AppState} from '../../store';
import {Store} from '@ngrx/store';
import {getActivePlaylistItem, getPlayerState, getPlaylist} from '../../store/selectors/audio.selectors';
import {take} from 'rxjs/operators';
import {ContentService} from '../../services/content.service';
import {MakConstants} from '../../shared/MakConstants';
import {Tour} from '../../interfaces/tour';
import {Playlist} from '../../interfaces/playlist';
import {combineLatest} from 'rxjs';
import {AcfLink} from '../../interfaces/acf-link';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mak-object-details-main-menu',
  templateUrl: './object-details-main-menu.component.html',
  styleUrls: ['./object-details-main-menu.component.scss']
})
export class ObjectDetailsMainMenuComponent implements OnInit {

  @Output('onItemClicked') onItemClicked = new EventEmitter<[ObjectDetailsMainMenuItemType, number]>();

  @Input('objectDetails') set setObjectDetails(value: ObjectDetails) {
    if (value) {
      this.data = value;
      this.onDataChanged(value);
    }
  }

  @Input('parentTourId') set setParentTourId(value: number | null) {
    if (value !== null) {
      this.contentService.getTourById(value).pipe(take(1)).subscribe((response) => {
        this.parentTour = response;
        this.onDataChanged(this.data);
      });
    } else {
      value = null;
    }
  }

  parentTour: Tour | null = null;

  data!: ObjectDetails;

  topItem!: ObjectDetailsMainMenuItem;
  leftColumnItems: ObjectDetailsMainMenuItem[] = [];
  rightColumnItems: ObjectDetailsMainMenuItem[] = [];

  mainAudioFileTitle = '';

  constructor(
    private language: LanguageService,
    private translateService: TranslateService,
    private playerHistory: PlayerHistoryService,
    private store: Store<AppState>,
    private contentService: ContentService
  ) { }

  ngOnInit(): void {
    this.language.getLanguage().pipe(untilDestroyed(this)).subscribe((response) => {
      // TODO can we prevent this call on ngOnInit and call it in case of language change only?
      // console.log('new language is ' + response);
      this.onDataChanged(this.data);
    });

    combineLatest([
      this.store.select(getPlayerState),
      this.store.select(getActivePlaylistItem),
      this.store.select(getPlaylist)
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([playerState, currentPlaylistItem, currentPlaylist]) => {

        this.onDataChanged(this.data);

        const idx = (currentPlaylistItem.index != null) ? currentPlaylistItem.index : 0; // prevent errors that index not exists
        if (currentPlaylist && playerState) {
          if (this.topItem) {
            this.topItem = this.getAudioUpdatedItem(this.topItem, currentPlaylist, idx, playerState);
          }
          this.leftColumnItems = this.leftColumnItems.map((item: ObjectDetailsMainMenuItem) => {
            return this.getAudioUpdatedItem(item, currentPlaylist, idx, playerState);
          });
          this.rightColumnItems = this.rightColumnItems.map((item: ObjectDetailsMainMenuItem) => {
            return this.getAudioUpdatedItem(item, currentPlaylist, idx, playerState);
          });
        }
      });
  }

  private getAudioItemType(item: ObjectDetailsMainMenuItem, newPlaylist: Playlist, newIndex: number, newPlayerState: string): ObjectDetailsMainMenuItemType {
    if (item.type !== ObjectDetailsMainMenuItemType.AUDIO &&
      item.type !== ObjectDetailsMainMenuItemType.AUDIO_PLAYING) { // at least check is it audio item or not
      return item.type;
    }
    if (newPlayerState !== MakConstants.playerStates.playing) {
      return ObjectDetailsMainMenuItemType.AUDIO;
    }
    const stringifiedCurrentPlaylist = JSON.stringify(newPlaylist);
    if (item.uniqueId === 0) { // it can be playlist of single object or tour's object, so check both cases
      // console.log(item);

      if (this.data.playlist !== undefined && this.data.playlist.items.length) {
        if (JSON.stringify(this.data.playlist) === stringifiedCurrentPlaylist) { // here will be almost the same as in else state
          console.log('Possibly playlist of object');
          return ObjectDetailsMainMenuItemType.AUDIO_PLAYING;
        }
      } else {
        if (this.data.resources.audios.length) {
          if (JSON.stringify(this.data.resources.audios[0]) === stringifiedCurrentPlaylist) {
            console.log('It is playlist of first audio resource'); // case when no native playlist of object
            return ObjectDetailsMainMenuItemType.AUDIO_PLAYING;
          }
        }
      }

      // console.log(this.parentTour);

      if (this.parentTour !== null) { // check if parentTour object exists
        console.log('checking relative to parent tour');
        // tslint:disable-next-line:max-line-length
        if (JSON.stringify(this.parentTour.playlist) === stringifiedCurrentPlaylist) { // compare playlist of parent tour and current playlist
          // console.log('Possibly playlist of tour');
          // tslint:disable-next-line:max-line-length
          // here compare of current audioId of current playlist and audioId of current menu item (audioId of current menu item will be found in ObjectDetails.playlist)
          // tslint:disable-next-line:max-line-length
          return  (this.parentTour.playlist.items[newIndex].audioId === this.data.playlist?.items[this.topItem.uniqueId].audioId) ? ObjectDetailsMainMenuItemType.AUDIO_PLAYING : ObjectDetailsMainMenuItemType.AUDIO;
        }
      }
    } else {
      // tslint:disable-next-line:max-line-length
      const shiftedIndex = item.uniqueId - 1; // top item has id 0 and references to main playlist of object, id > 0 means that it is resource
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.data.resources.audios.length; i++) {
        const audioPlaylist = this.data.resources.audios[i];
        // console.log('Possibly playlist of resource');
        if (i === shiftedIndex) {
          if (stringifiedCurrentPlaylist === JSON.stringify(audioPlaylist)) {
            return ObjectDetailsMainMenuItemType.AUDIO_PLAYING;
          }
        }
      }
    }

    // Default return
    return ObjectDetailsMainMenuItemType.AUDIO;
  }
  /**
   * Calls everytime when Input or Language changed
   * @param objectDetails ObjectDetails
   */
  private onDataChanged(objectDetails: ObjectDetails): void {
    this.parseMenuItems(objectDetails).then((response) => {
      this.distributeItems(response);
    });
  }

  /**
   * Generates menu items based on data given
   * @param data ObjectDetails
   */

  private async parseMenuItems(data: ObjectDetails): Promise<ObjectDetailsMainMenuItem[]> {
    const items: ObjectDetailsMainMenuItem[] = [];

    const objectPlaylists: Playlist[] = [];
    if (data.playlist?.sprite.de) { // main playlist of object is always on top
      objectPlaylists.push(data.playlist);
    }
    if (data.resources.audios) { // then local playlists from resources
      data.resources.audios.forEach((audio: Playlist) => {
        objectPlaylists.push(audio);
      });
    }
    items.push(...await this.parseAudios(objectPlaylists));

    if (data.direction) {
      if (data.direction.cards) {
        items.push(...this.parseDirections(data.direction));
      }
    }

    if (data.locationData) {
      items.push(...this.parseLocationData(data.locationData));
    }

    if (data.resources.links) {
      items.push(...this.parseLinks(data.resources.links));
    }
    items.push(...this.parseStories(data.resources.stories));
    return items;
  }

  private parseLinks(links: AcfLink[]): ObjectDetailsMainMenuItem[] {
    const linksItems: ObjectDetailsMainMenuItem[] = [];
    links.forEach((el: AcfLink, idx: number) => {
      linksItems.push({
        tag: '',
        title: (this.language.currentLanguage === Languages.GERMAN) ? el.de.title : el.en.title,
        description: (this.language.currentLanguage === Languages.GERMAN) ? el.de.url : el.en.url,
        type: ObjectDetailsMainMenuItemType.LINK,
        uniqueId: idx
      });
    });

    return linksItems;
  }

  /**
   * Left Right Columns and top item that is first in list
   * @param items ObjectDetailsMainMenuItem
   */

  private distributeItems(items: ObjectDetailsMainMenuItem[]): void {
    // reset old state
    this.leftColumnItems = [];
    this.rightColumnItems = [];
    //
    if (items.length > 0) {
      this.topItem = items[0];
      items.shift();
      items.forEach((el: ObjectDetailsMainMenuItem, idx: number) => {
        (idx % 2 === 0) ? this.leftColumnItems.push(el) : this.rightColumnItems.push(el);
        this.leftColumnItems = [...this.leftColumnItems];
        this.rightColumnItems = [...this.rightColumnItems];
      });
    }
  }


  // TODO This method needs improvements or may be completely rewriting
  /**
   * Checks is audio item currently playing depending on current audio state, current playlist and its current index
   * @param item
   * @param currentPlaylist
   * @param currentPlaylistItem
   * @param currentState
   * @private
   */
  // tslint:disable-next-line:max-line-length
  private getAudioUpdatedItem(item: ObjectDetailsMainMenuItem, currentPlaylist: Playlist, currentPlaylistItem: number, currentState: string): ObjectDetailsMainMenuItem {
    return {
      ...item,
      type: this.getAudioItemType(item, currentPlaylist, currentPlaylistItem, currentState)
    };
  }

  private parseStories(storiesArray: Story[]): ObjectDetailsMainMenuItem[] {
    const storiesItems: ObjectDetailsMainMenuItem[] = [];
    storiesArray.forEach((el: Story, idx: number) => {
      const cardsWord = (el.cards.length > 1) ? this.translateService.instant('STORY.CARDS') : this.translateService.instant('STORY.CARD');
      storiesItems.push({
        tag: '',
        title: (this.language.currentLanguage === Languages.GERMAN) ? el.de.title : el.en.title,
        description: el.cards.length + ' ' + cardsWord,
        type: ObjectDetailsMainMenuItemType.STORY,
        uniqueId: idx
      });
    });

    return storiesItems;
  }

  private parseAudios(audiosArray: Playlist[]): Promise<ObjectDetailsMainMenuItem[]> {
    return new Promise<ObjectDetailsMainMenuItem[]>((resolve) => {

      combineLatest([
        this.store.select(getPlayerState),
        this.store.select(getPlaylist),
        this.store.select(getActivePlaylistItem)
      ]).pipe(take(1)).subscribe(([playerState, playlist, playlistIndex]) => {

        const audioMenuItems: ObjectDetailsMainMenuItem[] = [];

        audiosArray.forEach((el: Playlist, idx: number) => {
          if (el.items.length) {

            const playlistItem = el.items[0];

            // Do not display Resources with the same name as the main audio File
            if (idx === 0) {
              this.mainAudioFileTitle = playlistItem.de.title;
            } else if (this.mainAudioFileTitle === playlistItem.de.title) {
              return;
            }

            let itemLength = '';
            if (this.language.currentLanguage === Languages.GERMAN) { // N Min.
              itemLength = Math.round(playlistItem.de.duration / 60) +
                ' ' + this.translateService.instant('GENERAL.MIN');
            } else {
              itemLength =  Math.max(1, Math.round(playlistItem.en.duration / 60)) +
                ' ' + this.translateService.instant('GENERAL.MIN');
            }

            const newAudioItem = {
              tag: (this.playerHistory.getItemPlayed(playlistItem.audioId)) ? this.translateService.instant('GENERAL.PLAYED') : '',
              title: (this.language.currentLanguage === Languages.GERMAN) ? playlistItem.de.title : playlistItem.en.title,
              description: itemLength,
              // tslint:disable-next-line:max-line-length
              type: ObjectDetailsMainMenuItemType.AUDIO,
              uniqueId: idx
            };

            if (playlist && playlistIndex.index != null && playerState) {
              newAudioItem.type = this.getAudioItemType(newAudioItem, playlist, playlistIndex.index, playerState);
            }
            audioMenuItems.push(newAudioItem);
          }
        });
        resolve(audioMenuItems);
      });
    });
  }

  private parseDirections(directions: any): ObjectDetailsMainMenuItem[] {
    // Assume that only one directions given (directions is not array)
    return [{
      tag: '',
      title: this.translateService.instant('OBJECT_PREVIEW_MENU.WHERE_IS_OBJECT'),
      description: '',
      type: ObjectDetailsMainMenuItemType.DIRECTION,
      uniqueId: 0
    }];
  }

  private parseLocationData(location: any): ObjectDetailsMainMenuItem[] {
    return [{
      tag: '',
      title: this.translateService.instant('OBJECT_PREVIEW_MENU.SHOW_ON_PLAN'),
      description: '',
      type: ObjectDetailsMainMenuItemType.LOCATION,
      uniqueId: 0
    }];
  }
}
