import {createFeatureSelector, createSelector} from '@ngrx/store';
import {State, userFeatureKey} from '../reducers/user.reducer';

export const selectUserFeature = createFeatureSelector<State>(
  userFeatureKey
);

export const getIntroWatched = createSelector(
  selectUserFeature,
  (state: State) => state.introWatched
);

export const getFirstTourHintWatched = createSelector(
  selectUserFeature,
  (state: State) => state.firstTourHintWatched
);

export const getPlayedAudioIds = createSelector(
  selectUserFeature,
  (state: State) => state.playedAudioIds
);

export const getFavoriteObjects = createSelector(
  selectUserFeature,
  (state: State) => state.favoriteObjects
);

export const getLanguage = createSelector(
  selectUserFeature,
  (state: State) => state.language
);

export const getActiveTab = createSelector(
  selectUserFeature,
  (state: State) => state.activeTab
);

export const getVisitedPagesHomeTab = createSelector(
  selectUserFeature,
  (state: State) => state.visitedPagesHomeTab
);

export const getVisitedPagesCodeTab = createSelector(
  selectUserFeature,
  (state: State) => state.visitedPagesCodeTab
);

export const getVisitedPagesAudioTab = createSelector(
  selectUserFeature,
  (state: State) => state.visitedPagesAudioTab
);

export const getVisitedPagesLocationTab = createSelector(
  selectUserFeature,
  (state: State) => state.visitedPagesLocationTab
);

export const getVisitedPagesFavoritesTab = createSelector(
  selectUserFeature,
  (state: State) => state.visitedPagesFavoritesTab
);

export const getRouterAnimationDirection = createSelector(
  selectUserFeature,
  (state: State) => state.routerAnimationDirection
);

export const getMenuVisibility = createSelector(
  selectUserFeature,
  (state: State) => state.menuVisible
);

export const getCodeCounter = createSelector(
  selectUserFeature,
  (state: State) => state.codeCounter
);
export const getPreviousUrl = createSelector(
  selectUserFeature,
  (state: State) => state.previousUrl
);

export const getClosedAPINotificationIDs = createSelector(
  selectUserFeature,
  (state: State) => state.closedAPINotificationIDs
);


