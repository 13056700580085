import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../store';
import {LanguageService} from '../../services/language.service';
import {PlaylistItem} from '../../interfaces/playlist-item';
import {getActivePlaylistItem, getPlayerState} from '../../store/selectors/audio.selectors';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PlayerActionsService} from '../../services/player-actions.service';
import {Playlist} from '../../interfaces/playlist';
import {MakConstants} from '../../shared/MakConstants';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mak-playlist-line-item',
  templateUrl: './playlist-line-item.component.html',
  styleUrls: ['./playlist-line-item.component.scss']
})
export class PlaylistLineItemComponent implements OnInit {

  @Output('itemClicked') itemClicked = new EventEmitter<number>();
  @Output('itemMenuOpened') itemMenuOpened = new EventEmitter<number>();

  lang$ = this.language.getLanguage();

  playlist!: Playlist;
  playlistItem!: PlaylistItem;
  isPlaying = false;
  index = 0;
  isCurrentItem = false;

  @Input('playlist') set setPlaylist(value: Playlist | null) {
    if (value) {
      this.playlist = value;
      this.playlistItem = this.playlist.items[this.index];
    }
  }

  @Input('index') set setIndex(value: number | null) {
    if (value) {
      this.index = value;
      if (this.playlist.items?.length) {
        this.playlistItem = this.playlist.items[this.index];
      }
    }
  }

  constructor(
    private store: Store<AppState>,
    private language: LanguageService,
    private playerActions: PlayerActionsService
  ) { }

  ngOnInit(): void {
    this.store.select(getActivePlaylistItem)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.isCurrentItem = response && response.index === this.index;
      });

    this.store.select(getPlayerState)
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.isPlaying = (response === MakConstants.playerStates.playing);
      });
  }

  setActiveItem(): void {
    this.playerActions.toggleAudio(this.playlist, this.index);
    // this.itemClicked.emit(this.index);
  }

  openObjectMenu($event: Event, index: number): void {
    $event.stopPropagation();
    this.itemMenuOpened.emit(index);
  }
}
