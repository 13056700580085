import {Injectable} from '@angular/core';
import {GlobalOptions} from '../interfaces/global-options';
import {GetGlobalOptions} from '../queries/__generated__/GetGlobalOptions';
import {GET_GLOBAL_OPTIONS} from '../queries/GET_GLOBAL_OPTIONS';
import {AppState} from '../store';
import {Store} from '@ngrx/store';
import {getGlobalOptions} from '../store/selectors/global-options.selectors';
import {take} from 'rxjs/operators';
import {HintsService} from './hints.service';
import {Apollo} from 'apollo-angular';
import {ImageService} from './image.service';
import {setGlobalOptions} from '../store/actions/global-options.actions';
import {OpeningHoursService} from './opening-hours.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalOptionsService {

  constructor(private store: Store<AppState>,
              private hintsService: HintsService,
              private apollo: Apollo,
              private imageService: ImageService,
              private openingHoursService: OpeningHoursService) { }

  getGlobalOptions(): Promise<GlobalOptions> {
    return new Promise<GlobalOptions>((resolve, reject) => {
      this.store.select(getGlobalOptions).pipe(take(1)).subscribe((globalOptions) => {
        if (globalOptions !== null) {
          console.log('Global Options found!');
          resolve(globalOptions);
        } else {
          console.log('Global Options was not found!');
          console.log('Loading global options...');
          this.loadGlobalOptions().then((response) => {
            console.log('Global Options were loaded!');
            this.store.dispatch(setGlobalOptions({options: response}));
            resolve(response);
          }).catch((err) => {
            console.error('Error while loading global options');
          });
        }
      });
    });
  }

  private loadGlobalOptions(): Promise<GlobalOptions> {
    return new Promise<GlobalOptions>((resolve, reject) => {

      const globalOptions: GlobalOptions = {
        anniversaryPromotionActive: false,
        introTourCards: [],
        firstTourHint: this.hintsService.getEmptyHint(),
        beforeCloseHint: this.hintsService.getEmptyHint(),
        openingHours: this.openingHoursService.getEmptyOpeningHours()
      };

      this.apollo
        .query<GetGlobalOptions>({
          query: GET_GLOBAL_OPTIONS
        })
        .subscribe(({data, loading}) => {
          if (data.globalOptions) {
            globalOptions.anniversaryPromotionActive = data.globalOptions.acfGlobalOptions?.anniversaryPromotionActive ?? false;

            data.globalOptions.acfGlobalOptions?.introTourCards?.forEach((el) => {
              globalOptions.introTourCards.push({
                de: {
                  title: el?.titleDe ?? '',
                  text: el?.bodyTextDe ?? ''
                },
                en: {
                  title: el?.titleEn ?? '',
                  text: el?.bodyTextEn ?? ''
                },
                media: {
                  isImage: !el?.isAnimation,
                  lottieAnimationData: el?.animationData ?? '',
                  image: this.imageService.parseAcfImage(el?.illustration)
                }
              });
            });

            // First Tour Hint Parse
            globalOptions.firstTourHint.media.isImage = !data.globalOptions.acfGlobalOptions?.firstTourHint?.firstTourHintIsAnimation ?? true;
            globalOptions.firstTourHint.media.image = this.imageService.parseAcfImage(data.globalOptions.acfGlobalOptions?.firstTourHint?.firstTourHintIllustration);
            globalOptions.firstTourHint.media.lottieAnimationData = data.globalOptions.acfGlobalOptions?.firstTourHint?.firstTourHintAnimation ?? '';
            globalOptions.firstTourHint.de.title = data.globalOptions.acfGlobalOptions?.firstTourHint?.firstTourHintTitleDe ?? '';
            globalOptions.firstTourHint.de.text = data.globalOptions.acfGlobalOptions?.firstTourHint?.firstTourHintTextDe ?? '';
            globalOptions.firstTourHint.en.title = data.globalOptions.acfGlobalOptions?.firstTourHint?.firstTourHintTitleEn ?? '';
            globalOptions.firstTourHint.en.text = data.globalOptions.acfGlobalOptions?.firstTourHint?.firstTourHintTextEn ?? '';

            // Hint Before Close
            globalOptions.beforeCloseHint.media.isImage = !data.globalOptions.acfGlobalOptions?.closeHint?.closeHintIsAnimation ?? true;
            globalOptions.beforeCloseHint.media.image = this.imageService.parseAcfImage(data.globalOptions.acfGlobalOptions?.closeHint?.closeHintIllustration);
            globalOptions.beforeCloseHint.media.lottieAnimationData = data.globalOptions.acfGlobalOptions?.closeHint?.closeHintAnimation ?? '';
            globalOptions.beforeCloseHint.de.title = data.globalOptions.acfGlobalOptions?.closeHint?.closeHintTitleDe ?? '';
            globalOptions.beforeCloseHint.de.text = data.globalOptions.acfGlobalOptions?.closeHint?.closeHintTextDe ?? '';
            globalOptions.beforeCloseHint.en.title = data.globalOptions.acfGlobalOptions?.closeHint?.closeHintTitleEn ?? '';
            globalOptions.beforeCloseHint.en.text = data.globalOptions.acfGlobalOptions?.closeHint?.closeHintTextEn ?? '';

            // Opening Hours
            if (data.globalOptions.acfGlobalOptions?.openingHours) {
              globalOptions.openingHours = this.openingHoursService.parseOpeningHours(data.globalOptions.acfGlobalOptions.openingHours);
              console.log(globalOptions.openingHours);
            }

            resolve(globalOptions);
          }
        });
    });
  }
}
