import {createFeatureSelector, createSelector} from '@ngrx/store';
import {objectFeatureKey, State} from '../reducers/object.reducer';

export const selectObjectFeature = createFeatureSelector<State>(
  objectFeatureKey
);

export const getObjectForPullUp = createSelector(
  selectObjectFeature,
  (state: State) => state.objectForPullUp
);

export const getTourSwipingState = createSelector(
  selectObjectFeature,
  (state: State) => state.tourSwipingState
);

export const getTourPointer = createSelector(
  selectObjectFeature,
  (state: State) => state.tourPointer
);

export const getStoryObject = createSelector(
  selectObjectFeature,
  (state: State) => state.story
);

