import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TabNavigationService} from '../services/tab-navigation.service';
import {NavigationTabs} from '../enums/navigation-tabs';
import {Store} from '@ngrx/store';
import {AppState} from '../store';
import {getActiveTab} from '../store/selectors/user.selectors';

@Component({
  selector: 'mak-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit {

  activeTab$ = this.store.select(getActiveTab);
  navigationTabsEnum = NavigationTabs;

  mainMenuState = false;

  constructor(
    private store: Store<AppState>,
    private tabNavigation: TabNavigationService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  toggleMainMenu($event: boolean): void {
    this.mainMenuState = $event;
    console.log($event);
    this.cdRef.detectChanges(); // TODO it worked ok without this line, now it doesn't
  }

  gotToTab(audioArchive: string): void {
    switch (audioArchive) {
      case 'home':
        this.tabNavigation.goToTab(NavigationTabs.HOME);
        break;
      case 'code':
        this.tabNavigation.goToTab(NavigationTabs.CODE);
        break;
      case 'audio':
        this.tabNavigation.goToTab(NavigationTabs.AUDIO);
        break;
      case 'locations':
        this.tabNavigation.goToTab(NavigationTabs.LOCATION);
        break;
      default:
        console.warn('MainNavComponent/gotToTab: ' + audioArchive + ' not mapped');
    }
  }
}
