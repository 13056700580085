import {Injectable} from '@angular/core';
import {Playlist} from '../interfaces/playlist';
import {Store} from '@ngrx/store';
import {AppState} from '../store';
import {setActivePlaylistItem, setPlayerAction, setPlayerMode, setPlaylist} from '../store/actions/audio.actions';
import {MakConstants} from '../shared/MakConstants';
import {getPlayerState} from '../store/selectors/audio.selectors';
import {take} from 'rxjs/operators';
import {LanguageService} from './language.service';

@Injectable({
  providedIn: 'root'
})
export class PlayerActionsService {

  constructor(
    private store: Store<AppState>,
    private languageService: LanguageService
  ) { }

  toggleAudio(playlist: Playlist, index: number): void {
    this.loadAudioFile(playlist);
    this.setIndex(index);
    this.togglePlayerState();
    this.setMediaSession(playlist.items[index][this.languageService.currentLanguage].title);
  }

  setMediaSession(title: string): void {
    if ('mediaSession' in navigator) {
      // @ts-ignore
      navigator.mediaSession.metadata = new MediaMetadata({
        title,
        artist: 'MAK APP',
        album: '',
        artwork: [
          { src: '/assets/img/media-session/app-icon-ios-5.png',   sizes: '96x96',   type: 'image/png' },
          { src: '/assets/img/media-session/app-icon-ios-4.png', sizes: '128x128', type: 'image/png' },
          { src: '/assets/img/media-session/app-icon-ios-3.png', sizes: '192x192', type: 'image/png' },
          { src: '/assets/img/media-session/app-icon-ios-2.png', sizes: '256x256', type: 'image/png' },
          { src: '/assets/img/media-session/app-icon-ios-1.png', sizes: '384x384', type: 'image/png' },
          { src: '/assets/img/media-session/app-icon-ios.png', sizes: '512x512', type: 'image/png' },
        ]
      });
    } else {
      console.warn('Media Session is not supported in this browser');
    }
  }

  loadAudioFile(playlist: Playlist): void {
    this.store.dispatch(setPlaylist({
      playlist
    }));
  }

  setIndex(index: number): void {
    this.store.dispatch(setActivePlaylistItem({index, timestamp: new Date().toString()}));
  }

  togglePlayerState(): void {

    this.store.select(getPlayerState)
      .pipe(take(1))
      .subscribe(playerState => {

        let playerAction = MakConstants.playerActions.play;

        if (playerState === MakConstants.playerStates.playing) {
          playerAction = MakConstants.playerActions.pause;
        } else {
          playerAction = MakConstants.playerActions.play;
        }

        console.log('PlayerActionsService/togglePlayerState to: ' + playerAction);

        this.store.dispatch(setPlayerAction({action: playerAction, timestamp: new Date().toString()}));
      });

  }

  setPlayerMode(state: string): void {
    switch (state) {
      case MakConstants.playerModes.mini:
        break;
      case MakConstants.playerModes.full:
        break;
      default:
        console.error('Mode was not found!');
        return;
    }
    this.store.dispatch(setPlayerMode({mode: state}));
  }

}
