import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ObjectDetailsMainMenuItem} from '../../interfaces/object-details-main-menu-item';
import {ObjectDetailsMainMenuItemType} from '../../enums/object-details-main-menu-item-type';

@Component({
  selector: 'mak-object-details-main-menu-item',
  templateUrl: './object-details-main-menu-item.component.html',
  styleUrls: ['./object-details-main-menu-item.component.scss']
})
export class ObjectDetailsMainMenuItemComponent implements OnInit {

  @Output('onItemClicked') onItemClicked = new EventEmitter<[ObjectDetailsMainMenuItemType, number]>();

  @Input('data') set setData(value: ObjectDetailsMainMenuItem) {
    this.data = value;

    setTimeout(() => {
      this.setTitleTranslateX();
    }, 10);
  }

  @ViewChild('titleElement') titleElement!: ElementRef;
  @ViewChild('titleTextElement') titleTextElement!: ElementRef;

  menuTypes = ObjectDetailsMainMenuItemType;

  data!: ObjectDetailsMainMenuItem;

  private readonly TITLE_DURATION_PER_WORD = 300; // ms 400-500 seems ok
  private readonly WORDS_NUMBER_INITIALLY_IN_VIEW = 20; // approximately
  titleTransitionDirection = false; // false - going to start, true - going to end
  titleTransitionDuration = 0; // ms dynamic
  titleTransitionInterval!: number;

  constructor() { }

  ngOnInit(): void {
  }

  setTitleTranslateX(): void {
    clearInterval(this.titleTransitionInterval); // clear last interval in any case
    this.resetTitleTransform(); // set transition to start
    if (this.titleElement && this.titleTextElement) { // viewChild check
      const currentTitle = this.data.description; // title that will be rendered
      if (currentTitle) {
        const wrapperWidth = this.titleElement.nativeElement.clientWidth; // width of wrapper with limited width
        const textWidth = this.titleTextElement.nativeElement.clientWidth; // width of text with unlimited width
        if (textWidth > wrapperWidth) { // transition if needs more space only
          const titleLength = currentTitle.length - this.WORDS_NUMBER_INITIALLY_IN_VIEW;
          this.titleTransitionDuration = this.TITLE_DURATION_PER_WORD * titleLength; // duration from start to end position
          this.titleTextElement.nativeElement.style.transitionDuration = `${this.titleTransitionDuration}ms`;
          this.titleTextElement.nativeElement.style.transform = `translateX(${wrapperWidth - textWidth}px)`;
          this.titleTransitionInterval = window.setInterval(() => { // in interval with delay of transition duration set translate to start or end
            if (this.titleTransitionDirection) {
              this.titleTextElement.nativeElement.style.transform = `translateX(0px)`;
            } else {
              this.titleTextElement.nativeElement.style.transform = `translateX(${wrapperWidth - textWidth}px)`;
            }
            this.titleTransitionDirection = !this.titleTransitionDirection;
          }, this.titleTransitionDuration);
        }
      }
    }
  }

  resetTitleTransform(): void {
    if (this.titleElement && this.titleTextElement) { // viewChild check
      this.titleTextElement.nativeElement.style.transitionDuration = `0ms`;
      this.titleTextElement.nativeElement.style.transform = `translateX(0.1px)`; // 0.1px to trigger changes
      this.titleTransitionDirection = true;
    }
  }

}
