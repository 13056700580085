import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../store';
import {LanguageService} from '../../services/language.service';
import {Playlist} from '../../interfaces/playlist';
import {getPlaylist} from '../../store/selectors/audio.selectors';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PlayerActionsService} from '../../services/player-actions.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mak-playlist-viewer',
  templateUrl: './playlist-viewer.component.html',
  styleUrls: ['./playlist-viewer.component.scss']
})
export class PlaylistViewerComponent implements OnInit {

  @Output('itemMenuOpened') itemMenuOpened = new EventEmitter<number>();

  @ViewChild('highlightNode') highlightNode!: ElementRef;
  @ViewChild('listNode') listNode!: ElementRef;

  @Input('currentIndex') set setCurrentIndex(value: number) {
    if (this.playlist) {
      console.log('current index changed');
      if (value >= 0 && value < this.playlist.items.length) {
        this.scrollToItem(value);
        this.lastItemIndex = value;
      }
    }

  }

  lang$ = this.language.getLanguage();
  playlist!: Playlist;

  listLastDeltaY = 0;
  isNoTransitionEffects = false;
  lastItemIndex = 0;

  constructor(
    private store: Store<AppState>,
    private language: LanguageService,
    private playerActions: PlayerActionsService
  ) { }

  ngOnInit(): void {
    this.store.select(getPlaylist)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        if (response) {
          this.playlist = response;
          this.scrollToItemNoTransition(0);
          this.lastItemIndex = 0;
        }
      });
  }

  onListScroll($event: any): void {
    if (this.listNode && this.highlightNode) {
      if ($event.isFinal) {
        this.isNoTransitionEffects = false;
        let nextElement = -1;
        this.listLastDeltaY = $event.deltaY + this.listLastDeltaY;
        const direction = $event.deltaY < 0; // true - down, false - up
        const highlightNodeOffsetTop = this.highlightNode.nativeElement.getBoundingClientRect().top;
        const highlightNodeOffsetBottom = highlightNodeOffsetTop + this.highlightNode.nativeElement.clientHeight;

        const newListScrollState = -this.listLastDeltaY;

        if (newListScrollState < 0) {
          this.listNode.nativeElement.style.transform = `translateY(0px)`;
          // this.listLastDeltaY = 0;
          nextElement = 1;
        }

        if (newListScrollState > this.listNode.nativeElement.clientHeight) {
          this.listNode.nativeElement.style.transform = `translateY(${-this.listNode.nativeElement.clientHeight}px)`;
          // this.listLastDeltaY = -this.listNode.nativeElement.clientHeight;
          nextElement = this.playlist.items.length;
        }

        if (nextElement === -1) {
          const listNodesWrapper = this.listNode.nativeElement as Element;
          let newDifference = 99999;

          listNodesWrapper.querySelectorAll('.playlist-viewer__items-item').forEach((el: Element, idx: number) => {
            idx = idx + 1;

            const elOffsetTop = el.getBoundingClientRect().top;
            const elOffsetBottom = el.clientHeight + elOffsetTop;
            const middle = (elOffsetBottom - elOffsetTop) / 2;
            let difference = -1;

            if (direction) { // down
              difference = highlightNodeOffsetBottom - elOffsetTop;
            } else {
              difference = elOffsetBottom - highlightNodeOffsetTop;
            }

            if (difference > 0) {
              if (difference > middle) {
                if ((difference - middle) < newDifference) {
                  nextElement = idx;
                  newDifference = difference - middle;
                }
              }
            }
          });
        }

        console.log(`${this.lastItemIndex} !== ${nextElement - 1}`);
        if (this.lastItemIndex !== nextElement - 1) {
          this.playItem(nextElement - 1);
          this.lastItemIndex = nextElement - 1;
        } else {
          this.scrollToItem(nextElement - 1);
        }
        console.log('Next element is ' + nextElement);
      } else {
        this.isNoTransitionEffects = true;
        this.listNode.nativeElement.style.transform = `translateY(${$event.deltaY + this.listLastDeltaY}px)`;
      }

    }
  }

  scrollToItem(itemNumber: number): void {
    if (itemNumber >= 0 && itemNumber < this.playlist.items.length) {
      if (this.listNode) {
        const listNodesWrapper = this.listNode.nativeElement as Element;
        listNodesWrapper.querySelectorAll('.playlist-viewer__items-item').forEach((el, idx) => {
          if (idx === itemNumber) {
            if (el) {
              const htmlEl = el as HTMLElement;
              const offsetTop = htmlEl.getBoundingClientRect().top;
              const wrapperOffsetTop = this.listNode.nativeElement.getBoundingClientRect().top;
              this.listNode.nativeElement.style.transform = `translateY(${wrapperOffsetTop - offsetTop}px)`;
              this.listLastDeltaY = wrapperOffsetTop - offsetTop;
            }
          }
        });
      }
    }
  }

  scrollToItemNoTransition(itemNumber: number): void {
    this.isNoTransitionEffects = true;

    setTimeout(() => {
      this.scrollToItem(itemNumber);
      this.isNoTransitionEffects = false;
    }, 50);

  }

  playItem(idx: number): void {
    this.playerActions.toggleAudio(this.playlist, idx);
  }
}
