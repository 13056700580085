import {gql} from 'apollo-angular';
import {ImageFragment} from './ImageFragment';
import {YoastSeoFragment} from './YoastSeoFragment';
import {DirectionFragment} from './DirectionFragment';
import {ResourceFragment} from './ResourceFragment';

export const ObjectFragment = gql`
  fragment objectFields on Object {
    databaseId
    title
    acfObjectFields {
      titleEn
      textContentDe {
        text
      }
      textContentEn {
        text
      }
      previewImage {
        ...imageFields
      }
      locationData
      audioDurationDe
      audioTranscriptDe
      audioTranscriptEn
      audioDescriptionDe {
        mediaItemUrl
      }
      audioDurationEn
      audioDescriptionEn {
        mediaItemUrl
      }
      gallery {
        ...imageFields
      }
      directionsObjects {
        ... on Direction {
          ...directionFields
        }
      }
      objectsResources {
        ... on Resource {
          ...resourceFields
        }
      }
    }
    entityCode {
      codeAssignment {
        ... on Code {
          title
        }
      }
    }
    seo {
      ...yoastSeoFields
    }
  }
  ${YoastSeoFragment}
  ${ImageFragment}
  ${DirectionFragment}
  ${ResourceFragment}
`;
