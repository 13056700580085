import {gql} from 'apollo-angular';

export const GET_PAGE_BY_ID = gql`
  query GetPageById($postId: Int!) {
    pageBy(pageId: $postId) {
      title
      acfPage {
        contentDe
        contentEn
        titleEn
      }
    }
  }`;
