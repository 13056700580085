import {Injectable} from '@angular/core';
import {AcfImage} from '../interfaces/acf-image';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor() { }

  getEmptyImage(): AcfImage {
    return {
      src: '',
        srcSet: '',
        alt: '',
        title: '',
        caption: '',
        width: 0,
        height: 0
    };
  }

  parseAcfImage(restImage: any): AcfImage {

    if ( !restImage || typeof restImage === 'undefined' || !restImage.hasOwnProperty('mediaItemUrl') ) {
      // Return Empty Image if not set in Wordpress
      return {
        title: '',
        alt: '',
        caption: '',
        src: '',
        srcSet: '',
        width: 0,
        height: 0
      };
    }

    if (restImage.width === 0) {
      // console.log(restImage);
    }

    const acfImage: AcfImage = {
      title: restImage.title,
      alt: restImage.altText,
      caption: restImage.caption,
      src: restImage.mediaItemUrl,
      srcSet: restImage.srcSet,
      width: restImage.mediaDetails.width,
      height: restImage.mediaDetails.height
    };

    // console.log(acfImage);

    return acfImage;

  }

}
