import {Injectable} from '@angular/core';
import {Poster} from '../interfaces/poster';
import {ImageService} from './image.service';

@Injectable({
  providedIn: 'root'
})
export class PosterService {

  constructor(private image: ImageService) { }

  getEmptyPoster(): Poster {
    return {
      buttonType: 'text',
      colorScheme: 'yellow',
      de: {linkTitle: '', linkUrl: '', text: '', title: ''},
      en: {linkTitle: '', linkUrl: '', text: '', title: ''},
      icon: '',
      image: this.image.getEmptyImage(),
      isLinkExternal: false
    };
  }
}
