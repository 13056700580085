<div class="object-details-main-menu">
  <div class="object-details-main-menu__top-item" *ngIf="topItem">
    <mak-object-details-main-menu-item [data]="topItem" (onItemClicked)="onItemClicked.emit($event)"></mak-object-details-main-menu-item>
  </div>
  <div class="object-details-main-menu__column">
    <div class="object-details-main-menu__item" *ngFor="let item of leftColumnItems">
      <mak-object-details-main-menu-item [data]="item" (onItemClicked)="onItemClicked.emit($event)"></mak-object-details-main-menu-item>
    </div>
  </div>
  <div class="object-details-main-menu__column">
    <div class="object-details-main-menu__item" *ngFor="let item of rightColumnItems">
      <mak-object-details-main-menu-item [data]="item" (onItemClicked)="onItemClicked.emit($event)"></mak-object-details-main-menu-item>
    </div>
  </div>
</div>
