import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../store';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {getAudioTranscriptVisibility} from '../../store/selectors/audio.selectors';
import {TranslateService} from '@ngx-translate/core';
import {take} from 'rxjs/operators';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mak-transcript-panel',
  templateUrl: './transcript-panel.component.html',
  styleUrls: ['./transcript-panel.component.scss']
})
export class TranscriptPanelComponent implements OnInit {

  transcript = '';
  visible = false;

  @Output('onTranscriptPanelClosed') onTranscriptPanelClosed = new EventEmitter<null>();
  @Input('transcript') set setTranscript(value: string) {
    if (value) {
      this.transcript = value;
    } else {
      this.translate.get('PLAYER.NO_TRANSCRIPT').pipe(take(1)).subscribe(response => {
        this.transcript = response;
      });
    }
  }

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.store.select(getAudioTranscriptVisibility)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.visible = response;
      });
  }

}
