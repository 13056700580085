import {Injectable} from '@angular/core';
import {YoastMeta} from '../interfaces/yoast-meta';
import {Meta} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class YoastSeoService {

  activeMetaTags: any[] = new Array(0);

  constructor(private meta: Meta) { }

  mapYoastRestFields(rawYoastMeta: any): YoastMeta[] {

    return rawYoastMeta.fullHead;

  }

  setMetaTags(yoastMetaTags: YoastMeta[]): void {

    return;

    // Clean up existing meta tags
    this.activeMetaTags.forEach(activeMetaTag => {
      this.meta.removeTagElement(activeMetaTag);
    });

    yoastMetaTags.forEach(yoastMetaTag => {
      if (yoastMetaTag.attributeKey === 'name') {
        const result = this.meta.addTag({name: yoastMetaTag.attributeValue, content: this.transformHtml(yoastMetaTag.content)});
        this.activeMetaTags.push(result);
      } else if (yoastMetaTag.attributeKey === 'property') {
        const result = this.meta.addTag({property: yoastMetaTag.attributeValue, content: this.transformHtml(yoastMetaTag.content)});
        this.activeMetaTags.push(result);
      }
    });

  }

  transformHtml(value: string): string {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = value;
    return tempElement.innerText;
  }


}
