import {createAction, props} from '@ngrx/store';
import {Notification} from '../../interfaces/notification';
import {Hint} from '../../interfaces/hint';

export const addNotification = createAction(
  '[Notification] Add new notification item to pool',
  props<{notification: Notification}>()
);

export const setSingleHintContent = createAction(
  '[Notification] Set Single Hint Content',
  props<{hint: Hint}>()
);

export const showSingleHint = createAction(
  '[Notification] show Single Hint',
  props<{timestamp: string}>()
);

export const hideSingleHint = createAction(
  '[Notification] hide Single Hint',
  props<{timestamp: string}>()
);

