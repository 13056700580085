<div class="main-menu">

  <ul class="main-menu__items">
    <li
      (click)="setLanguage(languages.ENGLISH)"
      *ngIf="currentLanguage === 'de'"
      class="main-menu__item">
      <span class="icon icon-lang"></span><span class="main-menu__title">{{ "GENERAL.LANG_SWITCHER_EN" | translate }}</span>
    </li>
    <li
      (click)="setLanguage(languages.GERMAN)"
      *ngIf="currentLanguage === 'en'"
      class="main-menu__item">
      <span class="icon icon-lang"></span><span class="main-menu__title">{{ "GENERAL.LANG_SWITCHER_DE" | translate }}</span>
    </li>
    <li
      (click)="gotToTab('favoriten')"
      class="main-menu__item">
      <span class="icon icon-heart-full"></span><span class="main-menu__title">{{ "MENU.FAVORITES_LIST" | translate }}</span>
    </li>
    <li
      (click)="openTutorial()"
      class="main-menu__item">
      <span class="icon icon-questionmark"></span><span class="main-menu__title">{{ "MENU.HELP" | translate }}</span>
    </li>
    <li
      (click)="toSinglePage('/page/8621')"
      class="main-menu__item">
      <span class="icon icon-info"></span><span class="main-menu__title">{{ "MENU.INFORMATION" | translate }}</span>
    </li>
    <a
      href="https://mak.at/jart/prj3/mak-resp/main.jart?rel=de&content-id=1560210933290"
      target="_blank"
      (click)="closeMenu()"
      class="main-menu__item">
      <span class="icon icon-checkout"></span><span class="main-menu__title">{{ "MENU.TICKETS" | translate }}</span>
    </a>
    <a
      href="https://www.mak.at/newsletter"
      target="_blank"
      (click)="closeMenu()"
      class="main-menu__item">
      <span class="icon icon-greif"></span><span class="main-menu__title">{{ "MENU.NEWSLETTER" | translate }}</span>
    </a>
    <a
      href="https://www.mak.at/jart/prj3/mak-resp/main.jart?rel=de&content-id=1335170527278"
      target="_blank"
      (click)="closeMenu()"
      class="main-menu__item">
      <span class="icon icon-rooms"></span><span class="main-menu__title">{{ "MENU.VISIT" | translate }}</span>
    </a>
  </ul>

  <div class="main-menu__opening-hours">
    <mak-opening-hours></mak-opening-hours>
  </div>


</div>
