import {Component, Input, OnInit} from '@angular/core';
import {ObjectDetails} from '../../interfaces/object-details';
import {LanguageService} from '../../services/language.service';
import {Languages} from '../../enums/languages';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mak-object-details-text-content',
  templateUrl: './object-details-text-content.component.html',
  styleUrls: ['./object-details-text-content.component.scss']
})
export class ObjectDetailsTextContentComponent implements OnInit {

  @Input('data') set setData(value: ObjectDetails) {
    if (value) {
      this.data = value; // save data for future changed
      this.onDataChanged(this.data, this.language.currentLanguage);
    }
  }
  data!: ObjectDetails;
  lang$ = this.language.getLanguage();
  isShowMore = false;
  texts: { isFull: boolean, text: string }[] = [];

  constructor(private language: LanguageService) { }

  ngOnInit(): void {
    this.lang$.pipe(untilDestroyed(this)).subscribe((response) => {
      this.onDataChanged(this.data, response);
    });
  }

  onDataChanged(data: ObjectDetails, language: Languages): void {
    this.texts = [];
    if (language === Languages.GERMAN) {
      this.data.de.textContent.forEach((el: string) => {
        this.texts.push(this.rerenderText(el));
      });
    } else if (language === Languages.ENGLISH) {
      this.data.en.textContent.forEach((el: string) => {
        this.texts.push(this.rerenderText(el));
      });
    }
    this.texts = [...this.texts];
  }

  showMore(idx: number): void {
    this.texts[idx].isFull = true;
  }

  rerenderText(newText: string): { isFull: boolean, text: string } {
    let text = newText;
    let isAlreadyFull = true;
    if (text.indexOf('<p><!--more--></p>') > -1) { // difference is <p></p> wrapper only
      isAlreadyFull = false;
      text = `<div class="object-details-text-content__preview-part">${text}</div>`;
      text = text.replace('<p><!--more--></p>', `</div><div class="object-details-text-content__hidden-part">`);
    } else if (text.indexOf('<!--more-->') > -1) {
      isAlreadyFull = false;
      text = `<div class="object-details-text-content__preview-part">${text}</div>`;
      text = text.replace('<!--more-->', `</div><div class="object-details-text-content__hidden-part">`);
    }
    text.replace(new RegExp(/<[\S]+?><\/[\S]+?>/), ''); // search for possibly empty nodes like <p></p>

    return {
      isFull: isAlreadyFull,
      text
    };
  }
}
