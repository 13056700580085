import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../store';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {getPlayerSpeedVisibility} from '../../store/selectors/audio.selectors';
import {setPlayerSpeed} from '../../store/actions/audio.actions';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mak-player-speed',
  templateUrl: './player-speed.component.html',
  styleUrls: ['./player-speed.component.scss']
})
export class PlayerSpeedComponent implements OnInit {

  @Output('onPlayerSpeedClosed') onPlayerSpeedClosed = new EventEmitter<null>();

  visible = false;
  currentSpeed = 1;

  speeds = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.store.select(getPlayerSpeedVisibility)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.visible = response;
      });

    // seems like we dont need to know it anymore
    // this.store.select(getPlayerSpeed)
    //   .pipe(untilDestroyed(this))
    //   .subscribe(response => {
    //     this.currentSpeed = response;
    //   });
  }

  closePlayerSpeed(): void {
    this.onPlayerSpeedClosed.emit();
  }

  setPlayerSpeed(speed: number): void {
    this.store.dispatch(setPlayerSpeed({speed}));
    this.closePlayerSpeed();
  }

}
