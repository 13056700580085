import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ContentService} from './content.service';
import {forkJoin, Observable} from 'rxjs';
import {HomePage} from '../interfaces/home-page';

@Injectable({
  providedIn: 'root'
})
export class HomeResolverService   implements Resolve<any> {

  constructor(
    private contentService: ContentService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | {homePage: HomePage} {
    return forkJoin({
      homePage: this.contentService.getHomePageContent(),
    });
  }
}
