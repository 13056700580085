<div class="object-gallery" [ngClass]="{'object-gallery--no-pagination' : items.length < 2}" *ngIf="items">
  <div class="object-gallery__background">
    <div class="object-gallery__content">
      <swiper [config]="sliderOptions" (swiper)="sliderCreated($event)">
        <ng-template *ngFor="let item of items; index as idx" swiperSlide>
          <div class="object-gallery__item" (click)="enlargeImage($event, idx)">
            <mak-acf-image [image-data]="item" [disable-lazy-loading]="idx === 0"></mak-acf-image>
          </div>
        </ng-template>
      </swiper>
      <div class="object-gallery__pagination" *ngIf="items.length > 1">
        <div class="object-gallery__pagination-item"
             [ngClass]="{'object-gallery__pagination-item--active': idx === currentSlideNumber}"
             *ngFor="let item of items; index as idx" (click)="goToSlide(idx)"></div>
      </div>
    </div>
  </div>
</div>
