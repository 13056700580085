import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TurnDeviceComponent } from './turn-device.component';
import {LottieModule} from 'ngx-lottie';
import {TranslateModule} from '@ngx-translate/core';



@NgModule({
  declarations: [
    TurnDeviceComponent
  ],
  exports: [
    TurnDeviceComponent
  ],
  imports: [
    CommonModule,
    LottieModule,
    TranslateModule
  ]
})
export class TurnDeviceModule { }
