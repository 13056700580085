import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AcfImageComponent} from './acf-image.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';


@NgModule({
  declarations: [
    AcfImageComponent
  ],
  exports: [
    AcfImageComponent
  ],
  imports: [
    CommonModule,
    LazyLoadImageModule
  ]
})
export class AcfImageModule { }
