<div class="mini-player">
<!--  <div class="mini-player__enlarge-trigger"></div>-->
  <div class="mini-player__thumb-wrapper" (click)="titleClicked()">
    <mak-acf-image [image-data]="currentItem?.thumbnail"></mak-acf-image>
  </div>
  <div class="mini-player__title" #titleElement (click)="titleClicked()">
    <span class="mini-player__title-text" #titleTextElement>
      {{ currentItem | translateKey:'title':(lang$ | async) }}
    </span>
  </div>
  <div class="mini-player__close" (click)="closeMiniPlayer()">
    <span class="icon icon-close"></span>
  </div>
  <div class="mini-player__controls-wrapper">
    <div class="mini-player__control mini-player__control--pause" [ngClass]="{'mini-player__control--active' : playerState === makConstants.playerStates.playing}" (click)="togglePlayerState()">
      <span class="icon icon-pause"></span>
    </div>
    <div class="mini-player__control mini-player__control--play" [ngClass]="{'mini-player__control--active' : playerState === makConstants.playerStates.paused}" (click)="togglePlayerState()">
      <span class="icon icon-play"></span>
    </div>
  </div>

  <div class="mini-player__progress-bar-wrapper">
    <div class="mini-player__progress-bar-progress" [ngStyle]="{'width' : progress + '%'}"></div>
  </div>

</div>
