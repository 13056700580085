import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {getObjectForPullUp} from '../store/selectors/object.selectors';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ObjectDetails} from '../interfaces/object-details';
import {Store} from '@ngrx/store';
import {AppState} from '../store';
import {FavoritesService} from '../services/favorites.service';
import {getFavoriteObjects} from '../store/selectors/user.selectors';
import {take} from 'rxjs/operators';
import {ShareService} from '../services/share.service';
import {Router} from '@angular/router';
import {LanguageService} from '../services/language.service';
import {TabNavigationService} from '../services/tab-navigation.service';
import {setObjectInterest, setPoiOfInterest} from '../store/actions/map.actions';
import {setTourPointer} from '../store/actions/object.actions';
import {StoryService} from '../services/story.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mak-object-preview-menu',
  templateUrl: './object-preview-menu.component.html',
  styleUrls: ['./object-preview-menu.component.scss']
})
export class ObjectPreviewMenuComponent implements OnInit {

  $lang = this.language.getLanguage();

  @Input('tourId') tourId: number | null = null;

  @Output('onMenuClosed') onMenuClosed = new EventEmitter<null>();
  @Output('onLinkToObjectClicked') onLinkToObjectClicked = new EventEmitter<null>();

  objectDetails!: ObjectDetails;
  indexInTour = 1;

  isInFavorites = false;

  constructor(
    private store: Store<AppState>,
    private favorites: FavoritesService,
    private share: ShareService,
    private router: Router,
    private language: LanguageService,
    private tabNavigation: TabNavigationService,
    private storyService: StoryService
  ) { }

  ngOnInit(): void {
    this.store.select(getObjectForPullUp).pipe(untilDestroyed(this)).subscribe((response: ObjectDetails | null) => {
      if (response) {
        this.objectDetails = response;

        if (this.objectDetails.indexInTour) {
          this.indexInTour = this.objectDetails.indexInTour + 1;
        }

        this.store.select(getFavoriteObjects).pipe(take(1)).subscribe(localResponse => {
          this.isInFavorites = localResponse.map(el => el.id).indexOf(this.objectDetails.id) > -1;
        });
      }
    });

    this.store.select(getFavoriteObjects).pipe(untilDestroyed(this)).subscribe(response => {
      if (!this.objectDetails) {
        this.isInFavorites = false;
      } else {
        this.isInFavorites = this.favorites.isInFavoritesList(this.objectDetails, response);
      }
    });
  }

  onFavoritesToggleClicked(): void {
    this.favorites.toggleFavoriteObject(this.objectDetails);
    this.onMenuClosed.emit();
  }

  openShareMenu(): void {
    this.$lang
      .pipe(take(1))
      .subscribe((response: string) => {
        this.share.openShareMenu('MAK hörend erleben', this.router.url, 'Erkunden Sie das MAK mit dem neuen digitalen MAK Guide. Viele Audios, Hörstücke und hochauflösendes Bildmaterial lassen Sie faszinierende MAK-Objekte und ihre Geschichten auf eine ganz neue Art erleben.');
        this.onMenuClosed.emit();
    });
  }

  linkToObjectClicked(): void {
    const isInTourOverview = this.router.url.indexOf('/tour/') > -1;
    const isInTour = this.router.url.indexOf('/tour-object/') > -1;

    if ((this.tourId !== null || this.tourId === '') && isInTourOverview) {
      this.tabNavigation.gotDeeperInTab('/tour-object/' + this.tourId + '/' + this.indexInTour);
    } else if (isInTour) {
      this.store.dispatch(setTourPointer({index: this.objectDetails.indexInTour ?? 0, timestamp: new Date().toString() }));
    } else {
      this.tabNavigation.gotDeeperInTab('/object/' + this.objectDetails?.id);
    }
    this.onMenuClosed.emit();
    this.onLinkToObjectClicked.emit();


  }

  showObjectOnMap(): void {
    this.store.dispatch(setObjectInterest({objectDetails: this.objectDetails}));
    this.store.dispatch(setPoiOfInterest({poi: undefined}));
    this.router.navigateByUrl('/locations');
  }

  openObjectLocationStory(): void {
    if (this.objectDetails.direction) {
      this.storyService.openStory(this.objectDetails.direction);
      this.onMenuClosed.emit();
    }
  }
}
