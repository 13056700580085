import {Injectable} from '@angular/core';
import {Tour} from '../interfaces/tour';
import {TourCard} from '../interfaces/tour-card';
import {CardMapperService} from './card-mapper.service';
import {ImageService} from './image.service';
import {PlaylistItem} from '../interfaces/playlist-item';
import {AcfImage} from '../interfaces/acf-image';
import {TourPreview} from '../interfaces/tour-preview';

@Injectable({
  providedIn: 'root'
})
export class TourMapperService {

  constructor(
    private cardMapper: CardMapperService,
    private image: ImageService
  ) { }

  mapTourPreviewData(rawData: any, tourId: number = 0): TourPreview {

    const tourPreview: TourPreview = {
      id: rawData.databaseId ?? 0,
      pattern: rawData.acf?.pattern ?? '',
      previewImages: [],
      cardIds: [],
      audioIds: [],
      hiddenInArchive: rawData.acf?.hiddenInArchive ?? false,
      de: {
        title: rawData.title ?? '',
        totalDuration: rawData.acf?.audioDurationDe ?? 0,
        description: rawData.acf?.descriptionDe ?? '',
      },
      en: {
        title: rawData.acf.titleEn ?? '',
        totalDuration: rawData.acf?.audioDurationEn ?? 0,
        description: rawData.acf?.descriptionEn ?? '',
      },
      isExternalTour: false,
      externalTourLink: {
        url: '',
        title: ''
      }
    };

    if (rawData?.acf?.previewImages && rawData.acf.previewImages.length) {
      rawData.acf.previewImages.forEach((el: any) => {
        tourPreview.previewImages.push(this.image.parseAcfImage(el));
      });
    }

    if (rawData?.acf?.isExternalTour) {
      tourPreview.isExternalTour = true;
      tourPreview.externalTourLink = {
        title: rawData.acf?.externalTourLink?.title ?? '',
        url: rawData.acf?.externalTourLink?.url ?? ''
      };
    }

    if (rawData.acf?.cards.length > 0) {
      rawData.acf?.cards?.forEach((card: any, index: number) => {
        tourPreview.cardIds.push(card.object.databaseId);

        // Take Object ID if is regular audio
        let audioId = card.object.databaseId.toString();

        // Take resource ID if is overwritten with resource
        if (card.overrideWithResource && card.resource?.databaseId) {
          audioId = card.resource?.databaseId.toString();
        } else if (card.overrideAudioDe) {
        // Take hashed title of german audio sprite if overwritten with audio file
          audioId = this.hashString(card.overrideAudioDe.mediaItemUrl);
        }

        tourPreview.audioIds.push(audioId);

      });
    }

    return tourPreview;

  }

  mapTourData(rawData: any, tourId: number = 0): Tour {
    const cards: TourCard[] = [];
    let totalDurationDe = 0;
    let totalDurationEn = 0;

    const playlistItems: PlaylistItem[] = new Array(0);

    rawData.acf?.cards?.forEach((card: any, index: number) => {
      if (card?.object) {
        totalDurationDe += card?.durationDe ?? 0;
        totalDurationEn += card?.durationEn ?? 0;
        cards.push(this.cardMapper.mapCardData(card, index, tourId));


        const audioTranscript = {
          de: card.object.acfObjectFields.audioTranscriptDe ?? '',
          en: card.object.acfObjectFields.audioTranscriptEn ?? ''
        };

        if (card.overrideWithResource && card.resource?.acfResourceFields?.audio?.transcriptDe) {
            audioTranscript.de = card.resource?.acfResourceFields?.audio?.transcriptDe ?? audioTranscript.de;
            audioTranscript.en = card.resource?.acfResourceFields?.audio?.transcriptEn ?? audioTranscript.en;
        }

        // Take Object ID if is regular audio
        let audioId = card.object.databaseId.toString();

        // Take resource ID if is overwritten with resource
        if (card.overrideWithResource && card.resource?.databaseId) {
          audioId = card.resource?.databaseId.toString();
        } else if (card.overrideAudioDe) {
          // Take hashed title of german audio sprite if overwritten with audio file
          audioId = this.hashString(card.overrideAudioDe.mediaItemUrl);
        }

        const item: PlaylistItem = {
          objectDetailsId: card.object.databaseId,
          parentTourId: tourId,
          audioId,
          thumbnail: this.image.parseAcfImage(card.object.acfObjectFields.previewImage),
          de: {
            title: card.overrideTitleDe ? card.overrideTitleDe : card.object.title ?? '',
            timestamp: card?.timestampDe ?? 0,
            duration: card?.durationDe ?? 0,
            audioTranscript: audioTranscript.de
          },
          en: {
            title: card.overrideTitleEn ? card.overrideTitleEn :
              card.object.acfObjectFields.titleEn ? card.object.acfObjectFields.titleEn : card.object.acfObjectFields.title ?? '',
            timestamp: card?.timestampEn ?? 0,
            duration: card?.durationEn ?? 0,
            audioTranscript: audioTranscript.en
          }
        };

        playlistItems.push(item);

      }
    });

    const previewImages: AcfImage[] = [];
    if (rawData?.acf?.previewImages && rawData.acf.previewImages.length) {
      rawData.acf.previewImages.forEach((el: any) => {
        previewImages.push(this.image.parseAcfImage(el));
      });
    }

    // console.log(rawData);
    return  {
      id: rawData.databaseId,
      hiddenInArchive: rawData.acf?.hiddenInArchive ?? false,
      cards,
      pattern: rawData.acf?.pattern ?? '',
      previewImages,
      de: {
        title: rawData.title ?? '',
        totalDuration: totalDurationDe,
        description: rawData.acf?.descriptionDe ?? '',
      },
      en: {
        title: rawData.acf.titleEn ?? '',
        totalDuration: totalDurationEn,
        description: rawData.acf?.descriptionEn ?? '',
      },
      playlist: {
        sprite: {
          de: rawData.acf.audioSpriteFileDe ?? '',
          en: rawData.acf.audioSpriteFileEn ?? ''
        },
        items: playlistItems
      }
    };
  }

  /**
   * From: https://stackoverflow.com/a/7616484
   */
  hashString(input: string): string {
      let hash = 0;
      let i;
      let chr;

      if (input.length === 0) {
        return hash.toString();
      }

      for (i = 0; i < input.length; i++) {
        chr   = input.charCodeAt(i);
        // tslint:disable-next-line:no-bitwise
        hash  = ((hash << 5) - hash) + chr;
        // tslint:disable-next-line:no-bitwise
        hash |= 0; // Convert to 32bit integer
      }
      return hash.toString();

  }

}



