import {gql} from 'apollo-angular';
import {ObjectFragment} from './framents/ObjectFragment';

export const GET_OBJECT = gql`
  query GetObjectById($postId: ID!) {
    object(id: $postId, idType: DATABASE_ID) {
      ...objectFields
    }
  }
  ${ObjectFragment}`;
