import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ObjectGalleryService} from '../services/object-gallery.service';
import {SharedService} from '../services/shared.service';
import {ObjectGalleryEnlargedItem} from '../interfaces/object-gallery-enlarged-item';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mak-object-gallery-enlarged',
  templateUrl: './object-gallery-enlarged.component.html',
  styleUrls: ['./object-gallery-enlarged.component.scss']
})
export class ObjectGalleryEnlargedComponent implements OnInit, OnDestroy {

  @ViewChild('myPinch', {static: false}) myPinch: any;

  enlargedItem: ObjectGalleryEnlargedItem = {
    item: undefined,
    sourcePosition: {
      top: 0,
      left: 0
    }
  };

  state = false;
  imageViewportSize = '100px';
  srcSet = '';

  constructor(
    private objectGallery: ObjectGalleryService,
    private sharedService: SharedService) {
    this.imageViewportSize = window.innerWidth + 'px';
  }

  ngOnInit(): void {
    this.objectGallery.getEnlargedItemUpdates()
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
      if (response !== null) {
        this.enlargedItem = Object.assign({}, response);
        if (this.enlargedItem.item?.src.indexOf('gif') === -1) {
          this.srcSet = this.enlargedItem.item.srcSet;
        } else {
          this.srcSet = this.enlargedItem.item?.src ?? '';
        }
        this.state = true;
        this.sharedService.toggleBodyScroll(false);
      } else {
        this.state = false;
        this.sharedService.toggleBodyScroll(true);
      }
    });
  }

  ngOnDestroy(): void {
    this.myPinch.toggleZoom();
    this.myPinch.destroy();
  }

  closeEnlargedImage(): void {
    this.objectGallery.closeImage();
  }
}
