import {gql} from 'apollo-angular';
import {ImageFragment} from './framents/ImageFragment';

export const GET_NOTIFICATIONS_BY_DATE = gql`
  query GetNotificationsByDate($notificationDate: String!) {
    contentNodes(where: {notificationDate: $notificationDate}, first: 1000) {
      edges {
        node {
          ... on Notification {
            databaseId
            acfNotification {
              linkDe {
                target
                title
                url
              }
              linkEn {
                target
                title
                url
              }
              tagDe
              tagEn
              textDe
              textEn
              image {
                ... imageFields
              }
            }
          }
        }
      }
    }
  }

${ImageFragment}`;
