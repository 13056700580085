import {createFeatureSelector, createSelector} from '@ngrx/store';
import {globalOptionsFeatureKey, State} from '../reducers/global-options.reducer';

export const selectGlobalOptionsFeature = createFeatureSelector<State>(
  globalOptionsFeatureKey
);

export const getGlobalOptions = createSelector(
  selectGlobalOptionsFeature,
  (state: State) => state.globalOptions
);


