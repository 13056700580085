import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {ObjectDetailsComponent} from './object-details.component';
import {AcfImageModule} from '../atoms/acf-image/acf-image.module';
import {ObjectDetailsRoutingModule} from './object-details-routing.module';
import {ObjectGalleryModule} from '../object-gallery/object-gallery.module';
import {StoryModule} from '../story/story.module';
import {PipesModule} from '../pipes/pipes.module';
import {ObjectDetailsDashboardComponent} from './object-details-dashboard/object-details-dashboard.component';
import {ObjectDetailsTextContentComponent} from './object-details-text-content/object-details-text-content.component';
import {ObjectDetailsRelatedToursComponent} from './object-details-related-tours/object-details-related-tours.component';
import {ObjectDetailsMainMenuComponent} from './object-details-main-menu/object-details-main-menu.component';
import {ShareButtonModule} from '../atoms/share-button/share-button.module';
import {ObjectDetailsMainMenuItemComponent} from './object-details-main-menu-item/object-details-main-menu-item.component';
import {ContentPreviewModule} from '../content-preview/content-preview.module';
import {TranslateModule} from '@ngx-translate/core';
import {PullUpContainerModule} from '../pull-up-container/pull-up-container.module';
import {AudioResourceModule} from '../audio-resource/audio-resource.module';

@NgModule({
  declarations: [
    ObjectDetailsComponent,
    ObjectDetailsDashboardComponent,
    ObjectDetailsTextContentComponent,
    ObjectDetailsRelatedToursComponent,
    ObjectDetailsMainMenuComponent,
    ObjectDetailsMainMenuItemComponent
  ],
  exports: [
    ObjectDetailsComponent,
    ObjectDetailsDashboardComponent,
    ObjectDetailsMainMenuComponent,
    ObjectDetailsTextContentComponent
  ],
  providers: [
    DatePipe // TODO darf ich hier DatePipe importieren?
  ],
    imports: [
        CommonModule,
        AcfImageModule,
        ObjectDetailsRoutingModule,
        ObjectGalleryModule,
        StoryModule,
        PipesModule,
        ShareButtonModule,
        ContentPreviewModule,
        TranslateModule,
        PullUpContainerModule,
        AudioResourceModule
    ]
})
export class ObjectDetailsModule { }
