import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'timePreview'
})
export class TimePreviewPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    return Math.max(1, Math.round(value / 60 / 1000));
  }

}
