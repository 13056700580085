import {gql} from 'apollo-angular';

export const YoastSeoFragment = gql`
  fragment yoastSeoFields on PostTypeSEO {
    canonical
    cornerstone
    focuskw
    fullHead
    metaDesc
    metaKeywords
    metaRobotsNofollow
    metaRobotsNoindex
    opengraphAuthor
    opengraphDescription
    opengraphModifiedTime
    opengraphPublishedTime
    opengraphPublisher
    opengraphSiteName
    opengraphTitle
    opengraphType
    opengraphUrl
    readingTime
    title
    twitterDescription
    twitterTitle
  }
`;
