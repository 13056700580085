import {gql} from 'apollo-angular';
import {TourPreviewFragment} from './framents/TourPreviewFragment';

export const GET_TOUR_PREVIEWS = gql`
  query GetTourPreviews {
    tours(first: 1000) {
      nodes {
        ... on Tour {
          ...tourPreviewFields
        }
      }
    }
  }
  ${TourPreviewFragment}
  `;
