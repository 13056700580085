import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ContentService} from './content.service';
import {forkJoin, Observable} from 'rxjs';
import {SimplePage} from '../interfaces/simple-page';

@Injectable({
  providedIn: 'root'
})
export class PageResolverService  implements Resolve<any> {

  constructor(
    private contentService: ContentService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | {simplePage: SimplePage} {

    let id = 0;
    const paramId = route.paramMap.get('id');

    if (paramId) {
      id = parseInt(paramId, 10);
    }

    return forkJoin({
      simplePage: this.contentService.getPageById(id),
    });
  }
}
