import {Injectable} from '@angular/core';
import {ImageService} from './image.service';
import {Story} from '../interfaces/story';
import {StoryCard} from '../interfaces/story-card';

@Injectable({
  providedIn: 'root'
})
export class DirectionMapperService {

  directionCards: StoryCard[] = new Array(0);

  constructor(
    private image: ImageService
  ) { }

  mapDirectionData(rawData: any): Story {

    // TODO: Make these strings translatable. Done static to save time.
    const mappedDirection: Story = {
      de: {
        title: 'Wo befindet sich das Objekt?'
      },
      en: {
        title: 'Where is the object?'
      },
      cards: [],
      theme: 'black'
    };

    this.directionCards = new Array(0);

    this.handleInfoLevel(rawData);

    mappedDirection.cards = this.directionCards;

    return mappedDirection;

  }

  handleInfoLevel(levelData: any): void {

    const levelCards = new Array(0);

    if (levelData?.acfDirection?.cards?.length) {
      levelData?.acfDirection?.cards.forEach((card: any) => {
        levelCards.push(this.mapCardContent(card));
      });
    }

    this.directionCards.splice(0, 0, ...levelCards);

    if (levelData.acfDirection.base) {
      this.handleInfoLevel(levelData.acfDirection.base);
    }
  }

  mapCardContent(rawCardData: any): any {

    const newCard: StoryCard = {
      contentBlock: []
    };

    if (rawCardData.content.length) {
      rawCardData.content.forEach((content: any) => {

        if (content.hasOwnProperty('image')) {
          const mappedCard = {
            image: this.image.parseAcfImage(content.image)
          };
          newCard.contentBlock.push(mappedCard);
        } else {
          const mappedCard = {
            de: {
              text: content.textDe
            },
            en: {
              text: content.textEn
            }
          };

          newCard.contentBlock.push(mappedCard);
        }
      });
    }

    return newCard;

  }

}
