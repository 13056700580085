import {Component, OnInit} from '@angular/core';
import {Hint} from '../interfaces/hint';
import {HintsService} from '../services/hints.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {SharedService} from '../services/shared.service';
import Swiper from 'swiper';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mak-hints',
  templateUrl: './hints.component.html',
  styleUrls: ['./hints.component.scss']
})
export class HintsComponent implements OnInit {

  hints: Hint[] = [];
  currentPage = 1;
  hintsSwiper!: Swiper;

  constructor(
    private hintsService: HintsService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.hintsService.hints.pipe(untilDestroyed(this)).subscribe((response) => {
      if (response !== null) {
        this.sharedService.toggleBodyScroll(false);
        this.hints = response;
        this.currentPage = 0;
      } else {
        this.hints = [];
        this.sharedService.toggleBodyScroll(true);
      }
    });
  }

  onSwiper(slider: Swiper): void {
    this.hintsSwiper = slider;
  }

  nextHint(): void {
    this.hintsSwiper.slideNext();

  }

  prevHint(): void {
    this.hintsSwiper.slidePrev();
  }

  closeHints(): void {
    this.hintsService.closeHints();
  }

  goToSlide(idx: number): void {
    console.log(idx);
    this.hintsSwiper.slideTo(idx);
  }

  setCurrentIndex(event: any): void {
    this.currentPage = event.realIndex;
  }
}
