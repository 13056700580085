import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  constructor() { }

  openShareMenu(title: string, url: string, text: string): void {
    if (navigator.share) {
      navigator.share({
        title,
        text,
        url
      }).then(() => {
        console.log('Successfully Shared');
      }).catch(console.error);
    } else {
      console.error('Share menu is not supported on this device!');
    }
  }
}
