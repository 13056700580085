import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../store';
import {getPlayedAudioIds} from '../store/selectors/user.selectors';

import {intersection} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PlayerHistoryService {

  playedAudioIds: string[] = new Array(0);

  constructor(
    private store: Store<AppState>
  ) {

    this.store.select(getPlayedAudioIds)
      .subscribe(response => {
        this.playedAudioIds = response;
      });

  }

  getItemPlayed(audioId: string): boolean {
    return this.playedAudioIds.indexOf(audioId) > -1;
  }

  getPlayedItemsCount(audioIds: string[]): number {
    return intersection(this.playedAudioIds, audioIds).length;
  }

}
