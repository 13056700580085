import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'mak-equalizer',
  templateUrl: './equalizer.component.html',
  styleUrls: ['./equalizer.component.scss']
})
export class EqualizerComponent implements OnInit {

  @Input('state') state = false;

  constructor() { }

  ngOnInit(): void {
  }

}
