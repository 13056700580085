import {Component, OnInit} from '@angular/core';
import {Story} from '../interfaces/story';
import {LanguageService} from '../services/language.service';
import {Store} from '@ngrx/store';
import {AppState} from '../store';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {StoryService} from '../services/story.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mak-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss']
})
export class StoryComponent implements OnInit {
  story!: Story | null;
  currentCardNumber = 0;
  lang$ = this.language.getLanguage();
  state = false;

  constructor(private language: LanguageService,
              private store: Store<AppState>,
              private storyService: StoryService) { }

  ngOnInit(): void {
    this.storyService.getUpdates().pipe(untilDestroyed(this)).subscribe((response) => {
      console.log('was updated');
      if (response === null) {
        this.state = false;
      } else {
        if (this.story !== null && JSON.stringify(response) !== JSON.stringify(this.story)) {
          this.currentCardNumber = 0; // reset if new story
          this.story = response;
        }
        this.state = true;
      }
    });
  }

  closeStory(): void {
    this.storyService.closeStory();
  }


  goToPrevCard(): void {
    if (this.currentCardNumber === 0) {
      this.closeStory();
    } else {
      this.currentCardNumber--;
    }
  }

  goToNextCard(): void {
    if (this.currentCardNumber + 1 === this.story?.cards.length) {
      this.closeStory();
      this.currentCardNumber = 0;
    } else {
      this.currentCardNumber++;
    }
  }

  onCardClicked($event: MouseEvent): void {
    (window.innerWidth / 2 > $event.clientX) ? this.goToPrevCard() : this.goToNextCard();
  }
}
