import {createReducer, on} from '@ngrx/store';
import {
  removeObjectInterest,
  removePoiOfInterest,
  removeTourMarkerOfInterest,
  setObjectInterest,
  setPoiOfInterest,
  setTourMarkerOfInterest,
  setTourObjectsOfInterest
} from '../actions/map.actions';

import {ObjectDetails} from '../../interfaces/object-details';
import {TourObjectLocation} from '../../interfaces/tour-object-location';
import {Poi} from '../../interfaces/poi';

export const mapFeatureKey = 'map';

export interface State {
  objectOfInterest: ObjectDetails | undefined;
  tourObjectsOfInterest: TourObjectLocation[];
  tourMarkerOfInterest: TourObjectLocation | undefined;
  poiOfInterest: Poi | undefined;
}

export const initialState: State = {
  objectOfInterest: undefined,
  tourObjectsOfInterest: new Array(0),
  tourMarkerOfInterest: undefined,
  poiOfInterest: undefined
};

export const reducer = createReducer(
  initialState,
  on(setObjectInterest, (state, action) => {
    return {
      ...state,
      objectOfInterest: action.objectDetails
    };
  }),
  on(setTourObjectsOfInterest, (state, action) => {
    return {
      ...state,
      tourObjectsOfInterest: action.objects
    };
  }),
  on(removeObjectInterest, (state, action) => {
    return {
      ...state,
      objectOfInterest: undefined
    };
  }),
  on(setTourMarkerOfInterest, (state, action) => {
    return {
      ...state,
      tourMarkerOfInterest: action.object
    };
  }),
  on(removeTourMarkerOfInterest, (state, action) => {
    return {
      ...state,
      tourMarkerOfInterest: undefined
    };
  }),
  on(setPoiOfInterest, (state, action) => {
    return {
      ...state,
      poiOfInterest: action.poi
    };
  }),
  on(removePoiOfInterest, (state, action) => {
    return {
      ...state,
      poiOfInterest: undefined
    };
  }),
);
