import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngrx/store';
import {AppState} from '../../store';
import {setAudioTranscriptVisibility, setPlayerSpeedVisibility} from '../../store/actions/audio.actions';
import {MakConstants} from '../../shared/MakConstants';
import {getPlayerSpeed, getPlayerState} from '../../store/selectors/audio.selectors';
import {PlayerActionsService} from '../../services/player-actions.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mak-player-controls',
  templateUrl: './player-controls.component.html',
  styleUrls: ['./player-controls.component.scss']
})
export class PlayerControlsComponent implements OnInit {

  @Output('seek-position') seekPositionEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output('seek-position-progress') seekPositionProgressEmitter = new EventEmitter<number>();
  @Output('seek-jump') seekJumpEmitter: EventEmitter<string> = new EventEmitter<string>();

  @Output('skip') skipEmitter: EventEmitter<string> = new EventEmitter<string>();

  @Input('progress') set setProgress(value: number | null) {
    if (typeof value === 'number' && !this.isDragging) {
      this.progress = value;
    }
  }

  @Input('seek') set setSeek(value: number | null) {
    if (typeof value === 'number') {
      this.seek = value * 1000;
      this.setTimerTime();
    }
  }

  @Input('duration') set setDuration(value: number | null) {
    if (typeof value === 'number') {
      this.duration = value;
      this.setTimerTime();
    }
  }

  @Input('timestamp') set setTimestamp(value: number | null) {
    if (typeof value === 'number') {
      this.timestamp = value;
      this.setTimerTime();
    }
  }

  @ViewChild('progressBarWrapper') progressBarWrapperElement: ElementRef | undefined;

  playerState = MakConstants.playerStates.empty;
  makConstants = MakConstants;
  progress = 0;
  duration = 0;
  timestamp = 0;
  seek = 0;

  timeDone = 0;
  restTime = 0;

  progressDotLastDeltaX = 0;
  tempProgress = 0;
  isDragging = false;

  speed = 1;

  constructor(
    private store: Store<AppState>,
    private playerActions: PlayerActionsService
  ) { }

  ngOnInit(): void {
    this.store.select(getPlayerState)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        if (response) {
          this.playerState = response;
        }
      });

    this.store.select(getPlayerSpeed).pipe(untilDestroyed(this)).subscribe((response) => {
      console.log(response);
      this.speed = response;
    });
  }

  togglePlayerState(): void {
    this.playerActions.togglePlayerState();
  }

  skip(direction: string): void {
    this.skipEmitter.emit(direction);
  }

  setSeekPosition($event: MouseEvent): void {
    this.seekPositionEmitter.emit({event: $event, element: this.progressBarWrapperElement});
  }

  showTranscript(): void {
    this.store.dispatch(setAudioTranscriptVisibility({visible: true}));
  }

  seekJumpDirection(direction: string): void {
    this.seekJumpEmitter.emit(direction);
  }

  setTimerTime(): void {
    this.timeDone = this.seek - this.timestamp;
    this.restTime = this.duration - this.seek;
  }

  showPlayerSpeed(): void {
    this.store.dispatch(setPlayerSpeedVisibility({visible: true}));
  }

  progressBarDrag($event: any): void {
    if (this.progressBarWrapperElement) {
      this.isDragging = true;

      const width = this.progressBarWrapperElement.nativeElement.clientWidth;
      const change = $event.deltaX - this.progressDotLastDeltaX;
      const changePercentage = change / width;
      let newProgress = (this.progress / 100) + changePercentage;
      if (newProgress > 0.99) {
        newProgress = 1;
      } else if (this.progress <= 0) {
        newProgress = 0.01; // 0.01 instead of 0 may be prevents jump to prev on phones // TODO need fix?
      }
      this.progress = newProgress * 100;

      // console.log(this.progress);
      this.progressDotLastDeltaX = $event.deltaX;

      if ($event.isFinal) {
        this.isDragging = false;
        this.seekPositionProgressEmitter.emit(newProgress);
        this.progressDotLastDeltaX = 0;
      }
    }
  }
}
