import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ContentService} from './content.service';
import {forkJoin, Observable} from 'rxjs';
import {ContentBase} from '../interfaces/content-base';

@Injectable({
  providedIn: 'root'
})
export class PatternResolverService implements Resolve<any> {

  constructor(
    private contentService: ContentService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | ContentBase {

    let id = 0;
    const paramId = route.paramMap.get('id');

    if (paramId) {
      id = parseInt(paramId, 10);
    }

    return forkJoin({
      contentBase: this.contentService.getContentById(id),
    });
  }
}
