import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ObjectPreviewMenuComponent} from './object-preview-menu.component';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [
    ObjectPreviewMenuComponent
  ],
  exports: [
    ObjectPreviewMenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule
  ]
})
export class ObjectPreviewMenuModule { }
