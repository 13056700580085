import {createReducer, on} from '@ngrx/store';
import {
  setActiveAudioFile,
  setActiveAudioId,
  setActivePlaylistItem,
  setAudioTranscriptVisibility,
  setPlayerAction,
  setPlayerMode,
  setPlayerSpeed,
  setPlayerSpeedVisibility,
  setPlayerState,
  setPlaylist
} from '../actions/audio.actions';

import {MakConstants} from '../../shared/MakConstants';
import {Playlist} from '../../interfaces/playlist';

export const audioFeatureKey = 'audio';

export interface State {
  activeAudioFile: string | undefined;
  sprite: string | undefined;
  playlist: Playlist | undefined;
  activePlaylistItem: {
    index: number | undefined,
    timestamp: string | undefined
  };
  playerAction: {
    action: string | undefined,
    timestamp: string | undefined
  };
  activeAudioId: string | undefined;
  playerState: string | undefined;
  audioTranscriptVisible: boolean;
  playerSpeedVisible: boolean;
  playerSpeed: number;
  playerMode: string;
}

export const initialState: State = {
  activeAudioFile: undefined,
  sprite: undefined,
  playlist: undefined,
  activePlaylistItem: {
    index: undefined,
    timestamp: undefined
  },
  playerAction: {
    action: undefined,
    timestamp: undefined
  },
  activeAudioId: undefined,
  playerState: MakConstants.playerStates.empty,
  audioTranscriptVisible: false,
  playerSpeedVisible: false,
  playerSpeed: 1,
  playerMode: MakConstants.playerModes.mini
};

export const reducer = createReducer(
  initialState,
  on(setActiveAudioFile, (state, action) => {
    return {
      ...state,
      activeAudioFile: action.fileUrl
    };
  }),
  on(setPlaylist, (state, action) => {
    return {
      ...state,
      playlist: action.playlist
    };
  }),
  on(setActivePlaylistItem, (state, action) => {
    return {
      ...state,
      activePlaylistItem: {
        index: action.index,
        timestamp: action.timestamp
      }};
  }),
  on(setActiveAudioId, (state, action) => {
    return {
      ...state,
      activeAudioId: action.audioId
    };
  }),
  on(setPlayerState, (state, action) => {
    return {
      ...state,
      playerState: action.state
    };
  }),
  on(setPlayerAction, (state, action) => {
    return {
      ...state,
      playerAction: {
        action: action.action,
        timestamp: action.timestamp
      }};
  }),
  on(setAudioTranscriptVisibility, (state, action) => {
    return {
      ...state,
      audioTranscriptVisible: action.visible
    };
  }),
  on(setPlayerSpeedVisibility, (state, action) => {
    return {
      ...state,
      playerSpeedVisible: action.visible
    };
  }),
  on(setPlayerSpeed, (state, action) => {
    return {
      ...state,
      playerSpeed: action.speed
    };
  }),
  on(setPlayerMode, (state, action) => {
    return {
      ...state,
      playerMode: action.mode
    };
  })
);
