import {Injectable} from '@angular/core';
import {ObjectDetails} from '../interfaces/object-details';
import {toggleFavoriteObject} from '../store/actions/user.actions';
import {AppState} from '../store';
import {Store} from '@ngrx/store';
import {getFavoriteObjects} from '../store/selectors/user.selectors';
import {UntilDestroy} from '@ngneat/until-destroy';
import {take} from 'rxjs/operators';
import {MakConstants} from '../shared/MakConstants';
import {NotificationsService} from './notifications.service';
import {TranslateService} from '@ngx-translate/core';
import {PlayerActionsService} from './player-actions.service';
import {Apollo} from 'apollo-angular';
import {UPDATE_LIKE_COUNT} from '../queries/mutations/UPDATE_LIKE_COUNT';
import {UpdateLikeCountMutation} from '../queries/mutations/__generated__/UpdateLikeCountMutation';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  constructor(
    private store: Store<AppState>,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private playerActionsService: PlayerActionsService,
    private apollo: Apollo
  ) { }

  /**
   * Toggle Object in Favorites and returns its new state
   * @param object ObjectDetails
   */
  toggleFavoriteObject(object: ObjectDetails): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.store.dispatch(toggleFavoriteObject({object}));

      this.store.select(getFavoriteObjects).pipe(take(1)).subscribe((response) => {
        if (this.isInFavoritesList(object, response)) {
          this.saveLikeOnServer(object.id, true);
          this.notificationsService.pushNotification({
            de: {
              linkTitle: this.translateService.instant('FAVORITES.SHOW_FAVORITES'),
              linkUrl: '/favoriten',
              tag: '',
              text: this.translateService.instant('FAVORITES.ADDED_TO_FAVORITES'),
              linkIsExtern: false
            },
            en: {
              linkTitle: this.translateService.instant('FAVORITES.SHOW_FAVORITES'),
              linkUrl: '/favoriten',
              tag: '',
              text: this.translateService.instant('FAVORITES.ADDED_TO_FAVORITES'),
              linkIsExtern: false
            },
            onInternalLinkClicked: () => {
              this.playerActionsService.setPlayerMode(MakConstants.playerModes.mini);
            },
            image: object.image.srcSet
          });

          console.log('returning true');
          resolve(true);
        } else {
          this.saveLikeOnServer(object.id, false);
          console.log('returning true');
          resolve(false);
        }
      });

      resolve(false);
    });
  }

  isInFavoritesList(object: ObjectDetails, list: ObjectDetails[]): boolean {
    return list.map(el => el.id).indexOf(object.id) > -1;
  }


  saveLikeOnServer(objectId: number, increase: boolean): void {
    this.apollo
      .mutate<UpdateLikeCountMutation>({
        mutation: UPDATE_LIKE_COUNT,
        variables: {
          increase,
          objectId,
          timeStamp: new Date().toString()
        }
      })
      .subscribe(({data}) => {
        console.log(data);
      });
  }

}
