import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MakConstants} from '../../shared/MakConstants';
import {Store} from '@ngrx/store';
import {AppState} from '../../store';
import {getPlayerState} from '../../store/selectors/audio.selectors';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PlaylistItem} from '../../interfaces/playlist-item';
import {LanguageService} from '../../services/language.service';
import {PlayerActionsService} from '../../services/player-actions.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mak-mini-player',
  templateUrl: './mini-player.component.html',
  styleUrls: ['./mini-player.component.scss']
})
export class MiniPlayerComponent implements OnInit {

  @ViewChild('titleElement') titleElement!: ElementRef;
  @ViewChild('titleTextElement') titleTextElement!: ElementRef;
  lang$ = this.language.getLanguage();

  @Input('current-item') set setCurrentItem(value: PlaylistItem | null) {
    if (value) {
      this.currentItem = value;
      setTimeout(() => { // TODO can we detect when value rendered in DOM? without time width = 0
        this.setTitleTranslateX();
      }, 10);
    }
  }

  @Input('progress') set setProgress(value: number | null) {
    if (value !== null) {
      this.progress = value;
    }
  }

  @Output('title-clicked') titleClickedEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('onMiniPlayerClosed') onMiniPlayerClosed = new EventEmitter<null>();

  currentItem!: PlaylistItem;
  playerState = MakConstants.playerStates.empty;
  makConstants = MakConstants;
  progress = 0;

  private readonly TITLE_DURATION_PER_WORD = 300; // ms 400-500 seems ok
  private readonly WORDS_NUMBER_INITIALLY_IN_VIEW = 20; // approximately
  titleTransitionDirection = false; // false - going to start, true - going to end
  titleTransitionDuration = 0; // ms dynamic
  titleTransitionInterval!: number;


  constructor(
    private store: Store<AppState>,
    private language: LanguageService,
    private playerActions: PlayerActionsService
  ) { }

  ngOnInit(): void {
    this.store.select(getPlayerState)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        if (response) {
          this.playerState = response;
        }
      });
  }

  togglePlayerState(): void {
    this.playerActions.togglePlayerState();
  }

  titleClicked(): void {
    this.titleClickedEmitter.emit(true);
  }

  closeMiniPlayer(): void {
    this.onMiniPlayerClosed.emit();
  }

  /**
   * Starting translation of title if its length bigger than given space
   * It is called every time when current item changed
   */

  setTitleTranslateX(): void {
    clearInterval(this.titleTransitionInterval); // clear last interval in any case
    this.resetTitleTransform(); // set transition to start
    if (this.titleElement && this.titleTextElement) { // viewChild check
      const currentTitle = this.language.getTranslatedKey(this.currentItem, 'title', this.language.currentLanguage); // title that will be rendered
      if (currentTitle) {
        const wrapperWidth = this.titleElement.nativeElement.clientWidth; // width of wrapper with limited width
        const textWidth = this.titleTextElement.nativeElement.clientWidth; // width of text with unlimited width
        if (textWidth > wrapperWidth) { // transition if needs more space only
          const titleLength = currentTitle.length - this.WORDS_NUMBER_INITIALLY_IN_VIEW;
          this.titleTransitionDuration = this.TITLE_DURATION_PER_WORD * titleLength; // duration from start to end position
          this.titleTextElement.nativeElement.style.transitionDuration = `${this.titleTransitionDuration}ms`;
          this.titleTextElement.nativeElement.style.transform = `translateX(${wrapperWidth - textWidth}px)`;
          this.titleTransitionInterval = window.setInterval(() => { // in interval with delay of transition duration set translate to start or end
            if (this.titleTransitionDirection) {
              this.titleTextElement.nativeElement.style.transform = `translateX(0px)`;
            } else {
              this.titleTextElement.nativeElement.style.transform = `translateX(${wrapperWidth - textWidth}px)`;
            }
            this.titleTransitionDirection = !this.titleTransitionDirection;
          }, this.titleTransitionDuration);
        }
      }
    }
  }

  resetTitleTransform(): void {
    this.titleTextElement.nativeElement.style.transitionDuration = `0ms`;
    this.titleTextElement.nativeElement.style.transform = `translateX(0.1px)`; // 0.1px to trigger changes
    this.titleTransitionDirection = true;
  }
}
