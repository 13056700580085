import {Component, Input, OnInit} from '@angular/core';
import {ContentService} from '../../services/content.service';
import {take} from 'rxjs/operators';
import {TabNavigationService} from '../../services/tab-navigation.service';
import {TourPreview} from '../../interfaces/tour-preview';

interface RelatedObjectTour {
  tour: TourPreview;
  cardIdx: number;
}

@Component({
  selector: 'mak-object-details-related-tours',
  templateUrl: './object-details-related-tours.component.html',
  styleUrls: ['./object-details-related-tours.component.scss']
})
export class ObjectDetailsRelatedToursComponent implements OnInit {

  @Input('objectId') set setObjectId(value: number) {
    this.objectId = value;
    this.isLoading = true;
    this.loadRelatedTours();
  }

  relatedTours: RelatedObjectTour[] = [];
  isLoading = true;
  objectId!: number;

  // pull up container
  activeTour: RelatedObjectTour | null = null;
  menuState = false;

  constructor(
    private contentService: ContentService,
    public tabNavigation: TabNavigationService
  ) { }

  ngOnInit(): void {
  }

  loadRelatedTours(): void {
    this.contentService.getTours()
      .pipe(take(1))
      .subscribe((response: TourPreview[]) => {
      response.map((tourPreview: TourPreview) => { // loop all tours from DB
          if (tourPreview.cardIds.indexOf(this.objectId) > -1) { // check if it is the same card
            this.relatedTours.push({ // save
              tour: tourPreview,
              cardIdx: tourPreview.cardIds.indexOf(this.objectId) + 1
            });
          }
      });
      this.isLoading = false;
    });
  }

  toggleMenuState(state: boolean): void {
    this.menuState = state;
  }

  openMenuForItem(tour: RelatedObjectTour): void {
    this.activeTour = tour;
    this.toggleMenuState(true);
  }
}
