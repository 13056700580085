import {createFeatureSelector, createSelector} from '@ngrx/store';
import {notificationFeatureKey, State} from '../reducers/notification.reducer';

export const selectNotificationFeature = createFeatureSelector<State>(
  notificationFeatureKey
);

export const getNotification = createSelector(
  selectNotificationFeature,
  (state: State) => state.notification
);

export const getSingleHintContent = createSelector(
  selectNotificationFeature,
  (state: State) => state.singleHintContent
);

export const getSingleHintVisibility = createSelector(
  selectNotificationFeature,
  (state: State) => state.singleHintIsVisible
);

