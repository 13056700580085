import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ObjectGalleryEnlargedComponent} from './object-gallery-enlarged.component';
import {PinchZoomModule} from 'ngx-pinch-zoom';
import {AcfImageModule} from '../atoms/acf-image/acf-image.module';


@NgModule({
  declarations: [
    ObjectGalleryEnlargedComponent
  ],
  exports: [
    ObjectGalleryEnlargedComponent
  ],
    imports: [
        CommonModule,
        PinchZoomModule,
        AcfImageModule
    ]
})
export class ObjectGalleryEnlargedModule { }
