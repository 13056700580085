import {PatternResolverService} from '../services/pattern-resolver.service';
import {TourResolverService} from '../services/tour-resolver.service';
import {PageResolverService} from '../services/page-resolver.service';
import {HomeResolverService} from '../services/home-resolver.service';

export class MakRoutes {

  public static ROUTES_DE = [
    {
      path: 'tour-object/:id/:objectIndex',
      loadChildren: () => import('./../tour/tour.module').then(m => m.TourModule),
      data: {
        lang: 'de'
      },
      resolve: {
        apiData: TourResolverService
      },
    },
    {
      path: 'object/:id',
      loadChildren: () => import('./../object-details/object-details.module').then(m => m.ObjectDetailsModule),
      data: {
        lang: 'de'
      },
      resolve: {
        apiData: PatternResolverService
      },
    },
    {
      path: 'tour/:id',
      loadChildren: () => import('./../tour-overview/tour-overview.module').then(m => m.TourOverviewModule),
      data: {
        lang: 'de'
      },
      resolve: {
        apiData: TourResolverService
      },
    },
    {
      path: 'page/:id',
      loadChildren: () => import('./../simple-page/simple-page.module').then(m => m.SimplePageModule),
      data: {
        lang: 'de'
      },
      resolve: {
        apiData: PageResolverService
      },
    },
    {
      path: 'tour',
      loadChildren: () => import('./../tour-overview/tour-overview.module').then(m => m.TourOverviewModule),
      data: {
        lang: 'de'
      }
    },
    {
      path: 'favoriten',
      loadChildren: () => import('./../favorites/favorites.module').then(m => m.FavoritesModule),
      data: {
        lang: 'de'
      }
    },
    {
      path: 'code',
      loadChildren: () => import('./../code/code.module').then(m => m.CodeModule),
      data: {
        lang: 'de'
      },
    },
    {
      path: 'locations',
      loadChildren: () => import('./../location/location.module').then(m => m.LocationModule),
      data: {
        lang: 'de'
      },
    },
    {
      path: 'audio',
      loadChildren: () => import('./../audio-archive/audio-archive.module').then(m => m.AudioArchiveModule),
      data: {
        lang: 'de'
      },
    },
    {
      path: '',
      loadChildren: () => import('./../home/home.module').then(m => m.HomeModule),
      data: {
        lang: 'de'
      },
      resolve: {
        apiData: HomeResolverService
      },
    },
    {
      path: '**',
      redirectTo: ''
    }
  ];
}
