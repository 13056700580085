<div class="player-controls">

  <div class="player-controls__progress-wrapper">
    <div class="player-controls__progress-time player-controls__progress-time--done">
      {{ timeDone | date:'mm:ss' }}
    </div>

    <div #progressBarWrapper class="player-controls__progress-bar-wrapper" (click)="setSeekPosition($event)">
      <div class="player-controls__progress-bar" [ngStyle]="{'width' : progress + '%'}"></div>
      <div class="player-controls__progress-bar-dot-wrapper" [ngStyle]="{'left' : progress + '%'}" (pan)="progressBarDrag($event)">
        <div class="player-controls__progress-bar-dot"></div>

      </div>
    </div>

    <div class="player-controls__progress-time player-controls__progress-time--coming">
      {{ restTime | date:'mm:ss' }}
    </div>

  </div>

  <div class="player-controls__controls-wrapper">
    <div class="player-controls__control player-controls__control--speed" (click)="showPlayerSpeed()">
      <span class="icon icon-speed"
            [ngClass]="{'icon-speed05': speed === 0.5, 'icon-speed075' : speed === 0.75, 'icon-speed1' : speed === 1, 'icon-speed125' : speed === 1.25, 'icon-speed15' : speed === 1.5, 'icon-speed175' : speed === 1.75, 'icon-speed2' : speed === 2}"></span>
    </div>
    <div class="player-controls__control player-controls__control--jump player-controls__control--jump-back" (click)="seekJumpDirection('prev')">
      <span class="icon icon-rewind"></span>
    </div>
    <div class="player-controls__control player-controls__play" *ngIf="playerState === makConstants.playerStates.paused" (click)="togglePlayerState()">
      <span class="icon icon-play-circle"></span>
    </div>
    <div class="player-controls__control player-controls__pause" *ngIf="playerState === makConstants.playerStates.playing" (click)="togglePlayerState()">
      <span class="icon icon-pause-circle"></span>
    </div>
    <div class="player-controls__control player-controls__control--jump player-controls__control--jump-forward" (click)="seekJumpDirection('next')">
      <span class="icon icon-forward"></span>
    </div>
    <div class="player-controls__control player-controls__control--transcript" (click)="showTranscript()">
      <span class="icon icon-transcript"></span>
    </div>
  </div>


</div>
